import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  ApiGetAllProyectosxusuario,
  ApiGetAllProyectos,
  ApiGetAllProyectosSinPublicar,
  ApiGetallProyectosExternos,
  ApiGetAllProyectosxAreas,
} from "../../../api/proyectos";
import CardProyecto from "./CardProyecto";
import { ApiGetAllAreas } from "../../../api/areas";

const animatedComponents = makeAnimated();

const ListadoProectos = ({ updateList, updateListFn, isExternal }) => {
  const [proyectos, setProyectos] = useState([]);
  const [proyectosFiltrados, setProyectosFiltrados] = useState([]);
  const [areas, setAreas] = useState([]);
  const [areasOptions, setAreasOptions] = useState([]);
  const [userId, setUserId] = useState(null);
  const [gruposAcceso, setGruposAcceso] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  //filtros
  const [projectsOptions, setProjectsOptions] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);

  useEffect(() => {
    if (isExternal) {
      const opciones = proyectos
        .filter((proyecto) => proyecto.estado_publicacion === true)
        .map((proyecto) => {
          if (proyecto.tieneFormSist && dataUser?.roles.code !== "1") {
            return null; // No se agrega al array de opciones
          }

          const formulariosPublicados = proyecto.pre_formularios.filter(
            (formulario) => formulario.estado_publicacion === true
          );

          if (formulariosPublicados.length === 0) {
            return null; // No se agrega al array de opciones
          }

          return {
            value: proyecto.pre_proyecto_id,
            label: proyecto.proyecto,
          };
        })
        .filter(Boolean); // Elimina elementos nulos del array resultante

      setProjectsOptions(opciones);
    }

    if (!isExternal) {
      const opciones = proyectos.map((proyecto) => ({
        value: proyecto.pre_proyecto_id,
        label: proyecto.proyecto,
      }));
      setProjectsOptions(opciones);
    }
    setProyectosFiltrados(proyectos);
  }, [proyectos]);

  useEffect(() => {
    isExternal ? setUserId(null) : setUserId(getUserId);
  }, []);

  const dataUser = JSON.parse(localStorage.getItem("datauser"));

  const getUserId = () => {
    const { pre_usuario_id } = dataUser;
    return pre_usuario_id;
  };

  useEffect(() => {
    updateFn();
  }, [updateList]);

  const updateFn = () => {
    initAreas();

    if (
      (dataUser?.roles.code === "1" || dataUser?.roles.code === "2") &&
      localStorage.getItem("panel") === "true"
    ) {
      initAdminSupervisor();
    }

    if (dataUser && localStorage.getItem("panel") === "false") {
      initProyectosPorArea();
    }

    if (!dataUser) {
      initExternos();
    }
  };

  const initAreas = async () => {
    const {
      data: dataAreas,
      mensaje: mensajeAreas,
      sms: smsAreas,
    } = await ApiGetAllAreas();

    if (smsAreas === "ok") {
      setAreas(dataAreas);
    } else {
      toast.error(mensajeAreas, {
        position: "bottom-left",
      });
    }
  };

  const initAdminSupervisor = async () => {
    const { data, mensaje, sms } = await ApiGetAllProyectosxusuario(
      getUserId()
    );
    if (sms === "ok") {
      const proyectosUnicos = filtrarProyectosUnicos(data);
      setProyectos(proyectosUnicos);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      toast.error(mensaje, {
        position: "bottom-left",
      });
    }
  };

  const initExternos = async () => {
    const { data, mensaje, sms } = await ApiGetallProyectosExternos();
    if (sms === "ok") {
      const nuevosProyectos = proyectos.concat(data);
      const proyectosUnicos = filtrarProyectosUnicos(nuevosProyectos);
      setProyectos(proyectosUnicos);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      toast.error(mensaje, {
        position: "bottom-left",
      });
    }
  };

  const initProyectosPorArea = async () => {
    const { data, mensaje, sms } = await ApiGetAllProyectosxAreas(
      dataUser.area.pre_cod_area
    );

    const { data: d, mensaje: m, sms: s } = await ApiGetallProyectosExternos();

    const nuevosProyectos = data?.concat(d);
    const proyectosUnicos = filtrarProyectosUnicos(nuevosProyectos);
    setProyectos(proyectosUnicos);
    setIsLoading(false);
  };

  useEffect(() => {
    setAreasOptions(
      areas.map((area) => ({
        value: area.pre_cod_area,
        label: `${area.pre_cod_area} - ${area.pre_area}`,
      }))
    );
  }, [areas]);

  const filtrarProyectosUnicos = (proyectos) => {
    const idsUnicos = new Set();
    return proyectos.filter((proyecto) => {
      if (!idsUnicos.has(proyecto.pre_proyecto_id)) {
        idsUnicos.add(proyecto.pre_proyecto_id);
        return true;
      }
      return false;
    });
  };

  //SEARCH
  useEffect(() => {
    if (selectedProjects.length === 0) {
      setProyectosFiltrados(proyectos);
      return;
    }

    const proyectosFiltrados = proyectos.filter((proyecto) => {
      const proyectosEncontrados = selectedProjects.find(
        (p) => p.value === proyecto.pre_proyecto_id
      );

      if (proyectosEncontrados) {
        return true;
      }
      return false;
    });

    setProyectosFiltrados(proyectosFiltrados);
  }, [selectedProjects]);

  if (isLoading) {
    return (
      <div className="w-100 d-flex justify-content-center py-5">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="h-100">
      <div className="row bgfondo2">
        <div className="w-100 my-3 d-flex justify-content-start flex-column">
          <Select
            isMulti
            placeholder="Buscador..."
            options={projectsOptions}
            closeMenuOnSelect={false}
            className="basic-multi-select react-select-container"
            classNamePrefix="select react-select"
            onChange={(selectedOptions) => {
              setSelectedProjects(selectedOptions);
            }}
            components={animatedComponents}
            noOptionsMessage={() => "No hay más categorías que mostrar."}
          />
        </div>
      </div>
      {!isExternal && (
        <div className="row bgfondo2">
          <div className="bgfondo2">
            <div className="w-100 d-flex justify-content-start flex-column">
              <h3>Categorías pendientes de publicación</h3>
              <p>
                Las categorías pendientes de publicación no se mostrarán a los
                usuarios finales hasta ser publicados.
              </p>
              <hr className="w-100" />
            </div>
            {proyectosFiltrados.length > 0 && areasOptions.length > 0 ? (
              proyectosFiltrados
                .filter((proyecto) => proyecto.estado_publicacion === false)
                .map((proyecto, index) => {
                  return (
                    <CardProyecto
                      proyecto={proyecto}
                      updateListFn={updateListFn}
                      updateFnInit={updateFn}
                      key={index}
                      classx="bg-white bordecompleto bordesombreado"
                      pre_usuario_id={userId}
                      isExternal={isExternal}
                      areas={areasOptions}
                    />
                  );
                })
            ) : (
              <div className="w-100 d-flex justify-content-center py-5">
                <h3>NO HAY PUBLICACIONES PENDIENTES</h3>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="my-4"></div>
      <div className="row bgfondo2">
        <div className="bgfondo2">
          <div className="w-100 d-flex justify-content-start flex-column">
            <h4>Categorías {isExternal ? "activas" : "publicadas"} </h4>
            {!isExternal && (
              <p>
                Las categorías publicadas que se marcan de color azul serán
                visibles con sus respectivos formularios activos.
              </p>
            )}
            <hr className="w-100" />
          </div>
          {isExternal ? (
            proyectosFiltrados?.length > 0 ? (
              proyectosFiltrados
                .filter((proyecto) => proyecto.estado_publicacion === true)
                .map((proyecto, index) => {
                  if (proyecto.tieneFormSist && dataUser?.roles.code !== "1") {
                    return;
                  }
                  const formulariosPublicados = proyecto.pre_formularios.filter(
                    (formulario) => formulario.estado_publicacion === true
                  );

                  if (formulariosPublicados.length === 0) {
                    return;
                  }

                  return (
                    <CardProyecto
                      proyecto={proyecto}
                      updateListFn={updateListFn}
                      updateFnInit={updateFn}
                      key={index}
                      classx="bg-white"
                      pre_usuario_id={userId}
                      isExternal={isExternal}
                      areas={areasOptions}
                    />
                  );
                })
            ) : (
              <div className="w-100 d-flex justify-content-center">
                <h3>NO HAY PROYECTOS PUBLICADOS</h3>
              </div>
            )
          ) : proyectosFiltrados?.length > 0 ? (
            proyectosFiltrados
              .filter((proyecto) => proyecto.estado_publicacion === true)
              .map((proyecto, index) => {
                if (proyecto.tieneFormSist && dataUser?.roles.code !== "1") {
                  return;
                }
                return (
                  <CardProyecto
                    proyecto={proyecto}
                    updateListFn={updateListFn}
                    updateFnInit={updateFn}
                    key={index}
                    classx="bg-white"
                    pre_usuario_id={userId}
                    isExternal={isExternal}
                    areas={areasOptions}
                  />
                );
              })
          ) : (
            <div className="w-100 d-flex justify-content-center">
              <h3>NO HAY PROYECTOS PUBLICADOS</h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ListadoProectos;
