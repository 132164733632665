import Axios from "../config/Axios";
import tokenAuth from "../config/Token";

export const uploadUpdateperfil = async (file, persona_id, fotoanterior) => {
  console.log(file);

  try {
    tokenAuth(localStorage.getItem("accesTokenCmm"));
    const formdata = new FormData();
    formdata.append("persona_id", persona_id);
    formdata.append("fotoanterior", fotoanterior);
    formdata.append("file", file);
    const result = await Axios.post("/editarfotoperfil", formdata, {
      onUploadProgress: (p) => {
        const progress = p.loaded / p.total;
        const percento = Math.round((100 * p.loaded) / p.total);
      },
    });
    return [result.data];
  } catch (e) {
    console.log(e);
    return [
      {
        data: [],
        sms: "err",
        mensaje: e,
      },
    ];
  }
};

export const firmarFoto = async (foto) => {
  try {
    const firmaFoto = await Axios.post("/downloadStorageFile", {
      file: foto,
    });
    return [
      {
        data: firmaFoto.data.url,
        sms: "ok",
        mensaje: "Foto firmada",
      },
    ];
  } catch (e) {
    return [
      {
        data: [],
        sms: "err",
        mensaje: e,
      },
    ];
  }
};

export const ApiDownloadedStaticFilesTemp = async (files) => {
  try {
    const res = await Axios.post("/downloadStaticFilesTemp", {
      files: files,
    });
    return {
      data: res.data,
      sms: "ok",
      mensaje: "Archivos descargados",
    };
  } catch (e) {
    return [
      {
        data: [],
        sms: "err",
        mensaje: e,
      },
    ];
  }
};
