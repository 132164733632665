import React, { useEffect, useRef, useState } from "react";
import ModalDefault from "../../modalDefault";
import { DataTable } from "mantine-datatable";
import { toast } from "react-hot-toast";
import {
  ApiActivarDesactivarCentroCosto,
  ApiGetAllCentrosCosto,
} from "../../../api/centrosCosto";
import { ActionIcon, Group, TextInput } from "@mantine/core";

const BATCH_SIZE = 10;

const TablaCentrosCosto = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [allCentrosCosto, setAllCentrosCosto] = useState([]);
  const [filteredCentrosCosto, setFilteredCentrosCosto] = useState([]);
  const [fetching, setfetching] = useState(true);
  const [selectedCentroCosto, setSelectedCentroCosto] = useState(null);

  // Estados para scroll infinito y filtros
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadedRecords, setLoadedRecords] = useState(BATCH_SIZE);
  const [queryDepartamento, setQueryDepartamento] = useState("");
  const [queryCodigo, setQueryCodigo] = useState("");
  const [queryCentroCosto, setQueryCentroCosto] = useState("");
  const scrollViewportRef = useRef(null);

  const columns = [
    {
      accessor: "pre_departamento_id",
      title: "Departamento",
      textAlignment: "center",
      filter: (
        <TextInput
          placeholder="Buscar por departamento..."
          value={queryDepartamento}
          onChange={(e) => setQueryDepartamento(e.currentTarget.value)}
        />
      ),
      filtering: queryDepartamento !== "",
      render: (record) =>
        `${record.pre_departamentos.pre_cod_departamento} - ${record.pre_departamentos.pre_departamento}`,
    },
    {
      accessor: "pre_centro_costo",
      title: "Centro de Costo",
      textAlignment: "center",
      filter: (
        <TextInput
          placeholder="Buscar por centro de costo..."
          value={queryCentroCosto}
          onChange={(e) => setQueryCentroCosto(e.currentTarget.value)}
        />
      ),
      filtering: queryCentroCosto !== "",
    },
    {
      accessor: "pre_cod_centro_costo",
      title: "Código",
      textAlignment: "center",
      filter: (
        <TextInput
          placeholder="Buscar por código..."
          value={queryCodigo}
          onChange={(e) => setQueryCodigo(e.currentTarget.value)}
        />
      ),
      filtering: queryCodigo !== "",
    },
    {
      accessor: "acciones",
      title: "Acciones",
      textAlignment: "center",
      render: (record) => opciones(record),
    },
  ];

  useEffect(() => {
    init();
  }, [props.updateList]);

  const init = async () => {
    setfetching(true);
    const { data } = await ApiGetAllCentrosCosto();
    const filteredCentrosCosto = data.filter(
      (centro) => centro.estado !== false
    );
    setAllCentrosCosto(filteredCentrosCosto);
    setFilteredCentrosCosto(filteredCentrosCosto);
    setfetching(false);
  };

  useEffect(() => {
    const filtered = allCentrosCosto.filter((centroCosto) => {
      const matchDepartamento =
        `${centroCosto.pre_departamentos.pre_cod_departamento} - ${centroCosto.pre_departamentos.pre_departamento}`
          .toLowerCase()
          .includes(queryDepartamento.toLowerCase());
      const matchCodigo = centroCosto.pre_cod_centro_costo
        .toLowerCase()
        .includes(queryCodigo.toLowerCase());
      const matchCentroCosto = centroCosto.pre_centro_costo
        .toLowerCase()
        .includes(queryCentroCosto.toLowerCase());

      return matchDepartamento && matchCodigo && matchCentroCosto;
    });
    setFilteredCentrosCosto(filtered);
  }, [allCentrosCosto, queryDepartamento, queryCodigo, queryCentroCosto]);

  const editCentroCosto = (row) => {
    const {
      pre_centro_costo,
      pre_cod_centro_costo,
      pre_centro_costo_id,
      pre_departamento_id,
    } = row;
    props.setType("editar");
    props.setState({
      ...props.state,
      pre_centro_costo,
      pre_cod_centro_costo,
      pre_centro_costo_id,
      pre_departamento_id,
    });
  };

  const onShowModal = (e) => {
    setShowModal(true);
    setSelectedCentroCosto(e);
  };

  const onClosedModal = () => {
    setShowModal(false);
    setSelectedCentroCosto(null);
  };

  const onDelete = async () => {
    const { estado, pre_centro_costo_id } = selectedCentroCosto;
    const { sms, mensaje } = await ApiActivarDesactivarCentroCosto({
      pre_centro_costo_id,
      pre_estado: !estado,
    });
    if (sms === "ok") {
      toast.success(mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid green",
          padding: "16px",
          color: "white",
          background: "green",
          borderColor: "white",
        },
        iconTheme: {
          primary: "green",
          secondary: "white",
        },
      });
      setSelectedCentroCosto(null);
      props.updateListFn();
    } else {
      toast.error(mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    }
    onClosedModal();
  };

  const loadMoreData = () => {
    if (loadedRecords < filteredCentrosCosto.length) {
      setLoadingMore(true);
      setTimeout(() => {
        setLoadedRecords((c) => c + BATCH_SIZE);
        setLoadingMore(false);
      }, 1000);
    }
  };

  const opciones = (row) => {
    return (
      <Group spacing={4} position="center" noWrap>
        <ActionIcon
          color="blue"
          onClick={() => {
            editCentroCosto(row);
          }}
        >
          <i className="fas fa-file-pen"></i>
        </ActionIcon>
        <ActionIcon
          color="red"
          onClick={() => {
            onShowModal(row);
          }}
        >
          <i className="fas fa-trash"></i>
        </ActionIcon>
        <ModalDefault
          bandera={"confirmacion"}
          modal={showModal}
          size={"sm"}
          icontitulo={<null />}
          titulo={`Confirmación`}
          closeAction={onClosedModal}
        >
          <div className="row py-4 justify-content-center text-align-center ">
            Realmente desea eliminar
          </div>
          <div className="d-flex justify-content-end flex-row align-self-start my-4">
            <button
              type=""
              onClick={onClosedModal}
              className="bg-btn-vet-danger sombra mx-1"
            >
              Cancelar
            </button>
            <button
              type=""
              onClick={onDelete}
              className="bg-btn-vet sombra mx-1"
            >
              <i className="fas fa-save"> Eliminar</i>
            </button>
          </div>
        </ModalDefault>
      </Group>
    );
  };

  const visibleCentros = filteredCentrosCosto.slice(
    0,
    Math.min(loadedRecords, filteredCentrosCosto.length)
  );

  return (
    <div className="row">
      <div className="col-md-12">
        <DataTable
          borderRadius="md"
          className="bordecompleto bgfondo "
          highlightOnHover
          noRecordsText="No se encontraron datos"
          records={visibleCentros}
          columns={columns}
          totalRecords={allCentrosCosto.length}
          withColumnBorders
          fetching={fetching || loadingMore}
          scrollViewportRef={scrollViewportRef}
          onScrollToBottom={loadMoreData}
          style={{ maxHeight: "350px", overflowY: "auto" }}
        />
      </div>
    </div>
  );
};

export default TablaCentrosCosto;
