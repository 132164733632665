import Axios from "../config/Axios";

export const ApiCrearDocumento = async (data) => {
  try {
    const result = await Axios.post(`/POSTcreateDocumentos`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetAllDocumentos = async () => {
  try {
    const result = await Axios.get(`/GETallDocumentos`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetDocumentoById = async (documento_id) => {
  try {
    const result = await Axios.get(`/GETDocumento/${documento_id}`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiEditarDocumento = async (data) => {
  try {
    const result = await Axios.post(`/POSTeditDocumentos`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiActivarDesactivarDocumento = async (data) => {
  try {
    const result = await Axios.post(`/POSTtoggleEstadoDocumento`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiDeleteDocumento = async (data) => {
  try {
    const result = await Axios.post(`/POSTdeleteDocumento`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};
