import Axios from "../config/Axios";

export const ApiCrearCargo = async (data) => {
  try {
    const result = await Axios.post(`/POSTcreateCargo`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiEditarCargo = async (data) => {
  try {
    const result = await Axios.post(`/POSTeditCargo`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetAllCargos = async () => {
  try {
    const result = await Axios.get(`/GETallCargos`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiActivarDesactivarCargo = async (data) => {
  try {
    const result = await Axios.post(`/POSTtoggleEstadoCargo`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};
