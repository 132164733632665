import React, { useEffect, useState } from "react";
import ModalDefault from "../../modalDefault";

import { DataTable } from "mantine-datatable";
import { toast } from "react-hot-toast";
import { ApiActivarDesactivarRol, ApiGetAllRoles } from "../../../api/roles";
import { ActionIcon, Group } from "@mantine/core";
const PAGE_SIZE = 7;
const TablaOpciones = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [roles, setRoles] = useState([]);
  const [fetching, setfetching] = useState(true);
  const [paginatedRoles, setPaginatedRoles] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedRol, setSelectedRol] = useState(null);

  const columns = [
    {
      accessor: "code",
      title: "Código",
      textAlignment: "center",
    },
    {
      accessor: "rol",
      title: "Rol",
      textAlignment: "center",
    },
    {
      accessor: "acciones",
      title: "Acciones",
      textAlignment: "center",
      render: (record) => opciones(record),
    },
  ];

  useEffect(() => {
    setfetching(true);
    init();
  }, [props.updateList]);

  const init = async () => {
    const { data } = await ApiGetAllRoles();
    const filterRoles = data.filter((rol) => rol.estado !== false);
    setRoles(filterRoles);
    const paginatedData = paginateData(filterRoles);
    setPaginatedRoles(paginatedData);
    setfetching(false);
  };

  const paginateData = (data) => {
    const startIndex = (page - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    return data.slice(startIndex, endIndex);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    const paginatedData = paginateData(roles);
    setPaginatedRoles(paginatedData);
  };

  const editRol = (row) => {
    const { code, rol, pre_rol_id } = row;
    props.setType("editar");
    props.setState({
      ...props.state,
      rol,
      pre_rol_id,
      code,
    });
  };

  const onShowModal = (e) => {
    setShowModal(true);
    setSelectedRol(e);
  };

  const onClosedModal = () => {
    setShowModal(false);
    setSelectedRol(null);
  };

  const onDelete = async () => {
    const { estado, pre_rol_id } = selectedRol;
    const { sms, mensaje } = await ApiActivarDesactivarRol({
      pre_rol_id,
      pre_estado: !estado,
    });
    if (sms === "ok") {
      toast.success(mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid green",
          padding: "16px",
          color: "white",
          background: "green",
          borderColor: "white",
        },
        iconTheme: {
          primary: "green",
          secondary: "white",
        },
      });
      setSelectedRol(null);
      props.updateListFn();
    } else {
      toast.error(mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    }
    onClosedModal();
  };

  const opciones = (row) => {
    return (
      <Group spacing={4} position="center" noWrap>
        <ActionIcon
          color="blue"
          onClick={() => {
            editRol(row);
          }}
        >
          <i className="fas fa-file-pen"></i>
        </ActionIcon>
        <ActionIcon
          color="red"
          onClick={() => {
            onShowModal(row);
          }}
        >
          <i className="fas fa-trash"></i>
        </ActionIcon>
        <ModalDefault
          bandera={"confirmacion"}
          modal={showModal}
          size={"sm"}
          icontitulo={<null />}
          titulo={`Confirmación`}
          closeAction={onClosedModal}
        >
          <div className="row py-4 justify-content-center text-align-center ">
            Realmente desea eliminar
          </div>
          <div className="d-flex justify-content-end flex-row align-self-start my-4">
            <button
              type=""
              onClick={onClosedModal}
              className="bg-btn-vet-danger"
            >
              Cancelar
            </button>
            <button type="" onClick={onDelete} className="bg-btn-vet">
              <i className="fas fa-save"> Eliminar</i>
            </button>
          </div>
        </ModalDefault>
      </Group>
    );
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <DataTable
          borderRadius="md"
          className="bordecompleto bgfondo "
          highlightOnHover
          noRecordsText="No se encontraron datos"
          records={paginatedRoles}
          columns={columns}
          totalRecords={roles.length}
          recordsPerPage={PAGE_SIZE}
          page={page}
          onPageChange={handlePageChange}
          withColumnBorders
          fetching={fetching}
        />
      </div>
    </div>
  );
};

export default TablaOpciones;
