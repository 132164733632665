import React, { useEffect, useState } from "react";
import ModalDefault from "../../modalDefault";
import Select from "react-select";
import {
  ApiCreateTransmision,
  ApiDeleteTransmision,
  ApiToggleTransmision,
  ApiUpdateRecursoByTransmision,
} from "../../../api/transmisiones";
import { DataTable } from "mantine-datatable";
import toast from "react-hot-toast";
import { QRCodeCanvas } from "qrcode.react";

const url = process.env.REACT_APP_URL;

const TransmisionRecursos = ({
  recursos,
  transmisiones,
  updateTransmisionesFn,
  deleteTransmisionFn,
  toggleTransmisionFn,
  loading,
  isExternal,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const [recursosOptions, setRecursosOptions] = useState([]);
  const [selectedRecurso, setSelectedRecurso] = useState(null);

  const [isEditMode, setIsEditMode] = useState(false);
  const [editItemId, setEditItemId] = useState(null);

  const [deleteItemId, setDeleteItemId] = useState(null);
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState(false);

  const [toggleItemId, setToggleItemId] = useState(null);
  const [toggleConfirmationModalOpen, setToggleConfirmationModalOpen] =
    useState(false);

  const [UrlTransmision, setUrlTransmision] = useState("");
  const [modalUrl, setModalUrl] = useState(false);
  const [copied, setCopied] = useState(false);

  const [state, setState] = useState({
    nombre: "",
    recurso_documento_id: "",
  });

  useEffect(() => {
    //filtrar los recursos que sus archivos sean de tipo video
    if (loading) return;

    const filteredRecursos = recursos.filter((recurso) =>
      recurso.recursos_documentos_files.every(
        (file) => file.type.split("/")[0] === "video"
      )
    );

    const options = filteredRecursos.map((recurso) => ({
      value: recurso.recurso_documento_id,
      label: recurso.nombre,
    }));

    setRecursosOptions(options);
  }, [loading, recursos]);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = async () => {
    if (isEditMode && editItemId !== null) {
      // Si estamos en modo de edición, actualizamos el elemento existente
      const res = await ApiUpdateRecursoByTransmision({
        transmision_recurso_id: editItemId,
        recurso_documento_id: state.recurso_documento_id,
        nombre: state.nombre,
      });

      if (res.sms === "err") {
        console.log("error al actualizar la transmisión");
        return;
      }

      updateTransmisionesFn(res.data, true);
      toast.success("Transmisión actualizada con éxito");
    } else {
      // Si no estamos en modo de edición, creamos un nuevo elemento
      const res = await ApiCreateTransmision(state);

      if (res.sms === "err") {
        console.log("error al crear la transmisión");
        return;
      }

      updateTransmisionesFn(res.data);
      toast.success("Transmisión creada con éxito");
    }

    closeModal();
  };

  const openModal = (editMode = false, itemId = null) => {
    setModalOpen(true);
    setIsEditMode(editMode);
    setEditItemId(itemId);

    // Si estamos en modo de edición, cargamos los datos del elemento actual
    if (editMode && itemId !== null) {
      const editedItem = transmisiones.find(
        (transmision) => transmision.transmision_recurso_id === itemId
      );

      if (editedItem) {
        setState({
          nombre: editedItem.nombre,
          recurso_documento_id:
            editedItem.recursos_documentos.recurso_documento_id,
        });

        setSelectedRecurso({
          value: editedItem.recursos_documentos.recurso_documento_id,
          label: editedItem.recursos_documentos.nombre,
        });
      }
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setIsEditMode(false);
    setEditItemId(null);
    setState({
      nombre: "",
      recurso_documento_id: "",
    });
    setSelectedRecurso(null);
  };

  const openDeleteConfirmationModal = (itemId) => {
    setDeleteItemId(itemId);
    setDeleteConfirmationModalOpen(true);
  };

  const closeDeleteConfirmationModal = () => {
    setDeleteItemId(null);
    setDeleteConfirmationModalOpen(false);
  };

  const confirmDelete = async () => {
    if (deleteItemId !== null) {
      const res = await ApiDeleteTransmision(deleteItemId);

      if (res.sms === "err") {
        console.log("error al eliminar la transmisión");
        return;
      }

      toast.success("Transmisión eliminada con éxito");

      deleteTransmisionFn(deleteItemId);
      closeDeleteConfirmationModal();
    }
  };

  const openToggleConfirmationModal = (itemId) => {
    setToggleItemId(itemId);
    setToggleConfirmationModalOpen(true);
  };

  const closeToggleConfirmationModal = () => {
    setToggleItemId(null);
    setToggleConfirmationModalOpen(false);
  };

  const confirmToggle = async () => {
    if (toggleItemId !== null) {
      const res = await ApiToggleTransmision(toggleItemId);

      if (res.sms === "err") {
        toast.error("Error al cambiar el estado de la transmisión");
        return;
      }

      toast.success("Estado de la transmisión cambiado con éxito");

      toggleTransmisionFn(toggleItemId);
      closeToggleConfirmationModal();
    }
  };

  const onGenerateUrl = async (transmision_recurso_id) => {
    setUrlTransmision(`${url}transmision/${transmision_recurso_id}`);
    onShowModalUrl();
  };

  const onCloseModalUrl = () => {
    setModalUrl(false);
    setCopied(false);
  };

  const onShowModalUrl = () => {
    setModalUrl(true);
  };

  const copyToClipboard = () => {
    const textarea = document.createElement("textarea");
    textarea.value = UrlTransmision;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopied(true);
  };

  const columns = [
    {
      accessor: "transmitiendo",
      title: "📽️",
      textAlignment: "center",
      width: 40,
      render: (transmision) => {
        return transmision.transmitiendo ? "🔴" : "⭕";
      },
    },
    {
      accessor: "nombre",
      title: "Nombre",
      textAlignment: "center",
    },
    {
      accessor: "recursos_documentos.nombre",
      title: "Recurso",
      textAlignment: "center",
    },
    {
      accessor: "acciones",
      title: "Acciones",
      textAlignment: "center",
      render: (transmision) => opciones(transmision),
    },
  ];

  const opciones = (transmision) => {
    return (
      <>
        <span
          className="mx-2"
          onClick={() => {
            onGenerateUrl(transmision.transmision_recurso_id);
          }}
          style={{
            cursor: "pointer",
            color: "blue",
          }}
          title="Link para compartir la transmision"
        >
          <i className="fa fa-link"></i>
        </span>
        <span
          onClick={() => {
            openModal(true, transmision.transmision_recurso_id);
          }}
          style={{
            cursor: "pointer",
            color: "orange",
          }}
          title="Editar la transmision"
        >
          <i className="fa fa-edit"></i>
        </span>
        <span
          className="mx-2"
          style={{
            cursor: "pointer",
            color: transmision.transmitiendo === true ? "red" : "gray",
          }}
          title="Activar/Desactivar la transmision"
          onClick={() =>
            openToggleConfirmationModal(transmision.transmision_recurso_id)
          }
        >
          <i
            className={`fa fa-toggle-${
              transmision.transmitiendo === true ? "on" : "off"
            }`}
          ></i>
        </span>
        <span
          onClick={() => {
            openDeleteConfirmationModal(transmision.transmision_recurso_id);
          }}
          style={{
            cursor: "pointer",
          }}
          title="Eliminar la transmision"
        >
          <i className="fa fa-trash"></i>
        </span>
      </>
    );
  };

  return (
    <div className="row mt-3 mb-4">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="w-100">
          <h3>Transmisiones de Recursos de Video</h3>

          <button
            type=""
            className="bg-btn-vet bordesombreado my-2 mx-1"
            onClick={() => {
              openModal();
            }}
          >
            <i className="fa fa-plus mx-2"></i>
            Crear transmision
          </button>
        </div>
      </div>
      <div className="col-12">
        <DataTable
          borderRadius="md"
          className="bordecompleto"
          highlightOnHover
          noRecordsText="No se encontraron datos"
          records={transmisiones}
          columns={columns}
          totalRecords={transmisiones.length}
          withColumnBorders
          fetching={loading}
          style={{ maxHeight: "350px", overflowY: "auto" }}
        />
      </div>

      <ModalDefault
        bandera={"modalCreate"}
        modal={modalOpen}
        size={"md"}
        titulo={
          isEditMode
            ? "Editar transmisión"
            : "Crear transmisión de recurso de video"
        }
        closeAction={closeModal}
      >
        <div className="row">
          <div className="col-12">
            <label className="py-2 negrita">Nombre del recurso:</label>
            <input
              type="text"
              id="nombre"
              name="nombre"
              value={state.nombre}
              className="inputtext3 bordecompleto"
              placeholder="Digite el nombre"
              onChange={handleChange}
            />
          </div>
          <div className="col-12">
            <label className="py-2 negrita">Recurso de video:</label>
            <Select
              value={selectedRecurso}
              onChange={(e) => {
                setSelectedRecurso(e);
                setState({
                  ...state,
                  recurso_documento_id: e.value,
                });
              }}
              options={recursosOptions}
              isLoading={loading}
              placeholder="Seleccione un recurso"
            />
          </div>
          <div className="d-flex justify-content-end flex-row align-self-start my-3">
            <button
              type="button"
              onClick={handleSave}
              className="bg-btn-vet sombra"
            >
              <i className="fas fa-save mx-2"></i>
              Guardar
            </button>
          </div>
        </div>
      </ModalDefault>
      <ModalDefault
        bandera={"modalDeleteConfirmation"}
        modal={deleteConfirmationModalOpen}
        size={"sm"}
        titulo={"Confirmación de eliminación"}
        closeAction={closeDeleteConfirmationModal}
      >
        <div className="row">
          <div className="col-12">
            <p>¿Estás seguro de que deseas eliminar este elemento?</p>
          </div>
          <div className="d-flex justify-content-end flex-row align-self-start my-3">
            <button
              type="button"
              onClick={confirmDelete}
              className="bg-btn-vet-danger sombra"
            >
              Confirmar
            </button>
          </div>
        </div>
      </ModalDefault>
      <ModalDefault
        bandera={"modalToggleConfirmation"}
        modal={toggleConfirmationModalOpen}
        size={"sm"}
        titulo={"Confirmación de toggle"}
        closeAction={closeToggleConfirmationModal}
      >
        <div className="row">
          <div className="col-12">
            <p>
              ¿Estás seguro de que deseas activar/desactivar esta transmisión?
            </p>
          </div>
          <div className="d-flex justify-content-end flex-row align-self-start my-3">
            <button
              type="button"
              onClick={confirmToggle}
              className="bg-btn-vet sombra"
            >
              Confirmar
            </button>
          </div>
        </div>
      </ModalDefault>
      <ModalDefault
        bandera={"compartirUrl"}
        modal={modalUrl}
        size={"md"}
        titulo={`Compartir transmisión`}
        closeAction={onCloseModalUrl}
      >
        <div className="row py-4 d-flex flex-column justify-content-center text-align-center ">
          <div className="col-12">
            <textarea
              className="form-control"
              rows={2}
              readOnly
              value={UrlTransmision}
            ></textarea>
          </div>
          <div className="col-12 d-flex justify-content-end mt-2">
            <button
              onClick={copyToClipboard}
              className="bg-btn-vet sombra m-2 w-30"
            >
              {copied ? "Copiado" : "Copiar"}
            </button>
          </div>

          <h6 className="">O comparte el codigo QR</h6>

          <div className="d-flex justify-content-center mt-2">
            <QRCodeCanvas
              value={UrlTransmision}
              bgColor="#FFFFFF"
              fgColor="#000000"
              level="Q"
              size={300}
              includeMargin={true}
            />
          </div>
        </div>
      </ModalDefault>
    </div>
  );
};

export default TransmisionRecursos;
