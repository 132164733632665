import React, { useEffect, useState } from "react";
import ADMLayout from "./layouts/admin";
import SpaLayout from "./layouts/spa";
import withSplashScreen from "./layouts/Splash";

const App = () => {
  const [panelControl, setPanel] = useState(false);

  useEffect(() => {
    localStorage.setItem("req", JSON.stringify(null));
    localStorage.setItem("instancia", JSON.stringify(null));
  }, []);

  const setPanelControl = (value) => {
    setPanel(value);
    localStorage.setItem("panel", value);
  };

  useEffect(() => {
    if (localStorage.getItem("panel") === "false") {
      setPanel(false);
    } else {
      setPanel(true);
    }
  }, [panelControl]);
  
  return (
    <>
      {localStorage.getItem("accessTokenAdm") && panelControl ? (
        <div className="bgfondo2">
          <ADMLayout
            panelControl={panelControl}
            setPanelControl={setPanelControl}
          />
        </div>
      ) : (
        <SpaLayout
          panelControl={panelControl}
          setPanelControl={setPanelControl}
        />
      )}
    </>
  );
};

export default withSplashScreen(App);
