import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ApiCrearArea, ApiEditarAreas } from "../../../api/areas";
import { ApiGetAllProyectosxusuario } from "../../../api/proyectos";
import {
  ApiGetFichasxFormulario,
  ApiGetUsuariosFichasxFormularioWithDates,
} from "../../../api/usuarios";
import {
  ApiGetAllFormByIdProyect,
  ApiGetAllPreguntasByIdFormWithDates,
  ApiGetfirmularioXiD
  
} from "../../../api/formularios";
import { ApiObtenerAccesoCompartidos } from "../../../api/formularioCompartido";
import {
  ApiFichasxFormulario,
  ApiFichasxFormularioWithDates,
  ApiTotalFichasxFormularioWithDates,
} from "../../../api/fichas";
import dayjs from "dayjs";
const CrearReporte = ({
  updateListFn,
  state,
  setState,
  type,
  setType,
  resetState,
  setPreguntas,
  setUsersRecords,
  setVerCompartidos,
  verCompartidos,
  isAdmin,
  setFichasRecords,
  loadingData,
  setLoadingData,
}) => {
  const [loading, setlLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [forms, setForms] = useState([]);
  const [formsCompartidos, setFormsCompartidos] = useState([]);
  const [auxfichas, setAuxfichas] = useState([]);
  const [auxpreguntas, setAuxpreguntas] = useState([]);


  const dataUser = JSON.parse(localStorage.getItem("datauser"));
  //inicializar en hoy y un mes atras
  const [dateFilter, setDateFilter] = useState([
    dayjs().subtract(1, "month").toDate(),
    dayjs().toDate(),
  ]);
  
  const [pageSize, setPageSize] = useState(100);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const { data, mensaje, sms } = await ApiGetAllProyectosxusuario(
      dataUser.pre_usuario_id
    );

    setFormsCompartidos(dataUser.formulario_compartido);

    if (sms === "ok") {
      setProjects(data);
    } else {
      toast.error(mensaje, {
        position: "bottom-left",
      });
    }
  };

  useEffect(() => {
    setPreguntas([]);
    setForms([]);

    if (state.pre_proyecto_id !== "") {
      loadForm();
    }
  }, [state.pre_proyecto_id]);

  const loadForm = async () => {
    const { data } = await ApiGetAllFormByIdProyect(state.pre_proyecto_id);
    setForms(data);
  };

  useEffect(() => {
    if (state.pre_formulario_id !== "") {
      loadPreguntas(state.pre_formulario_id);
      obtenerAccesoCompartido(state.pre_formulario_id)

    }
  }, [state.pre_formulario_id]);

  useEffect(() => {
    if (state.pre_formulario_compartido_id !== "") {
      loadPreguntas(state.pre_formulario_compartido_id);
      obtenerAccesoCompartido(state.pre_formulario_compartido_id)

    }
  }, []);

  const loadPreguntas = async (pre_formulario_id) => {
    

   /*  if (restotal.data === 0) {
      setFichasRecords([]);
      setUsersRecords([]);
      setPreguntas([]);
      setLoadingData(false);
      return;
    }
 */
    /* setLoadingData(true);
    const inicio = dayjs(dateFilter[0]).format("DD-MM-YYYY");
    const fin = dayjs(dateFilter[1]).format("DD-MM-YYYY");
    const restotal = await ApiTotalFichasxFormularioWithDates({
      pre_formulario_id,
      fecha_inicio: inicio,
      fecha_fin: fin,
    });
    const totalPagesNew = Math.ceil(restotal.data / pageSize);
    for (let index = 1; index <= totalPagesNew; index++) {
      ejecutarListadoPresuntas(inicio, fin, pre_formulario_id, index);
    } */
    ejecutarListadoPresuntas(pre_formulario_id);

  };

  const ejecutarListadoPresuntas = async (
    pre_formulario_id
  ) => {
    setLoadingData(true);
    const inicio = dayjs(dateFilter[0]).format("DD-MM-YYYY");
    const fin = dayjs(dateFilter[1]).format("DD-MM-YYYY");
    const restotal = await ApiTotalFichasxFormularioWithDates({
      pre_formulario_id,
      fecha_inicio: inicio,
      fecha_fin: fin,
    });
    const totalPages= Math.ceil(restotal.data / pageSize);

    let { data, col, form, totalPages2 } =
      await ApiGetAllPreguntasByIdFormWithDates({
        pre_formulario_id,
        fecha_inicio: inicio,
        fecha_fin: fin,
        page: 1,
        pageSize: pageSize,
      });
      console.log("preguntas **********")
      console.log(data)

   
     
   /*  if (fichas.sms === "err") {
      toast.error(fichas.mensaje, {
        position: "bottom-left",
      });
      return;
    }
     */
    

    const fichasPages = totalPages;
    let auxfichas= [];

    //si fichasPages es mayor que 1, ir a la segunda página y repetir hasta que fichasPage sea igual al currentPage de fichas
    if (fichasPages > 0) {

      for (let i = 1; i <= fichasPages; i++) {

        const { data: fichas2 } = await ApiFichasxFormularioWithDates({
          pre_formulario_id,
          fecha_inicio: inicio,
          fecha_fin: fin,
          page: i,
          pageSize: pageSize,
        });

        console.log(fichas2)
        auxfichas = auxfichas.concat(fichas2);
      }
    }
    data=auxfichas
    console.log(data)
    

    //si preguntasPages es mayor que 1, ir a la segunda página y repetir hasta que preguntasPages sea igual al currentPage de preguntas
    /* if (preguntasPages > 1) {
      for (let i = 2; i <= preguntasPages; i++) {
        const { data: preguntas2 } = await ApiGetAllPreguntasByIdFormWithDates({
          pre_formulario_id,
          fecha_inicio: inicio,
          fecha_fin: fin,
          page: i,
          pageSize: pageSize,
        });

        data = data.concat(preguntas2);
      }
    } */

    setFichasRecords(auxfichas);
    setUsersRecords(data);
    setPreguntas(col);
    setLoadingData(false);
  };


  const obtenerAccesoCompartido=async(pre_formulario_id)=>{
    const res = await ApiObtenerAccesoCompartidos(pre_formulario_id);
    const resform = await ApiGetfirmularioXiD(pre_formulario_id);

    if (res.sms === "err") {
      toast.error(res.mensaje, {
        position: "bottom-left",
      });
      return;
    }

    const users = [];

    res.data.forEach((item) => {
      users.push(item.pre_usuario_id);
    });

    setState({
      ...state,
      pre_usuarios: users,
      compartidos: res.data,
      formulario: resform.data,
    });

  }

  const handleResetDate = () => {
    //verificar si hay formulario seleccionado
    if (state.pre_formulario_id === "") {
      toast.error("Seleccione un formulario", {
        position: "bottom-left",
      });
      return;
    }

    //verifica si hay un rango de fechas seleccionado si alguno de los dos es null
    if (dateFilter[0] === null || dateFilter[1] === null) {
      toast.error("Seleccione un rango de fechas", {
        position: "bottom-left",
      });
      return;
    }

    loadPreguntas(state.pre_formulario_id);
    obtenerAccesoCompartido(state.pre_formulario_id)
  };

  const handleResetDateCompartidos = () => {
    //verificar si hay formulario seleccionado
    if (state.pre_formulario_compartido_id === "") {
      toast.error("Seleccione un formulario", {
        position: "bottom-left",
      });
      return;
    }

    //verifica si hay un rango de fechas seleccionado si alguno de los dos es null
    if (dateFilter[0] === null || dateFilter[1] === null) {
      toast.error("Seleccione un rango de fechas", {
        position: "bottom-left",
      });
      return;
    }

    loadPreguntas(state.pre_formulario_compartido_id);
    obtenerAccesoCompartido(state.pre_formulario_compartido_id)

  };

  const fields = [
    { name: "pre_cod_area", label: "Codigo de Area" },
    { name: "pre_area", label: "Nombre del Area" },
  ];

  const onchange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const emptyFields = fields.filter((campo) => !state[campo.name]);
  const CrearFn = async () => {
    setlLoading(true);
    if (emptyFields.length > 0) {
      const mensajes = emptyFields.map(
        (campo) => `El campo "${campo.label}" no puede estar vacío.`
      );
      const mensajeError = mensajes.join("\n");
      toast.error(mensajeError, {
        position: "bottom-center",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
      setlLoading(false);
      return;
    }

    const res =
      type === "save" ? await ApiCrearArea(state) : await ApiEditarAreas(state);
    const datares = res;
    if (datares.sms === "ok") {
      toast.success(datares.mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid green",
          padding: "16px",
          color: "white",
          background: "green",
          borderColor: "white",
        },
        iconTheme: {
          primary: "green",
          secondary: "white",
        },
      });
      setType("save");
      resetState();
      updateListFn();
    } else {
      toast.error(datares.mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    }
    setlLoading(false);
  };

  return (
    <div>
      <div className="row mt-3 mb-4">
        <div className="col-md-6 col-lg-4  w-100">
          <div
            className=" bg-white  border-0 borde-tarjeta"
            style={{ height: "auto" }}
          >
            <div className="card-body m-0 p-0">
              <div className="w-100 bgfondo2 bordeabajo bordesombreado d-flex flex-column align-items-center d-flex justify-content-center py-3 px-3">
                <div className="w-100  d-flex justify-content-between align-items-center px-3">
                  <h5 className=" negrita card-title">
                    {isAdmin ? "Reportes" : "Reportes compartidos"}
                  </h5>
                  <div className="d-flex justify-content-between flex-row align-self-start">
                    <div className="d-flex justify-content-end ">
                      {isAdmin && (
                        <button
                          type=""
                          className="bg-btn-vet bordesombreado"
                          onClick={() => {
                            setVerCompartidos(!verCompartidos);
                            resetState();
                            setPreguntas([]);
                            setForms([]);
                          }}
                        >
                          {verCompartidos ? (
                            <>
                              <i className="fas fa-eye mx-2"></i>
                              Mis formularios ({pageSize})
                            </>
                          ) : (
                            <>
                              <i className="fas fa-share mx-2"></i>
                              Compartidos conmigo
                            </>
                          )}
                        </button>
                      )}
                    </div>
                    {/*  <div className="d-flex justify-content-end ">
                      {loading ? (
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <button
                          type=""
                          className="bg-btn-vet bordesombreado"
                          onClick={() => CrearFn()}
                        >
                          <i className="fas fa-save mx-2"></i>
                          Generar Reporte
                        </button>
                      )}
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="row py-4 ">
                {!verCompartidos ? (
                  <>
                    <div className="col-md-6 ">
                      <div className=" row">
                        <label className="x-0 py-2 negrita">
                          Listado de Proyectos :
                        </label>

                        <div className={"col-md-12 p-0 m-0"}>
                          <select
                            className="inputselect2 bordecompleto bordesombreado"
                            id="pre_proyecto_id"
                            name="pre_proyecto_id"
                            style={{ width: "100%" }}
                            onChange={onchange}
                            value={state.pre_proyecto_id}
                          >
                            <option value="">Seleccione una proyecto...</option>
                            {projects.length > 0
                              ? projects.map((data, index) => (
                                  <option
                                    value={data.pre_proyecto_id}
                                    key={index}
                                  >
                                    {data.proyecto}
                                  </option>
                                ))
                              : null}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      <div className=" row">
                        <label className="x-0 py-2 negrita">
                          Listado de Formularios :
                        </label>

                        <div className={"col-md-12 p-0 m-0"}>
                          <select
                            className="inputselect2 bordecompleto bordesombreado"
                            id="pre_formulario_id"
                            name="pre_formulario_id"
                            style={{ width: "100%" }}
                            onChange={onchange}
                            value={state.pre_formulario_id}
                            disabled={state.pre_proyecto_id === ""}
                          >
                            <option value="">
                              Seleccione una formulario...
                            </option>
                            {forms.length > 0
                              ? forms.map((data, index) => (
                                  <option
                                    value={data.pre_formulario_id}
                                    key={index}
                                  >
                                    {data.formulario}
                                  </option>
                                ))
                              : null}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      <div className=" row">
                        <label className="x-0 py-2 negrita">
                          Fecha inicio :
                        </label>

                        <div className={"col-md-12 p-0 m-0"}>
                          <input
                            type="date"
                            value={dayjs(dateFilter[0]).format("YYYY-MM-DD")}
                            onChange={(e) => {
                              const newStartDate = dayjs(
                                e.target.value
                              ).toDate();
                              setDateFilter([newStartDate, dateFilter[1]]);
                            }}
                            className="inputselect2 bordecompleto bordesombreado"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ">
                      <div className=" row">
                        <label className="x-0 py-2 negrita">Fecha fin :</label>

                        <div className={"col-md-12 p-0 m-0"}>
                          <input
                            type="date"
                            value={dayjs(dateFilter[1]).format("YYYY-MM-DD")}
                            onChange={(e) => {
                              const newEndDate = dayjs(e.target.value).toDate();
                              setDateFilter([dateFilter[0], newEndDate]);
                            }}
                            className="inputselect2 bordecompleto bordesombreado"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 ">
                      <div className={"col-2 px-1 m-0 w-100"}>
                        <label className="x-0 py-2 negrita w-100">
                          Recargar :
                        </label>
                        <button
                          type=""
                          className="bg-btn-vet bordesombreado"
                          onClick={handleResetDate}
                          style={{
                            height: "45px",
                            width: "100%",
                          }}
                        >
                          <i className="fas fa-repeat"></i>
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-4 ">
                      <div className=" row">
                        <label className="x-0 py-2 negrita">
                          Compartidos conmigo:
                        </label>

                        <div className={"col-md-12 p-0 m-0"}>
                          <select
                            className="inputselect2 bordecompleto bordesombreado"
                            id="pre_formulario_compartido_id"
                            name="pre_formulario_compartido_id"
                            style={{ width: "100%" }}
                            onChange={onchange}
                            value={state.pre_formulario_compartido_id}
                          >
                            <option value="">
                              Seleccione una formulario...
                            </option>
                            {formsCompartidos.length > 0 ? (
                              formsCompartidos.map((data, index) => (
                                <option
                                  value={data.pre_formulario_id}
                                  key={index}
                                >
                                  {data.pre_formulario.proyecto} -{" "}
                                  {data.pre_formulario.formulario}
                                </option>
                              ))
                            ) : (
                              <option value="">
                                No se han compartido formularios contigo
                              </option>
                            )}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 ">
                      <div className=" row">
                        <label className="x-0 py-2 negrita">
                          Fecha inicio :
                        </label>

                        <div className={"col-md-12 p-0 m-0"}>
                          <input
                            type="date"
                            value={dayjs(dateFilter[0]).format("YYYY-MM-DD")}
                            onChange={(e) => {
                              const newStartDate = dayjs(
                                e.target.value
                              ).toDate();
                              setDateFilter([newStartDate, dateFilter[1]]);
                            }}
                            className="inputselect2 bordecompleto bordesombreado"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 ">
                      <div className=" row">
                        <label className="x-0 py-2 negrita">Fecha fin :</label>

                        <div className={"col-md-12 p-0 m-0"}>
                          <input
                            type="date"
                            value={dayjs(dateFilter[1]).format("YYYY-MM-DD")}
                            onChange={(e) => {
                              const newEndDate = dayjs(e.target.value).toDate();
                              setDateFilter([dateFilter[0], newEndDate]);
                            }}
                            className="inputselect2 bordecompleto bordesombreado"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 ">
                      <div className={"col-2 px-1 m-0 w-100"}>
                        <label className="x-0 py-2 negrita w-100">
                          Recargar :
                        </label>
                        <button
                          type=""
                          className="bg-btn-vet bordesombreado"
                          onClick={handleResetDateCompartidos}
                          style={{
                            height: "45px",
                            width: "100%",
                          }}
                        >
                          <i className="fas fa-repeat"></i>
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrearReporte;
