import React from "react";
import { Button, Group } from "@mantine/core";


const NumericKeyboard = ({ onKeyClick }) => {
  const handleClick = (key) => {
    onKeyClick(key);
  };

  // Matriz de botones para el teclado numérico
  const buttons = [
    [7, 8, 9],
    [4, 5, 6],
    [1, 2, 3],
    [0, "borrar", "enter"],
  ];

  return (
    <Group position="center" mt={10} className="d-flex flex-column w-100">
      {buttons.map((row, rowIndex) => (
        <Group key={rowIndex} mb={10}>
          {row.map((buttonKey, columnIndex) => (
            <Button
              size="xl"
              variant="default"
              key={columnIndex}
              onClick={() => handleClick(buttonKey)}
              h={90}
              w={90}
              style={{
                borderColor: "#6991cc",
              }}
            >
              {buttonKey === 'borrar' ? (
                <i className="fas fa-backspace" style={{ fontSize: '1.2rem' }} />
              ) : buttonKey === 'enter' ? (
                <i className="fas fa-check" style={{ fontSize: '0.9rem' }} />
              ) : (
                <span style={{ fontSize: "1.5rem", paddingLeft: "2px", paddingRight: "2px" }}>{buttonKey}</span>
              )}
            </Button>
          ))}
        </Group>
      ))}
    </Group>
  );
};

export default NumericKeyboard;
