import { useEffect, useState } from "react";
//import { socket } from "../config/urls";

import {
  ApiGetInstanciasAccidentes,
  ApiToggleEstadoInstanciaAccidente,
  ApiToggleTipoInstanciaAccidente,
} from "../api/instanciaAccidentes";
import { Button } from "@mantine/core";
import ModalDefault from "./modalDefault";
import FichaDetalleCard from "../pages/spa/FichaDetalleCard";

const ContadorIncidentes = () => {
  const [incidentes, setIncidentes] = useState([]);
  const [diasSinAccidentes, setDiasSinAccidentes] = useState(0);

  const [showFichaModal, setShowFichaModal] = useState(false);
  const [fichaSeleccionada, setFichaSeleccionada] = useState(null);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    console.log(fichaSeleccionada);
  }, [fichaSeleccionada]);

  const init = async () => {
    const res = await ApiGetInstanciasAccidentes();
    if (res.sms === "ok") {
      setIncidentes(res.data);
      calcularDiasSinAccidentes(res.data);
    }
  };

//  socket.on("updateIncidentes", (data) => {
//    console.log("*****//////////////////********");
//    console.log(data); // x8WIv7-mJelg7on_ALbx
//    init();
//  });

  const convertirFecha = (fechaStr) => {
    const [dia, mes, año] = fechaStr.split("/").map(Number);
    return new Date(año, mes - 1, dia);
  };

  const calcularDiasSinAccidentes = (instancias) => {
    console.log(instancias);
    // Filtra instancias con bajas (ajusta este filtro según tu lógica)
    const instanciasConBajas = instancias.filter(
      (instancia) => instancia.tipo_accidente === "Con baja"
    );

    if (instanciasConBajas.length === 0) {
      // Si no hay accidentes con bajas, se puede mostrar el total de días desde el inicio del registro
      return;
    }

    // Encuentra la fecha más reciente de accidente con baja
    const fechaMasReciente = instanciasConBajas.reduce(
      (fechaReciente, instancia) => {
        const fechaInstancia = convertirFecha(instancia.fecha_accidente);
        return fechaInstancia > fechaReciente ? fechaInstancia : fechaReciente;
      },
      new Date(0)
    );
    console.log("********");
    console.log("fecha mas reciente con baja");
    console.log(fechaMasReciente);

    // Calcula la diferencia en días desde la fecha más reciente hasta hoy
    const diferenciaDias = Math.floor(
      (new Date() - fechaMasReciente) / (1000 * 60 * 60 * 24)
    );

    setDiasSinAccidentes(diferenciaDias);
  };

  return (
    <div className="container">
      <div className="m-3">
        <div className="row">
          <div className="col-md-6 my-1">
            <div className="card h-100" style={{ background: "#d50000 " }}>
              <div className="card-body">
                <h5 className="card-title letrablanca">Incidentes</h5>
                <div className="bordeabajo my-3" />
                <div className="w-100 ">
                  <div
                    className="card-text negrita letrablanca"
                    style={{ fontSize: "100px" }}
                  >
                    {incidentes.length > 0 ? (
                      <div style={{ overflowX: "auto" }}>
                        <table
                          className="w-100 my-2"
                          style={{ minWidth: "600px" }}
                        >
                          <tbody className="w-100 p-2">
                            {incidentes.map((data) => (
                              <tr
                                className="w-100 bordecompleto bordesombreado my-2"
                                style={{
                                  background: "#d50000",
                                  textAlign: "center",
                                }}
                                key={data.instancia_accidente_id}
                              >
                                <td className="letrablan px-1">Fecha:</td>
                                <td className="letrablan px-1">
                                  {data.fecha_accidente}
                                </td>
                                <td className="letrablan px-1">
                                  {data.tipo_accidente}
                                </td>
                                <td className="letrablan px-1">
                                  {data.instancias_accidentes_fichas &&
                                  data.instancias_accidentes_fichas.length >
                                    0 ? (
                                    data.instancias_accidentes_fichas.map(
                                      (ficha) => (
                                        <Button
                                          key={ficha.ficha_id}
                                          variant="light"
                                          color="red"
                                          size="xs"
                                          className="m-1"
                                          onClick={() => {
                                            setFichaSeleccionada(ficha);
                                            setShowFichaModal(true);
                                          }}
                                        >
                                          <i className="fas fa-eye me-2"></i>
                                          {
                                            ficha.pre_fichas.pre_formularios
                                              .formulario
                                          }
                                        </Button>
                                      )
                                    )
                                  ) : (
                                    <Button
                                      variant="light"
                                      color="red"
                                      size="xs"
                                      className="my-1"
                                      onClick={(e) => e.preventDefault()}
                                      disabled
                                    >
                                      No hay fichas
                                    </Button>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div>
                        <h1>"No se registraron incidentes"</h1>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <small className="letrablanca">listado de accidentes</small>
              </div>
              {/*    <div className="w-100 bordearriba d-flex justify-content-center align-items-center align-self-center ">
                <p className="p-2  m-0 letrablanca">Ver los incidentes</p>
              </div> */}
            </div>
          </div>
          <div className="col-md-6 my-1">
            {diasSinAccidentes !== -1 && (
              <div className="card h-100" style={{ background: "green" }}>
                <div className="card-body">
                  <h5 className="card-title letrablanca">Sin incidentes</h5>
                  <div className="bordeabajo my-3" />
                  {/*                   <h6 className="card-subtitle mb-2 letrablanca">Total dias</h6>
                   */}{" "}
                  <div className="d-flex justify-content-center ">
                    <div>
                      <h1
                        className="card-text negrita letrablanca"
                        style={{ fontSize: "100px" }}
                      >
                        {diasSinAccidentes}
                      </h1>
                      <h1 className="letrablanca">Dias</h1>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <small className="letrablanca">
                    El numero mostrado en el contador refleja los dias sin
                    incidentes desde el ultimo incidente en la planta
                  </small>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ModalDefault
        bandera={"verFicha"}
        modal={showFichaModal}
        size={"lg"}
        titulo={`Detalle de la Ficha`}
        closeAction={() => setShowFichaModal(false)}
      >
        <div>
          {fichaSeleccionada &&
          fichaSeleccionada.pre_fichas?.pre_fichas_preguntas ? (
            fichaSeleccionada.pre_fichas.pre_fichas_preguntas.map(
              (fichaPregunta, index) => (
                <FichaDetalleCard key={index} fichaPregunta={fichaPregunta} />
              )
            )
          ) : (
            <div className="alert alert-danger" role="alert">
              No hay respuestas disponibles para esta ficha.
            </div>
          )}
        </div>
      </ModalDefault>
    </div>
  );
};

export default ContadorIncidentes;
