import Axios from "../config/Axios";
export const ApiCrearProyectos = async (data) => {
  try {
    const result = await Axios.post(`/POSTcreateProyectos`, data);
    return result.data;
  } catch (e) {
    return {
      sms: "err",
      data: [],
      mensaje: e,
    };
  }
};

export const ApiEditarProyectos = async (data) => {
  try {
    const result = await Axios.post(`/POSTeditProyectos`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetAllProyectosxAreas = async (pre_cod_area) => {
  try {
    const result = await Axios.get(`/GETallProyectosAreas/${pre_cod_area}`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetAllProyectos = async () => {
  try {
    const result = await Axios.get(`/GETallProyectos`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e.code,
      },
    ];
  }
};

export const ApiGetAllProyectosxusuario = async (pre_usuario_id) => {
  try {
    const result = await Axios.get(`/GETallProyectosUser/${pre_usuario_id}`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e.code,
      },
    ];
  }
};

export const ApiGetAllProyectosSinPublicar = async (pre_usuario_id) => {
  try {
    const result = await Axios.get(
      `/GETallProyectosSinPublicar/${pre_usuario_id}`
    );
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e.code,
      },
    ];
  }
};

export const ApiGetallProyectosExternos = async () => {
  try {
    const result = await Axios.get(`/GETallProyectosExternos`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e.code,
      },
    ];
  }
};

export const ApiGetAllProyectosSinPublicarxUsuario = async (pre_usuario_id) => {
  try {
    const result = await Axios.get(
      `/GETallProyectosSinPublicarUser/${pre_usuario_id}`
    );
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e.code,
      },
    ];
  }
};

export const ApiGetAllProyectosReportes = async () => {
  try {
    const result = await Axios.get(`/GETallProyectosReportes`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e.code,
      },
    ];
  }
};

export const ApiGetDataProyecto = async (pre_proyecto_id) => {
  try {
    const result = await Axios.get(`/GETdataProyecto/${pre_proyecto_id}`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiActivarDesactivarProyecto = async (data) => {
  try {
    const result = await Axios.post(`/GETActivarDesactivarProyectos`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiPublicarDesPublicarProyecto = async (data) => {
  try {
    const result = await Axios.post(`/GETPublicarrDesactivarProyectos`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetAllFormulariosSistema = async () => {
  try {
    const result = await Axios.get(`/GETAllFormulariosSistema`);
    return result.data;
  } catch (e) {
    return {
      sms: "err",
      data: [],
      mensaje: e,
    };
  }
};
