import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import FormularioData from "../pages/admin/formularios/index";
import SpaPage from "../pages/spa/index";
import AuthPage from "../pages/spa/login";
import IncidentesPage from "../pages/spa/incidentes";
import Reportes from "../pages/admin/reportes";
import { Nav } from "../pages/spa/Nav";
import RecursosDelSistemaPage from "../pages/admin/recursos";
import Reproductor from "../pages/spa/reproductor";
import Documentos from "../pages/spa/Documentos";
import DocumentosIndex from "../pages/spa/DocumentosIndex";

const SpaLayout = ({ panelControl, setPanelControl }) => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <SpaPage
              panelControl={panelControl}
              setPanelControl={setPanelControl}
            />
          }
        ></Route>
        <Route
          path="/incidentes"
          element={
            <IncidentesPage
              panelControl={panelControl}
              setPanelControl={setPanelControl}
              isExternal={true}
            />
          }
        ></Route>
        <Route path="/auth" element={<AuthPage />}></Route>

        <Route
          path="/proyectos/formulario/:formularioid/:isExternal"
          element={
            <>
              <Nav
                panelControl={panelControl}
                setPanelControl={setPanelControl}
              />
              <FormularioData isExternal={false} />
            </>
          }
        />
        <Route
          path="/reportes/compartidos"
          element={
            <>
              <Nav
                panelControl={panelControl}
                setPanelControl={setPanelControl}
              />
              <Reportes isAdmin={false} />
            </>
          }
        />
        <Route
          path="/recursos"
          element={
            <>
              <Nav
                panelControl={panelControl}
                setPanelControl={setPanelControl}
              />
              <RecursosDelSistemaPage isExternal={true} />
            </>
          }
        />
        <Route
          path="/transmision/:transmision_recurso_id"
          element={<Reproductor />}
        />
        <Route
          path="/mis-documentos"
          element={
            <>
              <Nav
                panelControl={panelControl}
                setPanelControl={setPanelControl}
              />
              <Documentos />
            </>
          }
        />
        <Route path="/documentos" element={<DocumentosIndex />} />
        <Route path="*" element={<> </>} />
      </Routes>
    </Router>
  );
};

export default SpaLayout;
