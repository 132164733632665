import React, { useEffect, useRef, useState } from "react";
import ModalDefault from "../../modalDefault";
import { DataTable } from "mantine-datatable";
import { toast } from "react-hot-toast";
import {
  ApiActivarDesactivarDepartamento,
  ApiGetAllDepartamentos,
} from "../../../api/departamentos";
import { ActionIcon, Group, TextInput } from "@mantine/core";

const BATCH_SIZE = 10;

const TablaDepartamentos = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [allDepartamentos, setAllDepartamentos] = useState([]);
  const [filteredDepartamentos, setFilteredDepartamentos] = useState([]);
  const [fetching, setfetching] = useState(true);
  const [selectedDepartamento, setSelectedDepartamento] = useState(null);

  // Estados para scroll infinito y filtros
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadedRecords, setLoadedRecords] = useState(BATCH_SIZE);
  const [queryDepartamento, setQueryDepartamento] = useState("");
  const [queryCodigo, setQueryCodigo] = useState("");
  const [queryArea, setQueryArea] = useState("");
  const scrollViewportRef = useRef(null);

  const columns = [
    {
      accessor: "pre_area_id",
      title: "Area",
      textAlignment: "center",
      filter: (
        <TextInput
          placeholder="Buscar por area..."
          value={queryArea}
          onChange={(e) => setQueryArea(e.currentTarget.value)}
        />
      ),
      filtering: queryArea !== "",
      render: (record) =>
        `${record.pre_areas.pre_cod_area} - ${record.pre_areas.pre_area}`,
    },
    {
      accessor: "pre_departamento",
      title: "Departamento",
      textAlignment: "center",
      filter: (
        <TextInput
          placeholder="Buscar por departamento..."
          value={queryDepartamento}
          onChange={(e) => setQueryDepartamento(e.currentTarget.value)}
        />
      ),
      filtering: queryDepartamento !== "",
    },
    {
      accessor: "pre_cod_departamento",
      title: "Código",
      textAlignment: "center",
      filter: (
        <TextInput
          placeholder="Buscar por código..."
          value={queryCodigo}
          onChange={(e) => setQueryCodigo(e.currentTarget.value)}
        />
      ),
      filtering: queryCodigo !== "",
    },
    {
      accessor: "acciones",
      title: "Acciones",
      textAlignment: "center",
      render: (record) => opciones(record),
    },
  ];

  useEffect(() => {
    init();
  }, [props.updateList]);

  const init = async () => {
    setfetching(true);
    const { data } = await ApiGetAllDepartamentos();
    const filterDepartamentos = data.filter(
      (departamento) => departamento.estado !== false
    );
    setAllDepartamentos(filterDepartamentos);
    setFilteredDepartamentos(filterDepartamentos);
    setfetching(false);
  };

  // Logica para filtros
  useEffect(() => {
    const filtered = allDepartamentos.filter((departamento) => {
      const matchDepartamento = departamento.pre_departamento
        .toLowerCase()
        .includes(queryDepartamento.toLowerCase());
      const matchCodigo = departamento.pre_cod_departamento
        .toLowerCase()
        .includes(queryCodigo.toLowerCase());
      const matchArea =
        `${departamento.pre_areas.pre_cod_area} - ${departamento.pre_areas.pre_area}`
          .toLowerCase()
          .includes(queryArea.toLowerCase());

      return matchDepartamento && matchCodigo && matchArea;
    });
    setFilteredDepartamentos(filtered);
  }, [allDepartamentos, queryDepartamento, queryCodigo, queryArea]);

  // Carga más datos cuando se llega al final del scroll
  const loadMoreData = () => {
    if (loadedRecords < filteredDepartamentos.length) {
      setLoadingMore(true);
      setTimeout(() => {
        setLoadedRecords(loadedRecords + BATCH_SIZE);
        setLoadingMore(false);
      }, 1000);
    }
  };

  const visibleDepartamentos = filteredDepartamentos.slice(
    0,
    Math.min(loadedRecords, filteredDepartamentos.length)
  );

  const editDepartamento = (row) => {
    const {
      pre_departamento,
      pre_cod_departamento,
      pre_departamento_id,
      pre_area_id,
    } = row;
    props.setType("editar");
    props.setState({
      ...props.state,
      pre_area_id,
      pre_departamento,
      pre_cod_departamento,
      pre_departamento_id,
    });
  };

  const onShowModal = (e) => {
    setShowModal(true);
    setSelectedDepartamento(e);
  };

  const onClosedModal = () => {
    setShowModal(false);
    setSelectedDepartamento(null);
  };

  const onDelete = async () => {
    const { estado, pre_departamento_id } = selectedDepartamento;
    const { sms, mensaje } = await ApiActivarDesactivarDepartamento({
      pre_departamento_id,
      pre_estado: !estado,
    });
    if (sms === "ok") {
      toast.success(mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid green",
          padding: "16px",
          color: "white",
          background: "green",
          borderColor: "white",
        },
        iconTheme: {
          primary: "green",
          secondary: "white",
        },
      });
      setSelectedDepartamento(null);
      props.updateListFn();
    } else {
      toast.error(mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    }
    onClosedModal();
  };

  const opciones = (row) => {
    return (
      <Group spacing={4} position="center" noWrap>
        <ActionIcon
          color="blue"
          onClick={() => {
            editDepartamento(row);
          }}
        >
          <i className="fas fa-file-pen"></i>
        </ActionIcon>
        <ActionIcon
          color="red"
          onClick={() => {
            onShowModal(row);
          }}
        >
          <i className="fas fa-trash"></i>
        </ActionIcon>
        <ModalDefault
          bandera={"confirmacion"}
          modal={showModal}
          size={"sm"}
          icontitulo={<null />}
          titulo={`Confirmación`}
          closeAction={onClosedModal}
        >
          <div className="row py-4 justify-content-center text-align-center ">
            Realmente desea eliminar
          </div>
          <div className="d-flex justify-content-end flex-row align-self-start my-4">
            <button
              type=""
              onClick={onClosedModal}
              className="bg-btn-vet-danger sombra mx-1"
            >
              Cancelar
            </button>
            <button
              type=""
              onClick={onDelete}
              className="bg-btn-vet sombra mx-1"
            >
              <i className="fas fa-save"> Eliminar</i>
            </button>
          </div>
        </ModalDefault>
      </Group>
    );
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <DataTable
          borderRadius="md"
          className="bordecompleto bgfondo "
          highlightOnHover
          noRecordsText="No se encontraron datos"
          columns={columns}
          totalRecords={allDepartamentos.length}
          withColumnBorders
          records={visibleDepartamentos}
          fetching={fetching || loadingMore}
          onScrollToBottom={loadMoreData}
          scrollViewportRef={scrollViewportRef}
          style={{ maxHeight: "350px", overflowY: "auto" }}
        />
      </div>
    </div>
  );
};

export default TablaDepartamentos;
