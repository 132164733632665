import React, { useEffect, useState } from "react";
import CrearUsuario from "../../../components/admin/usuarios/usuarios";
import ListadoUsuarios from "../../../components/admin/usuarios/listadoUsuarios";
import CargaUsuarios from "../../../components/admin/usuarios/cargamasivaexel/CargaDeDatosExcelSeguro";

const initialState = {
  usuario: "",
  nombres: "",
  apellidos: "",
  contrasena: "",
  pre_rol_id: "",
  pre_area_id: "",
  pre_departamento_id: "",
  pre_centro_costo_id: "",
  pre_cargo_id: "",
  correo: "",
  telefono: "",
  code: "",
  genero: "",
};
const Usuarios = () => {
  const [updateList, setUpdateList] = useState(false);
  const [type, setType] = useState("save");
  const [state, setState] = useState(initialState);
  const [showModalSave, setShowModalSave] = useState(false);
  useEffect(() => {
    return () => {};
  }, []);

  const updateListFn = () => {
    setUpdateList(!updateList);
  };

  const resetState = () => {
    setState(initialState);
  };
  return (
    <div className="h-100 px-3 bgfondo2 d-flex justify-content-start flex-column ">
      <CrearUsuario
        updateListFn={updateListFn}
        state={state}
        setState={setState}
        type={type}
        setType={setType}
        resetState={resetState}
        showModalSave={showModalSave}
        setShowModalSave={setShowModalSave}
      />
      <ListadoUsuarios
        updateList={updateList}
        updateListFn={updateListFn}
        setState={setState}
        setType={setType}
        showModalSave={showModalSave}
        setShowModalSave={setShowModalSave}
      />
    </div>
  );
};

export default Usuarios;
