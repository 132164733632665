import Axios from "../config/Axios";
export const ApiSyncUsersFromExcel = async (data) => {
  try {
    const result = await Axios.post(`/POSTAsyncUsersFromExcel`, data);
    return result.data;
  } catch (e) {
    return {
      sms: "err",
      data: [],
      mensaje: e,
    };
  }
};
