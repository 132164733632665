const categorias = {
  auditorias: "Auditorías Internas",
  capacitaciones: "Material de Capacitaciones",
  contratos: "Contratos y Acuerdos",
  "evaluaciones-riesgos": "Evaluaciones de Riesgos",
  fotografias: "Fotografías",
  "informes-ambientales": "Informes Ambientales",
  inspecciones: "Informes de Inspecciones",
  manuales: "Manuales",
  mapas: "Mapas de Instalaciones",
  normativas: "Normativas y Legislación",
  "planes-mantenimiento": "Planes de Mantenimiento",
  proyectos: "Documentación de Proyectos",
  protocolos: "Protocolos de Emergencia",
  registros: "Registros de Seguridad",
  reportes: "Reportes",
  tecnologia: "Recursos Tecnológicos",
  tutoriales: "Tutoriales",
  videos: "Videos",
  otras: "Otras",
};

export default categorias;
