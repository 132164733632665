import Axios from "../config/Axios";

export const ApiCrearRecurso = async (data) => {
  try {
    const result = await Axios.post(`/POSTcreateRecursos`, data);
    return result.data;
  } catch (e) {
    return {
      sms: "err",
      mensaje: e,
    };
  }
};

export const ApiGetRecursos = async () => {
  try {
    const result = await Axios.get(`/GETallRecursos`);
    return result.data;
  } catch (e) {
    return {
      sms: "err",
      mensaje: e,
    };
  }
};

export const ApiGetRecursosById = async (recurso_documento_id) => {
  try {
    const result = await Axios.get(`/GETRecurso/${recurso_documento_id}`);
    return result.data;
  } catch (e) {
    return {
      sms: "err",
      mensaje: e,
    };
  }
};

export const ApiUpdateRecurso = async (recurso) => {
  try {
    const result = await Axios.put(
      `/POSTeditRecurso/${recurso.recurso_documento_id}`,
      recurso
    );
    return result.data;
  } catch (e) {
    return {
      sms: "err",
      mensaje: e,
    };
  }
};

export const ApiDeleteRecurso = async (recurso_documento_id) => {
  try {
    const result = await Axios.post(
      `/POSTdeleteRecurso/${recurso_documento_id}`
    );
    return result.data;
  } catch (e) {
    return {
      sms: "err",
      mensaje: e,
    };
  }
};

export const ApiTogglePublico = async (data) => {
  try {
    const result = await Axios.post(`/POSTtoggleEstadoRecurso`, data);
    return result.data;
  } catch (e) {
    return {
      sms: "err",
      mensaje: e,
    };
  }
};
