import React, { useEffect, useState } from "react";

import toast from "react-hot-toast";
import {
  ApiCrearTipoOpciones,
  ApiEditarTipoOpcion,
} from "../../../api/tipo_opciones";
const CrearOpciones = ({
  updateListFn,
  state,
  setState,
  type,
  setType,
  resetState,
}) => {
  const [loading, setlLoading] = useState(false);
  const fields = [
    { name: "tipo", label: "Tipo" },
    { name: "icono", label: "Icono" },
    { name: "detalle", label: "Detalle" },
  ];

  const onchange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const emptyFields = fields.filter((campo) => !state[campo.name]);
  const CrearFn = async () => {
    setlLoading(true);
    if (emptyFields.length > 0) {
      const mensajes = emptyFields.map(
        (campo) => `El campo "${campo.label}" no puede estar vacío.`
      );
      const mensajeError = mensajes.join("\n");
      toast.error(mensajeError, {
        position: "bottom-center",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
      setlLoading(false);
      return;
    }
    const res =
      type === "save"
        ? await ApiCrearTipoOpciones(state)
        : await ApiEditarTipoOpcion(state);
    const datares = res;
    if (datares.sms === "ok") {
      toast.success(datares.mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid green",
          padding: "16px",
          color: "white",
          background: "green",
          borderColor: "white",
        },
        iconTheme: {
          primary: "green",
          secondary: "white",
        },
      });
      setType("save");
      resetState();
      updateListFn();
    } else {
      toast.error(datares.mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    }
    setlLoading(false);
  };

  return (
    <div>
      <div className="row  mt-3 mb-4">
        <div className="col-md-6 col-lg-4  w-100">
          <div
            className=" bg-white  border-0 borde-tarjeta"
            style={{ height: "auto" }}
          >
            <div className="card-body m-0 p-0">
              <div className="w-100 bgfondo2 bordeabajo bordesombreado d-flex flex-column align-items-center d-flex justify-content-center py-3 px-3">
                <div className="w-100  d-flex justify-content-between align-items-center px-3">
                  <h5 className=" negrita card-title">
                    {type === "save" ? "Crear" : "Editar"} Opciones
                  </h5>
                  <div className="d-flex justify-content-between flex-row align-self-start">
                    <div className="d-flex justify-content-end ">
                      {loading ? (
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <button
                          type=""
                          className="bg-btn-vet bordesombreado"
                          onClick={() => CrearFn()}
                        >
                          <i className="fas fa-save mx-2"></i>
                          {type === "save" ? "Guardar" : "Editar"} Tipo de opción
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row py-4 ">
                <div className="col-md-6">
                  <label className="py-2 negrita">Tipo</label>
                  <input
                    type="text"
                    id="tipo"
                    name="tipo"
                    value={state.tipo}
                    className="inputtext2 bordecompleto"
                    placeholder="Digite el tipo de Opcion"
                    onChange={onchange}
                  />
                </div>
                <div className="col-md-6">
                  <label className="py-2 negrita">Icono</label>
                  <input
                    type="text"
                    id="icono"
                    name="icono"
                    value={state.icono}
                    className="inputtext2 bordecompleto"
                    placeholder="Digite el icono"
                    onChange={onchange}
                  />
                </div>
                <div className="col-md-12">
                  <label className="py-2 negrita">Detalle</label>
                  <input
                    type="text"
                    id="detalle"
                    name="detalle"
                    value={state.detalle}
                    className="inputtext2 bordecompleto"
                    placeholder="Digite el detalle de Opcion"
                    onChange={onchange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrearOpciones;
