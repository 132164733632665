import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ApiGetAllRoles } from "../../../api/roles";
import { ApiCreateUser, ApiEditUser } from "../../../api/usuarios";
import { ApiGetAllAreas } from "../../../api/areas";
import { ApiGetAllDepartamentos } from "../../../api/departamentos";
import { ApiGetAllCentrosCosto } from "../../../api/centrosCosto";
import { ApiGetAllCargos } from "../../../api/cargos";
import ModalDefault from "../../modalDefault";
const CrearUsuario = ({
  updateListFn,
  state,
  setState,
  type,
  setType,
  resetState,
  showModalSave,
  setShowModalSave,
}) => {
  const [loading, setlLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [dataPassword, setDataPassword] = useState({});
  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const { data: Roles } = await ApiGetAllRoles();
    const rolesActivos = Roles.filter((data) => data.estado === true);
    setRoles(rolesActivos);

    const { data: dataAreas } = await ApiGetAllAreas();
    const areasActivas = dataAreas.filter((data) => data.estado === true);
    setAreas(areasActivas);
  };

  const onClosedModal = () => {
    setShowModal(false);
    setDataPassword({});
  };

  const [roles, setRoles] = useState([]);
  const [areas, setAreas] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [centros, setCentros] = useState([]);
  const [cargos, setCargos] = useState([]);

  useEffect(() => {
    if (state.pre_area_id !== "") {
      const getDepartamentos = async () => {
        const { data } = await ApiGetAllDepartamentos();
        setDepartamentos(
          data.filter(
            (data) =>
              data.pre_area_id === parseInt(state.pre_area_id) &&
              data.estado === true
          )
        );
      };
      getDepartamentos();
      setCentros([]);
      setCargos([]);
    } else {
      setDepartamentos([]);
      setCentros([]);
      setCargos([]);
      setState({
        ...state,
        pre_departamento_id: "",
        pre_centro_costo_id: "",
        pre_cargo_id: "",
      });
    }

    if (state.pre_departamento_id !== "") {
      const getCentros = async () => {
        const { data } = await ApiGetAllCentrosCosto();
        setCentros(
          data.filter(
            (data) =>
              data.pre_departamento_id ===
                parseInt(state.pre_departamento_id) && data.estado === true
          )
        );
      };
      getCentros();
      setCargos([]);
    } else {
      setCentros([]);
      setCargos([]);
      setState({ ...state, pre_centro_costo_id: "", pre_cargo_id: "" });
    }

    if (state.pre_centro_costo_id !== "") {
      const getCargos = async () => {
        const { data } = await ApiGetAllCargos();
        setCargos(
          data.filter(
            (data) =>
              data.pre_centro_costo_id ===
                parseInt(state.pre_centro_costo_id) && data.estado === true
          )
        );
      };
      getCargos();
    } else {
      setCargos([]);
      setState({ ...state, pre_cargo_id: "" });
    }
  }, [
    state.pre_area_id,
    state.pre_departamento_id,
    state.pre_centro_costo_id,
    state.pre_cargo_id,
  ]);

  const onchange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const CrearFn = async () => {
    setlLoading(true);
    if (state.usuario === "") {
      toast.error("El nombre Usuario se encuentra vacío", {
        position: "bottom-center",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
      setlLoading(false);
      return;
    }

    const res =
      type === "save" ? await ApiCreateUser(state) : await ApiEditUser(state);
    const datares = res;
    if (datares.sms === "ok") {
      if (type === "save") {
        setShowModal(true);
        setDataPassword(datares.data[0]);
      }

      toast.success(datares.mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid green",
          padding: "16px",
          color: "white",
          background: "green",
          borderColor: "white",
        },
        iconTheme: {
          primary: "green",
          secondary: "white",
        },
      });
      setShowModalSave(false);
      setType("save");
      resetState();
      updateListFn();
    } else {
      toast.error(datares.mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    }
    setlLoading(false);
  };

  const onCloseModal = () => {
    setShowModalSave(false);
    if (type !== "save") {
      setType("save");
      resetState();
    }
  };

  return (
    <div>
      <div className="row mt-3 mb-4">
        <div className="col-md-6 col-lg-4  w-100">
          <div
            className=" bg-white  bordecompleto borde-tarjeta"
            style={{ height: "auto" }}
          >
            <div className="card-body m-0 p-0">
              <div className="w-100 bgfondo2 bordeabajo bordesombreado d-flex flex-column align-items-center d-flex justify-content-center py-3 px-3">
                <div className="w-100  d-flex justify-content-between align-items-center px-3">
                  <h5 className=" negrita card-title">
                    {type === "save" ? "Crear" : "Editar"} Usuario
                  </h5>
                  <div className="d-flex justify-content-between flex-row align-self-start">
                    <div className="d-flex justify-content-end ">
                      <button
                        type=""
                        className="bg-btn-vet bordesombreado"
                        onClick={() => {
                          setShowModalSave(true);
                        }}
                      >
                        <i className="fa fa-plus mx-2"></i>
                        Crear usuario
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <ModalDefault
                bandera={"Crear usuario"}
                modal={showModalSave}
                size={"lg"}
                titulo={`Crear usuario`}
                closeAction={onCloseModal}
              >
                <div className="row py-4 ">
                  <div className="col-md-6">
                    <label className="py-2 negrita">Cedula</label>
                    <input
                      type="text"
                      id="usuario"
                      name="usuario"
                      value={state.usuario}
                      className="inputtext2 bordecompleto"
                      placeholder="Digite cedula"
                      onChange={onchange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="py-2 negrita">Codigo</label>
                    <input
                      type="text"
                      id="code"
                      name="code"
                      value={state.code}
                      className="inputtext2 bordecompleto"
                      placeholder="Digite el codigo"
                      onChange={onchange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="py-2 negrita">Nombre</label>
                    <input
                      type="text"
                      id="nombres"
                      name="nombres"
                      value={state.nombres}
                      className="inputtext2 bordecompleto"
                      placeholder="Digite el Nombre"
                      onChange={onchange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="py-2 negrita">Apellidos</label>
                    <input
                      type="text"
                      id="apellidos"
                      name="apellidos"
                      value={state.apellidos}
                      className="inputtext2 bordecompleto"
                      placeholder="Digite los Apellidos"
                      onChange={onchange}
                    />
                  </div>
                  <div className="col-md-6 ">
                    <div className=" row">
                      <label className="x-0 py-2 negrita">Genero</label>
                      <div className={"col-md-12 p-0 m-0"}>
                        <select
                          className="inputselect2 bordecompleto"
                          id="genero"
                          name="genero"
                          style={{ width: "100%" }}
                          onChange={onchange}
                          value={state.genero}
                        >
                          <option value="">Seleccione un Genero...</option>
                          <option value="Masculino">Masculino</option>
                          <option value="Femenino">Femenino</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="py-2 negrita">Correo</label>
                    <input
                      type="email"
                      id="correo"
                      name="correo"
                      value={state.correo}
                      className="inputtext2 bordecompleto"
                      placeholder="Digite el Correo"
                      onChange={onchange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="py-2 negrita">Telefono</label>
                    <input
                      type="text"
                      id="telefono"
                      name="telefono"
                      value={state.telefono}
                      className="inputtext2 bordecompleto"
                      placeholder="Digite numero telefonico"
                      onChange={onchange}
                    />
                  </div>

                  <div className="col-md-6 ">
                    <div className=" row">
                      <label className="x-0 py-2 negrita">Rol</label>

                      <div className={"col-md-12 p-0 m-0"}>
                        <select
                          className="inputselect2 bordecompleto"
                          id="pre_rol_id"
                          name="pre_rol_id"
                          style={{ width: "100%" }}
                          onChange={onchange}
                          value={state.pre_rol_id}
                        >
                          <option value="">Seleccione un Rol...</option>
                          {roles.length > 0
                            ? roles.map((data, index) => (
                                <option value={data.pre_rol_id} key={index}>
                                  {data.rol}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className=" row">
                      <label className="x-0 py-2 negrita">Area</label>

                      <div className={"col-md-12 p-0 m-0"}>
                        <select
                          className="inputselect2 bordecompleto"
                          id="pre_area_id"
                          name="pre_area_id"
                          style={{ width: "100%" }}
                          onChange={onchange}
                          value={state.pre_area_id}
                        >
                          <option value="">Seleccione un Area...</option>
                          {areas.length > 0
                            ? areas.map((data, index) => (
                                <option value={data.pre_area_id} key={index}>
                                  {`${data.pre_cod_area} - ${data.pre_area}`}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className=" row">
                      <label className="x-0 py-2 negrita">Departamento</label>

                      <div className={"col-md-12 p-0 m-0"}>
                        <select
                          className="inputselect2 bordecompleto"
                          id="pre_departamento_id"
                          name="pre_departamento_id"
                          style={{ width: "100%" }}
                          onChange={onchange}
                          value={state.pre_departamento_id}
                          disabled={state.pre_area_id === "" ? true : false}
                        >
                          <option value="">
                            Seleccione un Departamento...
                          </option>
                          {departamentos.length > 0
                            ? departamentos.map((data, index) => (
                                <option
                                  value={data.pre_departamento_id}
                                  key={index}
                                >
                                  {`${data.pre_cod_departamento} - ${data.pre_departamento}`}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className=" row">
                      <label className="x-0 py-2 negrita">
                        Centro de Costo
                      </label>

                      <div className={"col-md-12 p-0 m-0"}>
                        <select
                          className="inputselect2 bordecompleto"
                          id="pre_centro_costo_id"
                          name="pre_centro_costo_id"
                          style={{ width: "100%" }}
                          onChange={onchange}
                          value={state.pre_centro_costo_id}
                          disabled={
                            state.pre_departamento_id === "" ? true : false
                          }
                        >
                          <option value="">
                            Seleccione un Centro de Costo...
                          </option>
                          {centros.length > 0
                            ? centros.map((data, index) => (
                                <option
                                  value={data.pre_centro_costo_id}
                                  key={index}
                                >
                                  {`${data.pre_cod_centro_costo} - ${data.pre_centro_costo}`}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className=" row">
                      <label className="x-0 py-2 negrita">Cargo</label>

                      <div className={"col-md-12 p-0 m-0"}>
                        <select
                          className="inputselect2 bordecompleto"
                          id="pre_cargo_id"
                          name="pre_cargo_id"
                          style={{ width: "100%" }}
                          onChange={onchange}
                          value={state.pre_cargo_id}
                          disabled={
                            state.pre_centro_costo_id === "" ? true : false
                          }
                        >
                          <option value="">Seleccione un Cargo...</option>
                          {cargos.length > 0
                            ? cargos.map((data, index) => (
                                <option value={data.pre_cargo_id} key={index}>
                                  {`${data.pre_cod_cargo} - ${data.pre_cargo}`}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end flex-row align-self-start pt-4 px-4">
                    <div className="d-flex justify-content-end ">
                      {loading ? (
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <button
                          type=""
                          className="bg-btn-vet bordesombreado"
                          onClick={() => CrearFn()}
                        >
                          <i className="fas fa-save mx-2"></i>
                          {type === "save" ? "Guardar" : "Editar"} Usuario
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </ModalDefault>
            </div>
          </div>
        </div>
      </div>
      <ModalDefault
        bandera={"confirmacion"}
        modal={showModal}
        size={"md"}
        icontitulo={<null />}
        titulo={dataPassword.mensaje}
        closeAction={onClosedModal}
      >
        <div className="row py-4 justify-content-center text-align-center ">
          {dataPassword.contrasena}
        </div>
        <div className="d-flex justify-content-end flex-row align-self-start my-4">
          <button
            type=""
            onClick={onClosedModal}
            className="bg-btn-vet-danger sombra mx-1"
          >
            Cancelar
          </button>
        </div>
      </ModalDefault>
    </div>
  );
};

export default CrearUsuario;
