import React, { useEffect, useState } from "react";
import CrearRoles from "../../../components/admin/roles/roles";
import ListadoRoles from "../../../components/admin/roles/listadoRoles";

const initialState = {
  rol: "",
  code: "",
};
const Roles = () => {
  const [updateList, setUpdateList] = useState(false);
  const [type, setType] = useState("save");
  const [state, setState] = useState(initialState);
  useEffect(() => {
    return () => {};
  }, []);

  const updateListFn = () => {
    setUpdateList(!updateList);
  };

  const resetState = () => {
    setState(initialState);
  };
  return (
    <div className="h-100 px-3 bgfondo2 d-flex justify-content-start flex-column ">
       <CrearRoles
        updateListFn={updateListFn}
        state={state}
        setState={setState}
        type={type}
        setType={setType}
        resetState={resetState}
      />
     <ListadoRoles
        updateList={updateList}
        updateListFn={updateListFn}
        setState={setState}
        setType={setType}
      />
    </div>
  );
};

export default Roles;
