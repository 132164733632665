import Axios from "../config/Axios";
export const ApiCrearOpciones = async (data) => {
  try {
    const result = await Axios.post(`/POSTcreateOpciones`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiEditarOpcion = async (data) => {
  try {
    const result = await Axios.post(`/POSTeditOpcion`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetAllOpcioness = async (data) => {
  try {
    const result = await Axios.get(`/GETallOpcioness`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetDesactivarOpciones = async (data) => {
  try {
    const result = await Axios.post(
      `/GETActivarDesactivarOpciones`,data
    );
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetAllOpcionesPregunta = async (pre_pregunta_id) => {
  try {
    const result = await Axios.get(`/GETallOpcionesPreguntas/${pre_pregunta_id}`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

