import React, { useEffect, useState } from "react";
import { ApiGetAllSolicitudes } from "../../../api/solicitudes";
import { toast } from "react-hot-toast";
import { Badge, Button, Table, Progress } from "@mantine/core";
import {
  ApiRechazarSolicitud,
  ApiAprobarSolicitud,
} from "../../../api/solicitudes";
import ModalDefault from "../../../components/modalDefault";
import { uploadFile } from "../../../s3";
import { ApiDescargaFile } from "../../../api/formularios";

const Solicitudes = () => {
  const [solicitudes, setSolicitudes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [solicitud, setSolicitud] = useState(null);
  const [file, setFile] = useState(null);
  const [loadingApprove, setLoadingApprove] = useState(false);

  const [progress, setProgress] = useState({
    status: false,
    progress: 0,
    file: "",
  });

  const reset = () => {
    setProgress({
      status: false,
      progress: 0,
      file: "",
    });
    setFile(null);
    setSolicitud(null);
  };

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setLoading(true);
    const res = await ApiGetAllSolicitudes();

    if (res.sms === "err") {
      toast.error("Seleccione un documento", {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
      setLoading(false);
      return;
    }
    setSolicitudes(res.data);
    setLoading(false);
  };

  const rechazarSolicitud = async (sol) => {
    const res = await ApiRechazarSolicitud({ solicitud_id: sol.solicitud_id });

    if (res.sms === "err") {
      toast.error("Error al rechazar la solicitud", {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
      return;
    }

    toast.success("Solicitud rechazada", {
      position: "bottom-left",
      style: {
        border: "1px solid green",
        padding: "16px",
        color: "white",
        background: "green",
        borderColor: "white",
      },
      iconTheme: {
        primary: "green",
        secondary: "white",
      },
    });

    init();
  };

  const onCloseModal = () => {
    setModal(false);
    reset();
  };

  const aprobarSolicitud = async (sol) => {
    setSolicitud(sol);
    setModal(true);
  };

  const handleAprobarSolicitud = async () => {
    setLoadingApprove(true);

    const res = await uploadFile(file, setProgress);
    if (res.success) {
      const ress = await ApiAprobarSolicitud({
        solicitud_id: solicitud.solicitud_id,
        file: res.key,
      });

      if (ress.sms === "err") {
        toast.error("Error al aprobar la solicitud", {
          position: "bottom-left",
          style: {
            border: "1px solid red",
            padding: "16px",
            color: "white",
            background: "red",
            borderColor: "white",
          },
          iconTheme: {
            primary: "red",
            secondary: "white",
          },
        });
        setLoadingApprove(false);
        return;
      }

      toast.success("Solicitud aprobada", {
        position: "bottom-left",
        style: {
          border: "1px solid green",
          padding: "16px",
          color: "white",
          background: "green",
          borderColor: "white",
        },
        iconTheme: {
          primary: "green",
          secondary: "white",
        },
      });

      onCloseModal();
      reset();
      init();
    } else {
      toast.error("Error al subir el archivo", {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    }
    setLoadingApprove(false);
  };

  const onDowload = async (file) => {
    const { sms, url } = await ApiDescargaFile({ file: file });
    if (sms === "ok") {
      window.open(url, "_blank");
    }
  };

  return (
    <div className="h-100 px-3 bgfondo2 d-flex justify-content-start flex-column ">
      <div className="row mt-3 mb-4">
        <div className="col-md-6 col-lg-4  w-100">
          <div
            className=" bg-white  bordecompleto borde-tarjeta"
            style={{ height: "auto" }}
          >
            <div className="card-body m-0 p-0">
              <div className="w-100 bgfondo2 bordeabajo bordesombreado d-flex flex-column align-items-center d-flex justify-content-center py-3 px-3">
                <div className="w-100  d-flex justify-content-between align-items-center px-3">
                  <h5 className=" negrita card-title">Solicitudes</h5>
                  <div className="d-flex justify-content-between flex-row align-self-start">
                    <div className="d-flex justify-content-end ">
                      <button
                        type=""
                        className="bg-btn-vet bordesombreado"
                        onClick={() => {
                          console.log("Crear solicitud");
                        }}
                        style={{ visibility: "hidden" }}
                      >
                        <i className="fa fa-plus mx-2"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          {!loading ? (
            <Table
              striped
              highlightOnHover
              withBorder
              withColumnBorders
              style={{
                backgroundColor: "white",
              }}
            >
              <thead>
                <tr>
                  <th>Cedula</th>
                  <th>Nombre</th>
                  <th>Apellido</th>
                  <th>Documento</th>
                  <th>Archivo</th>
                  <th>Estado</th>
                  <th>Fecha y hora</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {solicitudes.map((sol, index) => {
                  return (
                    <tr key={index}>
                      <td>{sol.usuarios.usuario}</td>
                      <td>{sol.usuarios.nombres}</td>
                      <td>{sol.usuarios.apellidos}</td>
                      <td>{sol.documentos.nombre}</td>
                      <td>
                        {sol.file ? (
                          <Button
                            compact
                            variant="outline"
                            color="teal"
                            onClick={() => {
                              onDowload(sol.file);
                            }}
                          >
                            Descargar
                          </Button>
                        ) : (
                          "Sin contenido"
                        )}
                      </td>
                      <td>
                        {
                          <Badge
                            color={
                              sol.statud === "Pendiente"
                                ? "orange"
                                : sol.statud === "Aprobado"
                                ? "green"
                                : sol.statud === "Rechazado"
                                ? "red"
                                : "gray"
                            }
                            variant="filled"
                          >
                            {sol.statud}
                          </Badge>
                        }
                      </td>
                      <td>
                        {new Date(sol.create_at).toLocaleDateString() +
                          " " +
                          new Date(sol.create_at).toLocaleTimeString()}
                      </td>
                      <td>
                        <Button
                          disabled={sol.statud !== "Pendiente" ? true : false}
                          compact
                          variant="outline"
                          color="red"
                          onClick={() => {
                            rechazarSolicitud(sol);
                          }}
                          mr={5}
                          mb={5}
                        >
                          Rechazar
                        </Button>
                        <Button
                          disabled={sol.statud !== "Pendiente" ? true : false}
                          compact
                          variant="outline"
                          color="green"
                          onClick={() => {
                            aprobarSolicitud(sol);
                          }}
                        >
                          Aprobar
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <ModalDefault
        bandera={"confirmacion"}
        modal={modal}
        size={"md"}
        classnamebody={`p-0 m-0 w-100`}
        titulo={`Aprobar`}
        closeAction={() => onCloseModal()}
      >
        <div className="d-flex flex-column m-4">
          <h5>Para aprobar la solicitud, suba el archivo</h5>
          <div className="mt-3">
            <input
              className="form-control"
              type="file"
              onChange={(e) => {
                setFile(e.target.files[0]);
              }}
              style={{
                backgroundColor: "#f0f0f0",
              }}
            />
          </div>
          <Button
            className="mt-3"
            leftIcon={<i className="fa fa-check"></i>}
            loading={loadingApprove}
            onClick={() => {
              handleAprobarSolicitud();
            }}
          >
            Aprobar
          </Button>
        </div>
        <Progress
          radius={0}
          size={24}
          sections={[
            {
              value: progress.progress,
              color: "blue",
              label: `${progress.file} - ${progress.progress}%`,
            },
          ]}
        />
      </ModalDefault>
    </div>
  );
};

export default Solicitudes;
