import React, { useEffect, useState } from "react";
import Select from "react-select";
import toast from "react-hot-toast";
import makeAnimated from "react-select/animated";
import { ActionIcon } from "@mantine/core";

import {
  ApiCrearAsignaciones,
} from "../../../../api/asignaciones";

import { ApiGetAllGruposAcceso } from "../../../../api/grupos";
import { ApiGetAllFormulariosSistema } from "../../../../api/proyectos";

const animatedComponents = makeAnimated();

const CrearAsignaciones = ({
  updateListFn,
  state,
  setState,
  type,
  setType,
  resetState,
  setEsOrdenObligatorio,
  esOrdenObligatorio,
}) => {
  const dataUser = JSON.parse(localStorage.getItem("datauser"));
  const [loading, setlLoading] = useState(false);
  const [inputSets, setInputSets] = useState([
    { id: 1, selectedFormularios: [] },
  ]);
  const [nextId, setNextId] = useState(2);

  const [formularios, setFormularios] = useState([]);
  const [formOptions, setFormOptions] = useState([]);

  const [grupoOptions, setGrupoOptions] = useState([]);

  const [codigo, setCodigo] = useState("");

  const agregarMasInputs = () => {
    // Verifica si ya hay dos o más conjuntos de inputs
    if (inputSets.length >= 2) {
      toast.error(
        "No se pueden agregar más de dos conjuntos de inputs por ahora.",
        {
          position: "bottom-center",
        }
      );
      return;
    }
    setInputSets([...inputSets, { id: nextId, selectedFormularios: [] }]);
    setNextId(nextId + 1);
  };

  const eliminarInputSet = (id) => {
    setInputSets(inputSets.filter((inputSet) => inputSet.id !== id));
  };

  useEffect(() => {
    init();
    return () => {};
  }, []);

  useEffect(() => {
    if (type !== "save") {
    }
    return () => {};
  }, [state, type]);

  const init = async () => {
    const { data: gruposdb } = await ApiGetAllGruposAcceso();
    const gruposactivos = gruposdb.filter((item) => item.estado === true);
    const gruposse = gruposactivos.map((item) => {
      return {
        value: item.pre_grupo_acceso_id,
        label: item.nombre,
      };
    });
    setGrupoOptions(gruposse);

    const { sms, data, mensaje } = await ApiGetAllFormulariosSistema();

    // estuctura: data[0].pre_formularios[0].pre_asignacion_formularios
    //no mostrar los pre_formularios con pre_asignacion_formularios llenos
    const datafiltrada = data.map((item) => {
      return {
        proyecto: item.proyecto,
        pre_formularios: item.pre_formularios.filter(
          (item2) => item2.pre_asignacion_formularios.length === 0
        ),
      };
    });

    //console.log(data);
    //console.log(datafiltrada);

    if (sms === "ok") {
      setFormularios(datafiltrada);
    } else {
      toast.error(mensaje, {
        position: "bottom-left",
      });
    }
  };

  useEffect(() => {
    const formulariosop = formularios.flatMap((categoria) => {
      return categoria.pre_formularios.map((formulario) => {
        return {
          value: formulario.pre_formulario_id,
          label: `${categoria.proyecto} - ${formulario.formulario}`,
        };
      });
    });

    setFormOptions(formulariosop);
  }, [formularios]);

  const validarInputSets = () => {
    if (inputSets.length === 0) {
      toast.error("No hay inputs agregados, por favor agregue al menos uno.", {
        position: "bottom-center",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
      return false; // Retornar falso si la validación falla
    }

    for (let inputSet of inputSets) {
      if (
        !inputSet.selectedGrupo ||
        !inputSet.selectedFormularios ||
        inputSet.selectedFormularios.length === 0 ||
        codigo === ""
      ) {
        // Mostrar mensaje de error si no se seleccionó un grupo de acceso o si no hay formularios seleccionados
        toast.error(
          "Todos los campos deben estar completos, incluyendo la selección de grupos de acceso, formularios y codigo de la asignacion.",
          {
            position: "bottom-center",
            style: {
              border: "1px solid red",
              padding: "16px",
              color: "white",
              background: "red",
              borderColor: "white",
            },
            iconTheme: {
              primary: "red",
              secondary: "white",
            },
          }
        );
        return false;
      }
    }
    return true;
  };

  const CrearFn = async () => {
    setlLoading(true);
    if (!validarInputSets()) {
      setlLoading(false);
      return;
    }

    const req = transformarYGuardarSeleccion();

    try {
      const res = await ApiCrearAsignaciones(req);

      if (res.sms === "ok") {
        toast.success(res.mensaje, {
          position: "bottom-left",
          style: {
            border: "1px solid green",
            padding: "16px",
            color: "white",
            background: "green",
            borderColor: "white",
          },
          iconTheme: {
            primary: "green",
            secondary: "white",
          },
        });

        resetState();
        setInputSets([]);
        setNextId(1);
        setEsOrdenObligatorio(true);
        setCodigo("");
        updateListFn();
      } else {
        toast.error(res.mensaje, {
          position: "bottom-left",
          style: {
            border: "1px solid red",
            padding: "16px",
            color: "white",
            background: "red",
            borderColor: "white",
          },
          iconTheme: {
            primary: "red",
            secondary: "white",
          },
        });
      }
    } catch (error) {
      toast.error("Ocurrió un error al intentar crear la asignación.", {
        position: "bottom-left",
      });
    }

    setlLoading(false);
  };

  const handleFormularioSelect = (selectedFormularios, inputSetId) => {
    const newInputSets = inputSets.map((inputSet) => {
      if (inputSet.id === inputSetId) {
        // Limita la selección a un solo formulario
        if (selectedFormularios.length > 1) {
          toast.error("Solo se permite seleccionar un formulario por ahora.", {
            position: "bottom-center",
            duration: 3000,
          });
          // Mantener solo el primer formulario seleccionado
          return { ...inputSet, selectedFormularios: [selectedFormularios[0]] };
        }
        return {
          ...inputSet,
          selectedFormularios,
        };
      }
      return inputSet;
    });

    setInputSets(newInputSets);
  };

  const handleGrupoAccesoSelect = (selectedGrupo, inputSetId) => {
    const newInputSets = inputSets.map((inputSet) => {
      if (inputSet.id === inputSetId) {
        return {
          ...inputSet,
          selectedGrupo,
        };
      }
      return inputSet;
    });

    setInputSets(newInputSets);
  };

  const transformarYGuardarSeleccion = () => {
    let ordenActual = 1;
    const newState = inputSets.flatMap((inputSet) => {
      return inputSet.selectedFormularios.map((formulario) => ({
        pre_formulario_id: formulario,
        pre_grupo_acceso_id: inputSet.selectedGrupo,
        orden: ordenActual++,
        pre_area_id: dataUser.area.pre_area_id,
        orden_obligatorio: esOrdenObligatorio,
        codigo: codigo,
        pre_usuario_id: dataUser.pre_usuario_id,
      }));
    });
    setState(newState);
    return newState;
  };

  return (
    <div>
      <div className="row mt-3 mb-4">
        <div className="col-md-6 col-lg-4  w-100">
          <div
            className=" bg-white  border-0 borde-tarjeta"
            style={{ height: "auto" }}
          >
            <div className="card-body m-0 p-0">
              <div className="w-100 bgfondo2 bordeabajo bordesombreado d-flex flex-column align-items-center d-flex justify-content-center py-3 px-3">
                <div className="w-100  d-flex justify-content-between align-items-center px-3">
                  <h5 className=" negrita card-title">
                    {type === "save" ? "Crear" : "Editar"} Asignacion
                  </h5>
                  <div className="d-flex justify-content-between flex-row align-self-start">
                    <div className="d-flex justify-content-end ">
                      {loading ? (
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <button
                          type=""
                          className="bg-btn-vet bordesombreado"
                          onClick={() => CrearFn()}
                        >
                          <i className="fas fa-save mx-2"></i>
                          {type === "save" ? "Guardar" : "Editar"} Asignacion
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row py-4 ">
                <div className="col-md-12">
                  <button
                    className="bg-btn-vet bordesombreado"
                    onClick={agregarMasInputs}
                  >
                    Agregar más +
                  </button>
                </div>
                {inputSets.map((inputSet, index) => (
                  <React.Fragment key={inputSet.id}>
                    <div className="col-md-6 mt-2">
                      <label className="py-2 negrita">Grupo de acceso</label>
                      <Select
                        options={grupoOptions}
                        placeholder="Seleccione el grupo de acceso"
                        onChange={(selectedOption) =>
                          handleGrupoAccesoSelect(
                            selectedOption.value,
                            inputSet.id
                          )
                        }
                      />
                    </div>
                    <div className="col-md-4 mt-2">
                      <label className="py-2 negrita">Formularios</label>
                      <Select
                        isMulti
                        placeholder="Seleccione formularios"
                        noOptionsMessage={() =>
                          "No existen formularios de sistema que no estén vinculados a asignaciones. Por favor, crea nuevas instancias en la sección de Categorías."
                        }
                        components={animatedComponents}
                        options={formOptions}
                        value={formOptions.filter((option) =>
                          inputSet.selectedFormularios.includes(option.value)
                        )}
                        onChange={(selectedOptions) =>
                          handleFormularioSelect(
                            selectedOptions.map((option) => option.value),
                            inputSet.id
                          )
                        }
                      />
                    </div>
                    <div className="col-md-2 mt-2">
                      <ActionIcon
                        className="mt-5"
                        color="red"
                        onClick={() => eliminarInputSet(inputSet.id)}
                      >
                        <i className="fas fa-trash"></i>
                      </ActionIcon>
                    </div>
                  </React.Fragment>
                ))}
                <div className="col-md-6 mt-2">
                  <label className="py-2 negrita" htmlFor="orden_obligatorio">
                    Codigo de asignacion
                  </label>
                  <input
                    type="text"
                    id="codigo"
                    value={codigo}
                    placeholder="Digite el código de la asignación"
                    onChange={(e) =>
                      setCodigo(e.target.value.replace(/ /g, ""))
                    }
                    className="py-3 w-100 bordecompleto"
                  />
                </div>
                <div className="flex flex-row justify-content-center aling-items-center mt-3">
                  <input
                    type="checkbox"
                    id="orden_obligatorio"
                    checked={esOrdenObligatorio}
                    onChange={(e) => setEsOrdenObligatorio(e.target.checked)}
                    className="mx-2"
                  />
                  <label htmlFor="orden_obligatorio">Orden obligatorio</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrearAsignaciones;
