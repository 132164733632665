import ProyectosPublicos from "../../components/admin/proyectos/ListadoProyectos";
import { Nav } from "./Nav";
import ContadorIncidentes from "../../components/contadorIncidentes";
const InsdexSpa = ({ panelControl, setPanelControl }) => {
  return (
    <>
      <Nav panelControl={panelControl} setPanelControl={setPanelControl} />
      {/**<ContadorIncidentes/> */}
      <div style={{ maxWidth: "768px", margin: "0 auto" }}>
        <ProyectosPublicos isExternal={true} />
      </div>
    </>
  );
};

export default InsdexSpa;
