import { createStyles, rem } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  root: {
    padding: theme.spacing.xl,
    marginTop: theme.spacing.xl,
    backgroundColor: "transparent",
  },
  separatorActive: {
    borderWidth: 0,
    backgroundImage: theme.fn.linearGradient(
      45,
      theme.colors.blue[6],
      theme.colors.cyan[6]
    ),
  },

  separator: {
    height: rem(4),
    borderTop: `${rem(4)} solid ${theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.dark[3]
      }`,
    borderRadius: theme.radius.xl,
    backgroundColor: "transparent",
  },

  stepIcon: {
    borderColor: "transparent",
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.white,
    borderWidth: rem(4),

    "&[data-completed]": {
      borderWidth: 0,
      backgroundColor: "transparent",
      backgroundImage: theme.fn.linearGradient(
        45,
        theme.colors.blue[6],
        theme.colors.cyan[6]
      ),
    },
  },
  step: {
    transition: "transform 150ms ease",

    "&[data-progress]": {
      transform: "scale(1.05)",
    },
  },
}));

export default useStyles