import Axios from "../config/Axios";
export const ApiCrearInstanciaIncedente = async (data) => {
  try {
    const result = await Axios.post(`/POSTcreateInstanciaAccidentes`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetInstanciasAccidentesGrupoUsuario = async (data) => {
  try {
    const result = await Axios.get(
      `/GETInstanciaAccidentesGrupoUsuario/${data.grupo}/${data.usuario}`
    );
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetInstanciasAccidentes = async () => {
  try {
    const result = await Axios.get(`/GETallInstanciaAccidentes`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiToggleEstadoInstanciaAccidente = async (data) => {
  try {
    const result = await Axios.post(
      `/POSTtoggleEstadoInstanciaAccidente`,
      data
    );
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiToggleTipoInstanciaAccidente = async (data) => {
  try {
    const result = await Axios.post(`/POSTtoggleTipoInstanciaAccidente`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiEditarInstanciaAccidente = async (data) => {
  try {
    const result = await Axios.post(
      `/POSTeditInstanciaAccidente/${data.instancia_accidente_id}`,
      data
    );
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiEliminarInstanciaAccidente = async (data) => {
  try {
    const result = await Axios.post(
      `/POSTeliminarInstanciaAccidente/${data.instancia_accidente_id}`
    );
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
}
