import React, { useEffect, useState, useContext } from "react";

import CrearOpciones from "../../../components/admin/tipoOpciones/TipoOpciones";
import ListadoOpciones from "../../../components/admin/tipoOpciones/listarTipoOpciones";

const initialState = {
  tipo: "",
  icono: "",
  detalle:""
};
const Proyectos = () => {
  const [updateList, setUpdateList] = useState(false);
  const [type, setType] = useState("save");
  const [state, setState] = useState(initialState);
  useEffect(() => {
    return () => {};
  }, []);

  const updateListFn = () => {
    setUpdateList(!updateList);
  };

  const resetState = () => {
    setState(initialState);
  };
  return (
    <div className="h-100 px-3 bgfondo2 d-flex justify-content-start flex-column ">
      <CrearOpciones
        updateListFn={updateListFn}
        state={state}
        setState={setState}
        type={type}
        setType={setType}
        resetState={resetState}
      />
      <ListadoOpciones
        updateList={updateList}
        updateListFn={updateListFn}
        setState={setState}
        setType={setType}
      />
    </div>
  );
};

export default Proyectos;
