import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../components/admin/Sidebar/menucontable.css";
import CrearFormularioData from "../../../components/admin/formularios/formulario";
import ListadoPreguntas from "../../../components/admin/formularios/preguntas/ListadoPreguntas";
import CrearPregunta from "../../../components/admin/formularios/preguntas/crearPregunta";
import ModalDefault from "../../../components/modalDefault";
import { ApiOrdenamiento } from "../../../api/preguntas";
import { renderPopover } from "../../../config/tools";
import { ApiGetDataFormulario } from "../../../api/formularios";
const Formularios = () => {
  const { formularioid, isExternal = false } = useParams();
  const [updateList, setUpdateList] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editInfo, setEditInfo] = useState(null);
  const [resetStatePregunta, setResetStatePreguntas] = useState(false);
  const [showModalImportar, setShowModalImportar] = useState(false);
  const dataUser = JSON.parse(localStorage.getItem("datauser"));

  const [formData, setFormData] = useState(null);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [isDenied, setIsDenied] = useState({
    isDenied: false,
    code: "",
    message: "",
  });

  useEffect(() => {
    if (isExternal) {
      localStorage.setItem("panel", false);
    }
    init();
  }, []);

  const init = async () => {
    const { data, sms } = await ApiGetDataFormulario(formularioid);
    if (sms === "ok") {
      setFormData(data[0]);
      setSuccess(true);
    } else {
      setIsLoading(false);
      setSuccess(false);
    }
  };

  useEffect(() => {
    if (isExternal) {
      if (formData?.estado_publicacion === false) {
        setIsDenied({
          isDenied: true,
          code: "503",
          message: "Este formulario esta deshabilitado",
        });
      }

      if (formData?.pre_proyectos?.estado_publicacion === false) {
        setIsDenied({
          isDenied: true,
          code: "503",
          message: "El proyecto del formulario esta deshabilitado",
        });
      }

      if (formData?.pre_proyectos?.tipo_proyecto_code === "interno") {
        if (dataUser === null || dataUser === undefined) {
          setIsDenied({
            isDenied: true,
            code: "401",
            message:
              "No tiene permisos para ver este formulario. Intenta iniciar sesión",
          });
        }
      }

      if (formData?.pre_proyectos?.tipo_proyecto_code === "interno") {
        if (
          formData?.pre_proyectos?.areas_asignadas.includes(
            dataUser?.area?.pre_cod_area
          ) === false
        ) {
          setIsDenied({
            isDenied: true,
            code: "401",
            message: "Este formulario no esta disponible para su area",
          });
        }
      }
    }

    if (formData !== null) {
      setIsLoading(false);
    }
  }, [formData]);

  const updateListFn = (update) => {
    setUpdateList(!updateList);
  };

  const [ordenamiento, setOrdenamiento] = useState(false);
  const [preguntasOrdenadas, setPreguntasOrdenadas] = useState([]);

  const onSaveQuestionsOrdered = async () => {
    const { sms, mensaje } = await ApiOrdenamiento({
      questions: preguntasOrdenadas,
    });
    if (sms === "ok") {
      setOrdenamiento(false);
    }
    renderPopover(sms, mensaje);
  };

  const onCloseModal = (data) => {
    setShowModal(data);
    setResetStatePreguntas(!resetStatePregunta);
    setEditInfo(null);
  };

  const onShowModal = (data) => {
    setShowModal(data);
  };
  const editFn = async (data) => {
    onShowModal(true);
    setEditInfo(data);
  };

  const onShowModalImportarFn = (data) => {
    setShowModalImportar(data);
  };

  if (isLoading) {
    return (
      <div className="w-100 h-100 d-flex  justify-content-center align-items-center my-3">
        <div className="spinner-border text-dark" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (!success) {
    return (
      <div
        className="bgfondo2 d-flex justify-content-center aling-items-center flex-column  "
        style={{
          maxWidth: "768px",
          padding: "15px 15px",
          margin: " auto",
        }}
      >
        <h1 className="text-center">404</h1>
        <h1 className="text-center">Ups esta pagina no existe</h1>
      </div>
    );
  }

  if (isDenied.isDenied) {
    return (
      <div
        className="bgfondo2 d-flex justify-content-center aling-items-center flex-column  "
        style={{
          maxWidth: "768px",
          padding: "15px 15px",
          margin: " auto",
        }}
      >
        <h1 className="text-center">{isDenied.code ? isDenied.code : "404"}</h1>
        <h1 className="text-center">
          {isDenied.message ? isDenied.message : "Ups esta pagina no existe"}
        </h1>
      </div>
    );
  }

  return (
    <div
      className="bgfondo2 d-flex justify-content-center aling-items-center flex-column  "
      style={{
        maxWidth: "768px",
        padding: "0px 15px",
        margin: "0 auto",
      }}
    >
      {formularioid !== "" && (
        <>
          {" "}
          <CrearFormularioData
            openModalCrearPregunta={onShowModal}
            formularioid={formularioid}
            ordenamiento={ordenamiento}
            setOrdenamiento={setOrdenamiento}
            isExternal={isExternal}
            setShowModalImportar={onShowModalImportarFn}
            showModalImportar={showModalImportar}
            onSaveQuestionsOrdered={onSaveQuestionsOrdered}
          />
          <ListadoPreguntas
            formularioid={formularioid}
            editFn={editFn}
            updateList={updateList}
            ordenamiento={ordenamiento}
            isExternal={isExternal}
            setShowModalImportar={onShowModalImportarFn}
            showModalImportar={showModalImportar}
            setPreguntasOrdenadas={setPreguntasOrdenadas}
          />
          <ModalDefault
            bandera={"confirmacion"}
            modal={showModal}
            size={"md"}
            icontitulo={<null />}
            titulo={editInfo ? `EDICIÓN DE PREGUNTA` : `CREACIÓN DE PREGUNTA`}
            closeAction={() => onCloseModal(false)}
          >
            <CrearPregunta
              formularioid={formularioid}
              editInfo={editInfo}
              updateListFn={updateListFn}
              closeModalCrearPregunta={onCloseModal}
              resetStatePregunta={resetStatePregunta}
            />
          </ModalDefault>
        </>
      )}
    </div>
  );
};

export default Formularios;
