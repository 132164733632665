import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import dayjs from "dayjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const generateRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const UserStatisticsChart = ({ userData }) => {
  const options = {
    aspectRatio: 1.9,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Número De Fichas Por Usuario",
      },
    },
  };

  const userColors = {};
  userData.forEach((user) => {
    userColors[user.cedula] = generateRandomColor();
  });

  const allDates = userData
    .reduce((acc, user) => {
      return acc.concat(
        user.pre_fichas.map((ficha) => new Date(ficha.created_at))
      );
    }, [])
    .sort((a, b) => a - b); // Ordenar las fechas de forma ascendente

  const uniqueDates = allDates.map((date) => dayjs(date).format("DD-MM-YYYY"));

  const labels = Array.from(new Set(uniqueDates));

  const data = {
    labels,
    datasets: userData.map((user) => ({
      label: `${user.nombres} ${user.apellidos}`,
      data: labels.map((date) => {
        const matchingFichas = user.pre_fichas.filter(
          (ficha) => dayjs(ficha.created_at).format("DD-MM-YYYY") === date
        );
        return matchingFichas.length;
      }),
      borderColor: userColors[user.cedula],
      backgroundColor: userColors[user.cedula],
    })),
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default UserStatisticsChart;
