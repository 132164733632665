import Axios from "../config/Axios";
export const ApiCrearInstanciaFicha = async (data) => {
  try {
    const result = await Axios.post(
      `/POSTcreateInstanciaAccidentesFichas`,
      data
    );
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiToggleEstadoFicha = async (data) => {
  try {
    const result = await Axios.post(`/POSTtoggleEstadoFicha`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};
