import React, { useEffect, useState } from "react";
import CrearDocumentos from "../../../components/admin/documentos/documentos";
import ListadoDocumentos from "../../../components/admin/documentos/listadoDocumentos";

const initialState = {
  nombre: "",
  precio: "0",
};
const Proyectos = () => {
  const [updateList, setUpdateList] = useState(false);
  const [type, setType] = useState("save");
  const [state, setState] = useState(initialState);
  useEffect(() => {
    return () => {};
  }, []);

  const updateListFn = () => {
    setUpdateList(!updateList);
  };

  const resetState = () => {
    setState(initialState);
  };
  return (
    <div className="h-100 px-3 bgfondo2 d-flex justify-content-start flex-column ">
      <CrearDocumentos
        updateListFn={updateListFn}
        state={state}
        setState={setState}
        type={type}
        setType={setType}
        resetState={resetState}
      />
      <ListadoDocumentos
        updateList={updateList}
        updateListFn={updateListFn}
        setState={setState}
        setType={setType}
      />
    </div>
  );
};

export default Proyectos;
