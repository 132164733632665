import Axios from "../config/Axios";

export const ApiGetTransmisiones = async () => {
  try {
    const result = await Axios.get(`/GETallTransmisiones`);
    return result.data;
  } catch (e) {
    return {
      sms: "err",
      mensaje: e,
    };
  }
};

export const ApiCreateTransmision = async (transmision) => {
  try {
    const result = await Axios.post(`/POSTcreateTransmision`, transmision);
    return result.data;
  } catch (e) {
    return {
      sms: "err",
      mensaje: e,
    };
  }
};

export const ApiUpdateRecursoByTransmision = async (data) => {
  try {
    const result = await Axios.post(`/POSTeditTransmision`, data);
    return result.data;
  } catch (e) {
    return {
      sms: "err",
      mensaje: e,
    };
  }
};

export const ApiDeleteTransmision = async (transmision_id) => {
  try {
    const result = await Axios.post(`/POSTdeleteTransmision/${transmision_id}`);
    return result.data;
  } catch (e) {
    return {
      sms: "err",
      mensaje: e,
    };
  }
};

export const ApiToggleTransmision = async (transmision_id) => {
  try {
    const result = await Axios.post(`/POSTtoggleEstadoTransmision`, {
      transmision_recurso_id: transmision_id,
    });
    return result.data;
  } catch (e) {
    return {
      sms: "err",
      mensaje: e,
    };
  }
};

export const ApiGetTransmisionById = async (transmision_id) => {
  try {
    const result = await Axios.get(`/GETTransmision/${transmision_id}`);
    return result.data;
  } catch (e) {
    return {
      sms: "err",
      mensaje: e,
    };
  }
};
