import React, { useRef, useEffect, useState } from "react";
import ModalDefault from "../../modalDefault.js";

import { DataTable } from "mantine-datatable";
import { toast } from "react-hot-toast";
import {
  ApiDeleteDocumento,
  ApiGetAllDocumentos,
  ApiActivarDesactivarDocumento,
} from "../../../api/documentos.js";
import { ActionIcon, Group, TextInput, Switch } from "@mantine/core";

const BATCH_SIZE = 12;
const TablaOpciones = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [allDocumentos, setAllDocumentos] = useState([]); // Estado para todas las áreas
  const [filteredDocumentos, setFilteredDocumentos] = useState([]); // Estado para áreas filtradas
  const [fetching, setfetching] = useState(true);
  const [selectedDocumento, setSelectedDocumento] = useState(null);

  // Estados para scroll infinito y filtros
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadedRecords, setLoadedRecords] = useState(BATCH_SIZE);
  const [queryDocumento, setQueryDocumento] = useState("");
  const scrollViewportRef = useRef(null);

  const columns = [
    {
      accessor: "nombre",
      title: "Documento",
      textAlignment: "center",
      filter: (
        <TextInput
          placeholder="Buscar por nombre..."
          value={queryDocumento}
          onChange={(e) => setQueryDocumento(e.currentTarget.value)}
        />
      ),
      filtering: queryDocumento !== "",
    },
    {
      accessor: "precio",
      title: "Precio",
      textAlignment: "center",
    },
    {
      accessor: "activo",
      title: "Estado",
      textAlignment: "center",
      render: (record) => toogle(record),
    },
    {
      accessor: "acciones",
      title: "Acciones",
      textAlignment: "center",
      render: (record) => opciones(record),
    },
  ];

  useEffect(() => {
    init();
  }, [props.updateList]);

  const init = async () => {
    setfetching(true);
    const { data } = await ApiGetAllDocumentos();
    const filterDocumentos = data.filter(
      (documento) => documento.estado !== false
    );
    setAllDocumentos(filterDocumentos);
    setFilteredDocumentos(filterDocumentos);
    setfetching(false);
  };

  // Logica para filtros
  useEffect(() => {
    const filtered = allDocumentos.filter((documento) =>
      documento.nombre.toLowerCase().includes(queryDocumento.toLowerCase())
    );
    setFilteredDocumentos(filtered);
  }, [allDocumentos, queryDocumento]);

  // Carga más datos cuando se llega al final del scroll
  const loadMoreData = () => {
    if (loadedRecords < filteredDocumentos.length) {
      setLoadingMore(true);
      setTimeout(() => {
        setLoadedRecords(loadedRecords + BATCH_SIZE);
        setLoadingMore(false);
      }, 1000);
    }
  };

  // Asegúrate de no cargar más registros de los disponibles
  const visibleDocumentos = filteredDocumentos.slice(
    0,
    Math.min(loadedRecords, filteredDocumentos.length)
  );

  const editDocumento = (row) => {
    const { nombre, precio, documento_id } = row;
    props.setType("editar");
    const precioString = precio.toString();
    props.setState({
      ...props.state,
      nombre,
      precio: precioString,
      documento_id,
    });
  };

  const onShowModal = (e) => {
    setShowModal(true);
    setSelectedDocumento(e);
  };

  const onClosedModal = () => {
    setShowModal(false);
    setSelectedDocumento(null);
  };

  const onDelete = async () => {
    const { documento_id } = selectedDocumento;
    const { sms, mensaje } = await ApiDeleteDocumento({
      documento_id,
    });
    if (sms === "ok") {
      toast.success(mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid green",
          padding: "16px",
          color: "white",
          background: "green",
          borderColor: "white",
        },
        iconTheme: {
          primary: "green",
          secondary: "white",
        },
      });
      setSelectedDocumento(null);
      props.updateListFn();
    } else {
      toast.error(mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    }
    onClosedModal();
  };

  // how to integrate the toogle status in the table
  const toogle = (row) => {
    return (
      // use a switch to change the status of the period and center
      <Group position="center">
        <Switch
          checked={row.activo}
          onChange={() => toogleStatus(row)}
          color="blue"
        />
      </Group>
    );
  };

  // create a toogle to change the status of the period
  const toogleStatus = async (row) => {
    const { documento_id, activo } = row;
    const { sms, mensaje } = await ApiActivarDesactivarDocumento({
      documento_id,
      activo: !activo,
    });
    if (sms === "ok") {
      toast.success(mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid green",
          padding: "16px",
          color: "white",
          background: "green",
          borderColor: "white",
        },
        iconTheme: {
          primary: "green",
          secondary: "white",
        },
      });
      props.updateListFn();
    } else {
      toast.error(mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    }
  };

  const opciones = (row) => {
    return (
      <Group spacing={4} position="center" noWrap>
        <ActionIcon
          color="blue"
          onClick={() => {
            editDocumento(row);
          }}
        >
          <i className="fas fa-file-pen"></i>
        </ActionIcon>
        <ActionIcon
          color="red"
          onClick={() => {
            onShowModal(row);
          }}
        >
          <i className="fas fa-trash"></i>
        </ActionIcon>
        <ModalDefault
          bandera={"confirmacion"}
          modal={showModal}
          size={"sm"}
          icontitulo={<null />}
          titulo={`Confirmación`}
          closeAction={onClosedModal}
        >
          <div className="row py-4 justify-content-center text-align-center ">
            Realmente desea eliminar
          </div>
          <div className="d-flex justify-content-end flex-row align-self-start my-4">
            <button
              type=""
              onClick={onClosedModal}
              className="bg-btn-vet-danger sombra mx-1"
            >
              Cancelar
            </button>
            <button
              type=""
              onClick={onDelete}
              className="bg-btn-vet sombra mx-1"
            >
              <i className="fas fa-save"> Eliminar</i>
            </button>
          </div>
        </ModalDefault>
      </Group>
    );
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <DataTable
          borderRadius="md"
          className="bordecompleto bgfondo "
          highlightOnHover
          noRecordsText="No se encontraron datos"
          columns={columns}
          totalRecords={allDocumentos.length}
          withColumnBorders
          records={visibleDocumentos}
          fetching={fetching || loadingMore}
          onScrollToBottom={loadMoreData}
          scrollViewportRef={scrollViewportRef}
          style={{ maxHeight: "430px", overflowY: "auto" }}
        />
      </div>
    </div>
  );
};

export default TablaOpciones;
