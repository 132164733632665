import React, { useState } from "react";
import toast from "react-hot-toast";
import { ApiCrearArea, ApiEditarAreas } from "../../../api/areas";
import { ApiCrearRol, ApiEditarRol } from "../../../api/roles";
const CrearRoles = ({
  updateListFn,
  state,
  setState,
  type,
  setType,
  resetState,
}) => {
  const [loading, setlLoading] = useState(false);

  const onchange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const CrearFn = async () => {
    setlLoading(true);
    if (state.rol === "") {
      toast.error("El campo Rol no puede estar vacío.", {
        position: "bottom-center",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
      setlLoading(false);
      return;
    }

    if (state.code === "") {
      toast.error("El campo Código no puede estar vacío.", {
        position: "bottom-center",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
      setlLoading(false);
      return;
    }

    const res =
      type === "save" ? await ApiCrearRol(state) : await ApiEditarRol(state);
    const datares = res;
    console.log(datares);
    if (datares.sms === "ok") {
      toast.success(datares.mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid green",
          padding: "16px",
          color: "white",
          background: "green",
          borderColor: "white",
        },
        iconTheme: {
          primary: "green",
          secondary: "white",
        },
      });
      setType("save");
      resetState();
      updateListFn();
    } else {
      toast.error(datares.mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    }
    setlLoading(false);
  };

  return (
    <div>
      <div className="row  mt-3 mb-4">
        <div className="col-md-6 col-lg-4  w-100">
          <div
            className=" bg-white  border-0 borde-tarjeta"
            style={{ height: "auto" }}
          >
            <div className="card-body m-0 p-0">
              <div className="w-100 bgfondo2 bordeabajo bordesombreado d-flex flex-column align-items-center d-flex justify-content-center py-3 px-3">
                <div className="w-100  d-flex justify-content-between align-items-center px-3">
                  <h5 className=" negrita card-title">
                    {type === "save" ? "Crear" : "Editar"} Rol
                  </h5>
                  <div className="d-flex justify-content-between flex-row align-self-start">
                    <div className="d-flex justify-content-end ">
                      {loading ? (
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <button
                          type=""
                          className="bg-btn-vet bordesombreado"
                          onClick={() => CrearFn()}
                        >
                          <i className="fas fa-save mx-2"></i>
                          {type === "save" ? "Guardar" : "Editar"} Rol
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row py-4 ">
                <div className="col-md-6">
                  <label className="py-2 negrita">Codigo</label>
                  <input
                    type="text"
                    id="code"
                    name="code"
                    value={state.code}
                    className="inputtext2 bordecompleto"
                    placeholder="Digite el codigo del Rol"
                    onChange={onchange}
                  />
                </div>

                <div className="col-md-6">
                  <label className="py-2 negrita">Rol</label>
                  <input
                    type="text"
                    id="rol"
                    name="rol"
                    value={state.rol}
                    className="inputtext2 bordecompleto"
                    placeholder="Digite el nombre del Rol"
                    onChange={onchange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrearRoles;
