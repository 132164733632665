/* eslint-disable no-loop-func */
import React, { useEffect, useRef, useState } from "react";
import { ApiCrearFicha, ApiDeleteFile } from "../../../../api/fichas";
import {
  ApiGetAllPreguntas,
  ApitActivarDesactivarPregunta,
  ApiObligatorioObservacion,
  ApiCrearPregunta,
} from "../../../../api/preguntas";
import ModalDefault from "../../../modalDefault";
import { getTiposFormulariosDesign } from "../tiposFormularios";
import { renderPopover } from "../../../../config/tools";
import InputFile from "../../../inputFile";
import SendLogo from "../../../../assets/send.gif";
import CheckLogo from "../../../../assets/check.png";
import ErrorLogo from "../../../../assets/error.png";
import {
  ApiDescargarArchivoEstatico,
  ApiGetDataFormulario,
} from "../../../../api/formularios";
import { useNavigate } from "react-router-dom";

import toast from "react-hot-toast";
import { uploadFile } from "../../../../s3";
import { RingProgress, Text } from "@mantine/core";

export const d = ({ formularioData }) => {
  const {
    estado_preguntas,
    estado,
    pregunta,
    pre_formularios,
    pre_tipos_options,
  } = formularioData;

  return (
    <div className="bordeabajo p-2">
      <div className="d-flex justify-content-between align-items-center">
        <label>Pregunta : {pregunta}</label>
        <div>
          <button type="" className="bg-btn-vet-danger sombra mx-1">
            <i className="fas fa-close"></i>
          </button>
          {/* <button
            type=""
            className="bg-btn-vet sombra mx-1"
            onClick={() => goTo(`/proyectos/formulario/${pre_formulario_id}`)}
          >
            <i className="fas fa-list"></i>
          </button> */}
        </div>
      </div>
    </div>
  );
};

const ListadoPreguntas = ({
  updateList,
  formularioid,
  editFn,
  ordenamiento,
  setPreguntasOrdenadas,
  isExternal,
  showModalImportar,
  setShowModalImportar,
}) => {
  const navigate = useNavigate();
  const dataUser = JSON.parse(localStorage.getItem("datauser"));
  const req = JSON.parse(localStorage.getItem("req"));
  const instancia = JSON.parse(localStorage.getItem("instancia"));
  const ref = useRef([]);

  const userId = dataUser !== null ? dataUser.pre_usuario_id : null;
  const [showModal, setShowModal] = useState(false);
  const [showModalSend, setShowModalSend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [check, setcheck] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  const [statesubmit, setStatesubmit] = useState("");

  const [dataPreguntas, setDataPreguntas] = useState([]);
  const [state, setState] = useState([]);
  const [dataEliminar, setDataEliminar] = useState(null);
  const [idFormulario, setIdFormulario] = useState(null);
  const [dataFiles, setDataFiles] = useState([]);
  const [impFiles, setFilesImport] = useState([]);

  const [modulos, setModulos] = useState([]);

  const [nivelAnonimato, setNivelAnonimato] = useState(0);
  const [anonimatoChecked, setAnonimatoChecked] = useState(false);

  const [isMovil, setIsMovil] = useState(false);
  const [inerwidth, setInerwidth] = useState(window.innerWidth);

  const [progress, setProgress] = useState({
    status: false,
    progress: 0,
    file: "",
  });

  const handleResize = () => {
    setInerwidth(window.innerWidth);
    if (window.innerWidth <= 667) {
      setIsMovil(true);
    } else {
      setIsMovil(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [user, setUser] = useState({
    cedula: "",
    nombres: "",
    correo: "",
    telefono: "",
  });

  useEffect(() => {
    if (dataUser) {
      setUser({
        ...user,
        cedula: dataUser.usuario,
        nombres: `${dataUser.nombres} ${dataUser.apellidos}`,
      });
    }
  }, []);

  const onChangeUser = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const validateObligatorio = async (arradata) => {
    if (user.cedula === "") {
      renderPopover("err", "La cédula es obigatoria");
      return false;
    }

    if (user.nombres === "") {
      renderPopover("err", "los nombres son obligatorios ");
      return false;
    }

    //filtrar preguntas obligatorias
    const resoblifilter = await dataPreguntas.filter((x) => x.obligatorio);

    let one = 0;
    for (let index = 0; index < dataPreguntas.length; index++) {
      if (dataPreguntas[index].obligatorio) {
        const resfilter = await arradata.filter(
          (x) =>
            x.pre_pregunta_id === dataPreguntas[index].pre_pregunta_id &&
            x.respuesta !== ""
        );

        console.log(resfilter.length);
        if (resfilter.length === 0) {
          console.log("no respondida" + dataPreguntas[index].pre_pregunta_id);

          ref.current[dataPreguntas[index].pre_pregunta_id].classList.remove(
            "bordecompleto"
          );

          ref.current[dataPreguntas[index].pre_pregunta_id].classList.add(
            "bordecompletoerror"
          );
        } else {
          ref.current[dataPreguntas[index].pre_pregunta_id].classList.remove(
            "bordecompletoerror"
          );

          ref.current[dataPreguntas[index].pre_pregunta_id].classList.add(
            "bordecompleto"
          );
          one = one + 1;
        }
      }
    }

    const obligatorioFilePreguntas = dataPreguntas.filter(
      (pregunta) =>
        pregunta.obligatorio && pregunta.pre_tipos_opciones.tipo === "file"
    );

    for (let pregunta of obligatorioFilePreguntas) {
      const archivoAdjunto = dataFiles.find(
        (file) => file.pre_pregunta_id === pregunta.pre_pregunta_id
      );
      const preguntaElement = ref.current[pregunta.pre_pregunta_id]; // Obtener el elemento del DOM para esta pregunta

      if (!archivoAdjunto || archivoAdjunto.files.length === 0) {
        if (preguntaElement) {
          preguntaElement.classList.remove("bordecompleto");
          preguntaElement.classList.add("bordecompletoerror");
        }
        renderPopover(
          "err",
          `Archivo obligatorio no proporcionado para la pregunta ${pregunta.pregunta}`
        );
        return false;
      } else {
        if (preguntaElement) {
          preguntaElement.classList.remove("bordecompletoerror");
          preguntaElement.classList.add("bordecompleto");
        }
        one = one + 1;
      }
    }

    //console.log("obligadas:" + resoblifilter.length);
    //console.log("respondidas:" + one);
    if (resoblifilter.length === one) {
      return true;
    } else {
      return false;
    }
  };

  //TODO: tratar de manejar la ejecucion de este useEffect
  //una sola vez despues de que se renderice el componente
  //y hallan datos en el state
  useEffect(() => {
    // setear respuesta si edit está en true
    if (instancia?.isEdit) {
      const updateModulos = async () => {
        const updatedModulos = await Promise.all(
          modulos.map(async (modulo) => {
            return {
              ...modulo,
              pre_fichas_preguntas: {
                ...modulo.pre_fichas_preguntas,
                respuesta: await buscarRespuestaEnPregunta(
                  modulo.pre_pregunta_id,
                  modulo.pre_tipos_opciones.tipo
                ),
              },
            };
          })
        );

        setModulos(updatedModulos);
      };

      updateModulos();
    }
  }, [state]);

  async function buscarRespuestaEnPregunta(preguntaId, tipo) {
    const respuesta = state.find(
      (respuesta) => respuesta.pre_pregunta_id === preguntaId
    );

    if (!respuesta) {
      return null;
    }

    if (tipo === "file") {
      const respuestaJson = JSON.parse(respuesta.respuesta);
      const res = await ApiDescargarArchivoEstatico(respuestaJson[0]);
      console.log(res);
      if (res.sms === "ok") {
        console.log(`${process.env.REACT_APP_URL_API}recursos/${res.url}`);
        //TODO: manejar correctamente archivos
        return null;
      }
      return null;
    }

    if (tipo === "select" || tipo === "radio" || tipo === "checkbox") {
      const respuestaJson = JSON.parse(respuesta.respuesta);
      const respuestaSeleccionada = respuestaJson.find(
        (respuesta) => respuesta.selected
      );

      return respuestaSeleccionada ? respuestaSeleccionada.pre_opcion_id : null;
    }

    return respuesta.respuesta;
  }

  useEffect(() => {
    init();
  }, [dataPreguntas]);

  const init = async () => {
    if (instancia?.isEdit) {
      const res =
        instancia.instancia_accidente_ficha.pre_fichas.pre_fichas_preguntas.map(
          (val) => {
            return {
              pre_pregunta_id: val.pre_pregunta_id,
              respuesta: val.respuesta,
              pre_opcion_id: val.pre_opcion_id,
              estado: true,
              observacion: "",
              created_at: new Date(),
            };
          }
        );
      setState(res);

      return;
    }

    if (dataPreguntas.length > 0) {
      const res = dataPreguntas.map((val) => {
        return {
          pre_pregunta_id: val.pre_pregunta_id,
          respuesta: "",
          pre_opcion_id: null,
          estado: true,
          observacion: "",
          created_at: new Date(),
          tipe_file: val.pre_tipos_opciones.tipo === "file" ? true : false,
        };
      });

      setState(res);
    }

    const resform = await ApiGetDataFormulario(formularioid);
    if (resform.sms === "ok") {
      setNivelAnonimato(parseInt(resform.data[0]?.anonimo));

      if (resform.data[0]?.anonimo === "2") {
        setUser({
          cedula: "0",
          nombres: "Anónimo",
          correo: "",
          telefono: "",
        });
      }
    } else {
      toast.error(resform.mensaje);
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    setStatesubmit(
      "Enviando respuestas, por favor espere dentro de la pagina..."
    );

    const auxdata = {
      pre_usuario_id: userId !== null ? parseInt(userId) : null,
      pre_formulario_id: idFormulario,
      cedula: user.cedula,
      nombres: user.nombres,
      correo: user.correo,
      telefono: user.telefono,
      respuestas: state,
    };

    const resfiles = auxdata.respuestas.filter((d) => d.tipe_file === true);
    const copyKeyFiles = [];

    if (dataFiles.length > 0 && resfiles.length > 0) {
      for (const resfile of resfiles) {
        const xx = dataFiles.find(
          (f) => f.pre_pregunta_id === resfile.pre_pregunta_id
        );
        if (xx) {
          const filesToUpload = xx.files;
          const pregunta = dataPreguntas.find(
            (pregunta) => pregunta.pre_pregunta_id === resfile.pre_pregunta_id
          );

          const uploadedFilesKeys = [];

          for (const file of filesToUpload) {
            setStatesubmit(
              `Subiendo archivo (${file.name}) de la pregunta: ${pregunta.pregunta} - Por favor permanezca en la página...`
            );
            const res = await uploadFile(file, setProgress);
            if (res.success) {
              uploadedFilesKeys.push({
                file: res.key,
                estado: true,
                created_at: new Date(),
              });

              copyKeyFiles.push(res.key);
            } else {
              setStatesubmit(res.message);
              setLoading(false);
              onCloseModalSend();
              setErrorModal(true);

              if (uploadedFilesKeys.length > 0) {
                uploadedFilesKeys.forEach(async (file) => {
                  await ApiDeleteFile(file.file);
                });
              }
              return;
            }
          }
          resfile.respuesta =
            uploadedFilesKeys.length > 0
              ? JSON.stringify(uploadedFilesKeys)
              : "";
        }
      }
    }

    //console.log("🚀 ~ Listado Preguntas ~ onSubmit ~ auxdata", auxdata);

    const res = await ApiCrearFicha(auxdata);

    if (res.sms === "err") {
      setStatesubmit(res.mensaje);
      setLoading(false);
      onCloseModalSend();
      setErrorModal(true);

      if (copyKeyFiles.length > 0) {
        copyKeyFiles.forEach(async (file) => {
          await ApiDeleteFile(file);
        });
      }

      return;
    }

    // Si todo está bien, mostrar mensaje de éxito
    setStatesubmit("Reporte enviado");
    setLoading(false);
    setcheck(true);
    setTimeout(() => navigate(isExternal ? "/" : "/proyectos"), 2000);

    /**
     * if (req !== null) {
      //crear instancia accidentes fichas
      //instancia accidente id, pre ficha id,
      const {
        sms: smsInstanciaFicha,
        mensaje: mensajeInstanciaFicha,
        data: dataInstanciaFicha,
      } = await ApiCrearInstanciaFicha({
        instancia_accidente_id: req.data.instancia_accidente_id,
        pre_ficha_id: data.pre_ficha_id,
      });
    }

    if (instancia !== null) {
      if (instancia.isEdit) {
        //TODO: asociar el id de la ficha con la instancia_ficha
        console.log(instancia.ficha);
      } else {
        const {
          sms: smsInstanciaFicha,
          mensaje: mensajeInstanciaFicha,
          data: dataInstanciaFicha,
        } = await ApiCrearInstanciaFicha({
          instancia_accidente_id: instancia.instancia_accidente_id,
          pre_ficha_id: data.pre_ficha_id,
        });
      }
    }

    let submitMessage =
      req !== null ? "Reporte enviado" : "Gracias por sus respuestas";
    setStatesubmit(submitMessage);
    setLoading(false);
    setcheck(true);
    setTimeout(() => navigate(isExternal ? "/" : "/proyectos"), 2000);
     */
  };

  const onchangeObservacion = async (e, i, info) => {
    const resx = await state.map((data) => {
      if (data.pre_pregunta_id === info.pre_pregunta_id) {
        const aux = data;
        aux.observacion = e.target.value;
        return aux;
      } else {
        return data;
      }
    });
    console.log(resx);
    setState(resx);
  };

  const onchange = async (e, i, info) => {
    const {
      pre_tipos_opciones: { tipo },
    } = info;

    /*     alert(`${e.target.value} ${e.target.name}`)
     */

    if (tipo === "file") {
      const files = info.files;
      const existingIndex = dataFiles.findIndex(
        (item) => item.pre_pregunta_id === info.pre_pregunta_id
      );

      const updatedState = [...dataFiles];
      const aux = {
        pre_pregunta_id: info.pre_pregunta_id,
        files: files,
      };

      if (existingIndex !== -1) {
        updatedState[existingIndex] = aux;
      } else {
        updatedState.push(aux);
      }

      setDataFiles(updatedState);
    } else {
      let auxopciones = "";
      //console.log("======*********======");
      //console.log(info);
      //console.log(e.target.value);
      if (info.pre_opciones.length > 0 && e.target.value !== "") {
        const rexl = await info.pre_opciones.map((ele) => {
          ele.selected = false;
          if (`${e.target.value}` === `${ele.pre_opcion_id}`) {
            ele.selected = true;
          }
          return ele;
        });
        // eslint-disable-next-line no-unused-vars
        auxopciones = JSON.stringify(rexl);
      }

      //console.log(auxopciones);
      const tiposinputs = ["text", "date", "time"];
      const tiposinputsOpcions = ["radio", "select", "checkbox"];

      const aux = {
        pre_pregunta_id: info.pre_pregunta_id,
        respuesta: tiposinputs.includes(info.pre_tipos_opciones.tipo)
          ? e.target.value
          : auxopciones,
        pre_opcion_id: tiposinputsOpcions.includes(info.pre_tipos_opciones.tipo)
          ? parseInt(e.target.value)
          : null,
        estado: true,
        observacion: "",
        created_at: new Date(),
      };
      const existingIndex = state.findIndex(
        (item) => item.pre_pregunta_id === aux.pre_pregunta_id
      );

      const updatedState = [...state];

      if (existingIndex !== -1) {
        updatedState[existingIndex] = aux;
      } else {
        updatedState.push(aux);
      }

      setState(updatedState);
    }

    setIdFormulario(info.pre_formulario_id);
  };

  useEffect(() => {
    getAllPreguntasFormulario();
  }, [updateList]);

  const onCloseModalImport = () => {
    setFilesImport([]);
    setShowModalImportar(false);
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  const onShowModalSend = async () => {
    const data = {
      pre_usuario_id: userId !== null ? parseInt(userId) : null,
      pre_formulario_id: idFormulario,
      cedula: user.cedula,
      nombres: user.nombres,
      correo: user.correo,
      telefono: user.telefono,
      respuestas: state,
    };

    const resvalidation = await validateObligatorio(data.respuestas);
    if (!resvalidation) {
      renderPopover("err", "Las preguntas marcadas con rojo son obligatorios");
      onCloseModalSend();
      return;
    }
    setShowModalSend(true);
  };

  const onCloseModalSend = () => {
    setShowModalSend(false);
  };

  const onShowModal = () => {
    setShowModal(true);
  };

  const setearPreguntas = async (preguntas) => {
    let preguntasOrdenadas = null;

    const tienePreguntasSinOrden = preguntas.some(
      (pregunta) => pregunta.orden === null
    );
    if (tienePreguntasSinOrden) {
      preguntasOrdenadas = await preguntas.map((pregunta, index) => {
        return agregarModule(pregunta, index);
      });
    } else {
      preguntasOrdenadas = preguntas.sort((a, b) => a.orden - b.orden);
    }
    setModulos(preguntasOrdenadas);
  };

  const agregarModule = (pregunta, index) => {
    pregunta.orden = index + 1;
    return pregunta;
  };

  const getAllPreguntasFormulario = async () => {
    const { sms, data } = await ApiGetAllPreguntas(formularioid);
    if (sms === "ok") {
      setDataPreguntas(data);
      setearPreguntas(data);
    }
    onCloseModal();
  };

  const postShowObservacionObligatorioFn = async (
    pre_pregunta_id,
    showobservacion,
    obligatorio
  ) => {
    const { sms, mensaje, data } = await ApiObligatorioObservacion({
      pre_pregunta_id: pre_pregunta_id,
      showobservacion: showobservacion,
      obligatorio: obligatorio,
    });
    if (sms === "ok") {
      getAllPreguntasFormulario();
    }
    renderPopover(sms, mensaje);
  };

  const eliminarPregunta = async () => {
    if (!dataEliminar) {
      return renderPopover(
        "err",
        "`No se encontró datos para eliminar el registro"
      );
    }
    const { sms, mensaje, data } = await ApitActivarDesactivarPregunta(
      dataEliminar
    );
    if (sms === "ok") {
      getAllPreguntasFormulario();
      //update del updateOrdenamiento(modulos)
    }

    renderPopover(sms, mensaje);
    onCloseModal();
  };
  const editar = (data) => {
    editFn(data);
  };

  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const handleDrag = (e) => {
    e.preventDefault();
    let _modulos = [...dataPreguntas];
    const draggeItemContento = _modulos.splice(dragItem.current, 1)[0];
    _modulos.splice(dragOverItem.current, 0, draggeItemContento);
    dragItem.current = null;
    dragOverItem.current = null;
    _modulos.map((data, index) => {
      data.orden = index + 1;
    });
    setModulos(_modulos);
    setDataPreguntas(_modulos);

    const preguntas = _modulos.map((pregunta) => {
      const { pre_pregunta_id, orden } = pregunta;
      return { pre_pregunta_id, orden };
    });
    setPreguntasOrdenadas(preguntas);
  };

  const esobligatorio = () => {
    return <p style={{ color: "red" }}>Este campo es obligatorio</p>;
  };

  const validate = ["select", "radio", "checkbox"];
  const duplicarPregunta = async (datapreguntas) => {
    var opciones = [];
    const res = await validate.filter(
      (x) => x === datapreguntas.pre_tipos_opciones.tipo
    );
    if (res.length > 0) {
      if (datapreguntas.pre_opciones.length > 0) {
        opciones = await datapreguntas.pre_opciones.map((data) => {
          return {
            opcion: data.opcion,
            value: data.value,
          };
        });
      } else {
        // eslint-disable-next-line no-unused-vars
        opciones = [];
      }
    }
    console.log("==========================");

    const obj = {
      pre_formulario_id: datapreguntas.pre_formulario_id,
      pre_tipo_opcion_id: datapreguntas.pre_tipo_opcion_id,
      pregunta: datapreguntas.pregunta,
      estado_preguntas: true,
      tipo: datapreguntas.pre_tipos_opciones.tipo,
      pre_opciones: opciones,
    };

    const resx = await ApiCrearPregunta(obj);
    if (resx.sms === "ok") {
      getAllPreguntasFormulario();
    } else {
      renderPopover(resx.sms, res.mensaje);
    }
  };

  const ImportarPregunta = async (datapreguntas, index) => {
    const validate2 = ["select", "radio", "checkbox"];

    var opciones = [];
    const res = await validate2.filter(
      (x) => x === datapreguntas.pre_tipos_opciones.tipo
    );
    if (res.length > 0) {
      if (datapreguntas.pre_opciones.length > 0) {
        opciones = await datapreguntas.pre_opciones.map((data) => {
          return {
            opcion: data.opcion,
            value: data.value,
          };
        });
      } else {
        // eslint-disable-next-line no-unused-vars
        opciones = [];
      }
    }
    console.log("==========================");

    const obj = {
      pre_formulario_id: formularioid,
      pre_tipo_opcion_id: datapreguntas.pre_tipo_opcion_id,
      pregunta: datapreguntas.pregunta,
      estado_preguntas: true,
      tipo: datapreguntas.pre_tipos_opciones.tipo,
      pre_opciones: opciones,
    };
    const resx = await ApiCrearPregunta(obj);
    if (resx.sms === "ok") {
      const res = await impFiles.filter((x, i) => i !== index);

      setFilesImport(res);
      return;
    } else {
      renderPopover(resx.sms, res.mensaje);
      return;
    }
  };

  const downloadPregunta = (datapreguntas) => {
    var dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(datapreguntas));
    var downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute(
      "download",
      `Pregunta-${datapreguntas.orden}` + ".json"
    );
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  const getFiles = async (files) => {
    const aux = [...impFiles];
    if (files.length > 0) {
      for (let index = 0; index < files.length; index++) {
        const res = await readAsFileText(files[index]);
        aux.push(JSON.parse(res.target.result));
      }
      console.log(aux);
      setFilesImport(aux);
    }
  };

  const readAsFileText = (file) => {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onerror = reject;
      fr.onloadend = (result) => {
        resolve(result);
      };
      fr.readAsText(file);
    });
  };

  const handleAnonimato = (e) => {
    setAnonimatoChecked(e.target.checked);
    if (e.target.checked) {
      setUser({
        cedula: "0",
        nombres: "Anónimo",
        correo: "",
        telefono: "",
      });
    } else {
      if (dataUser) {
        setUser({
          ...user,
          cedula: dataUser.usuario,
          nombres: `${dataUser.nombres} ${dataUser.apellidos}`,
        });
      } else {
        setUser({
          cedula: "",
          nombres: "",
          correo: "",
          telefono: "",
        });
      }
    }
  };

  return (
    <div className="w-100 pb-4 d-flex flex-column justify-content-start align-items-center">
      {isExternal &&
        (nivelAnonimato !== 2 && anonimatoChecked !== true ? (
          <div className="w-100 py-4 bordecompleto bg-white borde-tarjeta">
            <div className="px-2 py-2">
              <h5>Información de usuario</h5>
            </div>
            <div className="row ">
              <div className="col-md-6">
                <label className="py-2 negrita">Cédula Usuario (*)</label>
                <input
                  ref={(r) => (ref.current["cedula"] = r)}
                  type="text"
                  id="cedula"
                  name="cedula"
                  className="inputtext2 bordecompleto"
                  placeholder="Digite su Cédula"
                  value={user.cedula}
                  onChange={onChangeUser}
                  disabled={userId !== null}
                />
              </div>
              <div className="col-md-6">
                <label className="py-2 negrita">Nombres y Apellidos (*)</label>
                <input
                  type="text"
                  id="nombres"
                  name="nombres"
                  className="inputtext2 bordecompleto"
                  placeholder="Digite su nombre completo"
                  value={user.nombres}
                  onChange={onChangeUser}
                  disabled={userId !== null}
                />
              </div>
              {/*  <div className="col-md-6">
            <label className="py-2 negrita">Correo Usuario</label>
            <input
              type="email"
              id="correo"
              name="correo"
              className="inputtext2 bordecompleto"
              placeholder="Digite su nombre correo"
              value={user.correo}
              onChange={onChangeUser}
            />
          </div>
          <div className="col-md-6">
            <label className="py-2 negrita">Telefono Usuario</label>
            <input
              type="text"
              id="telefono"
              name="telefono"
              className="inputtext2 bordecompleto"
              placeholder="Digite su número telefonico"
              value={user.telefono}
              onChange={onChangeUser}
            />
          </div> */}
            </div>
            {userId === null && (
              <div className="px-3 py-2">
                <p>
                  La información solicitada será procesada por Conservas Isabel
                  Ecuatoriana y no se compartirá con terceros.{" "}
                </p>
                <p style={{ color: "red" }}>
                  Todos los campos terminados en (*) son obligatorios
                </p>
              </div>
            )}

            {req !== null && (
              <>
                <div className="px-2 py-2">
                  <h5>Información del accidentado</h5>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label className="py-2 negrita">
                      Cédula del Accidentado (*)
                    </label>
                    <input
                      ref={(r) => (ref.current["cedula_accidentado"] = r)}
                      type="text"
                      id="cedula_accidentado"
                      name="cedula_accidentado"
                      className="inputtext2 bordecompleto"
                      placeholder="Digite su Cédula"
                      value={req.cedula_accidentado}
                      disabled={true}
                    />
                    {userId === null && esobligatorio()}
                  </div>
                  <div className="col-md-6">
                    <label className="py-2 negrita">
                      Nombre del Accidentado (*)
                    </label>
                    <input
                      type="text"
                      id="nombres_accidentado"
                      name="nombres_accidentado"
                      className="inputtext2 bordecompleto"
                      placeholder="Digite su nombre completo"
                      value={req.nombres_accidentado}
                      disabled={true}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="w-100 py-4 bordecompleto bg-white borde-tarjeta">
            <div className="px-3 py-2">
              <h5>Respuesta Anónima</h5>
            </div>
            <div className="px-3 py-2">
              <p>La información sera guardada de forma anónima.</p>
            </div>
          </div>
        ))}

      {nivelAnonimato === 1 && (
        <div className="px-3 py-2">
          <div className="form-check form-switch py-3 d-flex justify-content-center align-items-center">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexSwitchCheckDefault"
              onChange={handleAnonimato}
              style={{
                width: "40px",
                height: "20px",
              }}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckDefault"
              style={{
                marginLeft: "10px",
                fontSize: "14px",
              }}
            >
              Responder de forma anónima
            </label>
          </div>
        </div>
      )}

      <div className="py-4 w-100 ">
        <h3 className="negrita text-center ">Preguntas</h3>
        <hr />
      </div>
      {modulos.map((data, index) => {
        return (
          <div
            className={`card-body mb-3 bordecompleto bg-white bordesombreado p-0 w-100`}
            ref={(r) => (ref.current[data.pre_pregunta_id] = r)}
            value={data.pre_pregunta_id}
            draggable={ordenamiento}
            key={index}
            onDragStart={(e) => (dragItem.current = index)}
            onDragEnter={(e) => (dragOverItem.current = index)}
            onDragEnd={(e) => {
              handleDrag(e);
            }}
            onDragOver={(e) => e.preventDefault()}
          >
            {!isExternal ? (
              <div className="w-100 bgfondo2   d-flex flex-column align-items-center d-flex justify-content-center py-3 px-3">
                <div className=" w-100 d-flex justify-content-between align-items-center ">
                  <h5 className=" negrita card-title">Opciones </h5>
                  {!isExternal &&
                    (isMovil ? (
                      <div className="d-flex justify-content-center flex-row align-self-end">
                        <div className="d-flex justify-content-end aling-items-center ">
                          <div className="dropdown">
                            <button
                              className="bg-btn-small-circle bordesombreado mx-1"
                              type="button"
                              id="menu-pregunta"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="fas fa-ellipsis-vertical mx-1"></i>
                            </button>
                            <ul
                              className="dropdown-menu p-0 m-0"
                              aria-labelledby="menu-pregunta"
                              style={{ width: "200px" }}
                            >
                              <li>
                                <button
                                  type=""
                                  className="w-100 d-flex justify-content-start p-2 align-items-center"
                                  onClick={() => editar(data)}
                                >
                                  <i className="fas fa-edit"></i>
                                  Editar
                                </button>
                              </li>
                              <li>
                                <button
                                  type=""
                                  className="w-100 d-flex justify-content-start p-2 align-items-center"
                                  onClick={() => duplicarPregunta(data)}
                                >
                                  <i className="fa fa-clone"></i>
                                  Duplicar
                                </button>
                              </li>
                              <li>
                                <button
                                  type=""
                                  className="w-100 d-flex justify-content-start p-2 align-items-center"
                                  onClick={() => downloadPregunta(data)}
                                >
                                  <i className="fa fa-arrow-circle-o-down"></i>
                                  Exportar
                                </button>
                              </li>
                              <li>
                                <button
                                  type=""
                                  className="w-100 d-flex justify-content-start p-2 align-items-center"
                                  onClick={() => {
                                    setDataEliminar({
                                      pre_pregunta_id: data.pre_pregunta_id,
                                      estado: !data.estado,
                                      //agregar la actualizacion ordenmienti y mandar a base de datos
                                    });
                                    onShowModal();
                                  }}
                                >
                                  <i className="fas fa-trash"></i> Eliminar
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-between flex-row align-self-start">
                        <div className="d-flex justify-content-end ">
                          <button
                            type=""
                            className="bg-btn-vet-danger bordecompleto mx-1"
                            onClick={() => {
                              setDataEliminar({
                                pre_pregunta_id: data.pre_pregunta_id,
                                estado: !data.estado,
                                //agregar la actualizacion ordenmienti y mandar a base de datos
                              });
                              onShowModal();
                            }}
                          >
                            <i className="fas fa-trash"></i>
                          </button>
                          <button
                            type=""
                            className="bg-btn-vet bordecompleto bordecompleto mx-1"
                            onClick={() => editar(data)}
                          >
                            <i className="fas fa-edit"></i>
                          </button>
                          <button
                            type=""
                            className="bg-btn-vet bordecompleto bordecompleto mx-1"
                            onClick={() => duplicarPregunta(data)}
                          >
                            <i className="fa fa-clone"></i> Duplicar
                          </button>
                          <button
                            type=""
                            className="bg-btn-vet bordecompleto bordecompleto mx-1"
                            onClick={() => downloadPregunta(data)}
                          >
                            <i className="fa fa-arrow-circle-o-down"></i>{" "}
                            Exportar
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ) : null}
            <div className="p-3">
              {getTiposFormulariosDesign(
                data,
                onchange,
                index,
                onchangeObservacion
              )}
              {!isExternal ? (
                data.pre_tipos_opciones.tipo === "text" ||
                data.pre_tipos_opciones.tipo === "time" ||
                data.pre_tipos_opciones.tipo === "date" ? (
                  <>
                    <hr />
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        defaultChecked={data.obligatorio}
                        onChange={(e) =>
                          postShowObservacionObligatorioFn(
                            data.pre_pregunta_id,
                            data.showobservacion,
                            e.target.checked
                          )
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexSwitchCheckDefault"
                      >
                        Respuesta obligatoria
                      </label>
                    </div>
                  </>
                ) : (
                  <>
                    <hr />
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        defaultChecked={data.showobservacion}
                        onChange={(e) =>
                          postShowObservacionObligatorioFn(
                            data.pre_pregunta_id,
                            e.target.checked,
                            data.obligatorio
                          )
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexSwitchCheckDefault"
                      >
                        Permitir una observación
                      </label>
                    </div>

                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        defaultChecked={data.obligatorio}
                        onChange={(e) =>
                          postShowObservacionObligatorioFn(
                            data.pre_pregunta_id,
                            data.showobservacion,
                            e.target.checked
                          )
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexSwitchCheckDefault"
                      >
                        Respuesta obligatoria
                      </label>
                    </div>
                  </>
                )
              ) : null}
            </div>
          </div>
        );
      })}

      {isExternal ? (
        <div className="py-3">
          <button
            style={{ width: "250px" }}
            className="bg-btn-vet bordesombreado "
            onClick={onShowModalSend}
          >
            <i className="fa-solid fa-share mx-2"></i>
            Enviar
          </button>
        </div>
      ) : null}

      <ModalDefault
        bandera={"confirmacion"}
        modal={showModal}
        size={"md"}
        classnamebody={`p-0 m-0`}
        titulo={`CONFIRMACIÓN`}
        closeAction={onCloseModal}
      >
        <div className="row p-0 m-0">
          <div className="w-100 my-3  d-flex justify-content-center align-items-center">
            <h3>Está de acuerdo en eliminar el registro</h3>
          </div>
          <hr className="" />
          <div className="d-flex  my-3 justify-content-end flex-row align-self-start ">
            <button
              type=""
              onClick={onCloseModal}
              className="bg-btn-vet-danger  mx-1"
            >
              <i className="fas fa-close mx-2"> </i>Cancelar
            </button>
            <button
              type=""
              onClick={() => {
                eliminarPregunta();
              }}
              className="bg-btn-vet  mx-1"
            >
              <i className="fas fa-save mx-2"> </i>Aceptar
            </button>
          </div>
        </div>
      </ModalDefault>
      <ModalDefault
        bandera={"confirmacion"}
        modal={showModalSend}
        size={"md"}
        classnamebody={`p-0 m-0`}
        titulo={`Enviando...`}
        closeAction={onCloseModalSend}
      >
        <div className="row p-0 m-0">
          <div className="w-100 my-3 p-2s  d-flex justify-content-center align-items-center">
            {loading && !check ? (
              <div className="d-flex justify-content-start flex-column align-items-center">
                {progress.status === true ? (
                  <RingProgress
                    sections={[
                      {
                        value: progress.progress,
                        color: "blue",
                      },
                    ]}
                    label={
                      <Text color="blue" weight={700} align="center" size="sm">
                        {progress.progress}%
                      </Text>
                    }
                  />
                ) : (
                  <img
                    src={SendLogo}
                    alt=""
                    style={{ width: "200px", height: "200px" }}
                  />
                )}
                <label htmlFor="">{statesubmit}</label>
              </div>
            ) : !loading && check ? (
              <div className="d-flex justify-content-start flex-column align-items-center">
                <img
                  src={CheckLogo}
                  alt=""
                  style={{ width: "200px", height: "200px" }}
                />
                <label htmlFor="">{statesubmit}</label>
              </div>
            ) : (
              <h3>
                <strong>
                  Requerimos confirmación para enviar las respuestas
                </strong>
              </h3>
            )}
          </div>
          <hr className="" />
          <div className="d-flex  my-3 justify-content-end flex-row align-self-start ">
            {loading || check ? null : (
              <>
                <button
                  type=""
                  onClick={onCloseModalSend}
                  className="bg-btn-vet-danger  mx-1"
                >
                  <i className="fas fa-close mx-2"> </i>Cancelar
                </button>
                <button type="" onClick={onSubmit} className="bg-btn-vet  mx-1">
                  <i className="fas fa-save mx-2"> </i>Confirmar
                </button>
              </>
            )}
          </div>
        </div>
      </ModalDefault>
      <ModalDefault
        bandera={"confirmacion"}
        modal={showModalImportar}
        size={"md"}
        classnamebody={`p-0 m-0 w-100`}
        titulo={`IMPORTAR PREGUNTAS`}
        closeAction={onCloseModalImport}
      >
        <InputFile
          getFiles={getFiles}
          absolute="absolute"
          opacidad="opacoUpload"
          multiple
          accept={"/*"}
        />
        <div className="w-100 p-3">
          <table className=" w-100">
            <thead>
              <tr>
                <th>Pregunta</th>
                <th>opcion</th>
              </tr>
            </thead>
            <tbody>
              {impFiles.length > 0
                ? impFiles.map((data, index2) => {
                    return (
                      <tr className="w-100 bordecompleto" key={index2}>
                        <td style={{ width: "70%" }} className="">
                          <strong className="mx-2 w-100">
                            {data.pregunta}
                          </strong>
                        </td>
                        <td
                          onClick={async () => {
                            const dx = await impFiles.filter(
                              (x, index) => index !== index2
                            );
                            setFilesImport(dx);
                          }}
                        >
                          <label htmlFor="" className="bg-btn-vet-danger m-2">
                            Eliminar
                          </label>
                        </td>
                      </tr>
                    );
                  })
                : "No se detectaron archivos"}
            </tbody>
          </table>
          <hr />
          <div>
            {impFiles.length > 0 ? (
              <button
                type=""
                className="bg-btn-vet bordecompleto bordesombreado w-100"
                onClick={async () => {
                  for (let index = 0; index < impFiles.length; index++) {
                    await ImportarPregunta(impFiles[index], index);
                  }
                  getAllPreguntasFormulario();
                }}
              >
                <i className="fas fa-edit"></i>
                Guardar
              </button>
            ) : null}
          </div>
        </div>
      </ModalDefault>
      <ModalDefault
        bandera={"confirmacion"}
        modal={errorModal}
        size={"md"}
        classnamebody={`p-0 m-0 w-100`}
        titulo={`ERROR AL SUBIR FICHA`}
        closeAction={() => setErrorModal(false)}
      >
        <div className="d-flex justify-content-start flex-column align-items-center">
          <img
            src={ErrorLogo}
            alt=""
            style={{ width: "200px", height: "200px" }}
          />
          <label htmlFor="">{statesubmit}</label>
        </div>
      </ModalDefault>
    </div>
  );
};

export default ListadoPreguntas;
