import Axios from "../config/Axios";

export const ApiCrearDepartamento = async (data) => {
  try {
    const result = await Axios.post(`/POSTcreateDepartamento`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiEditarDepartamento = async (data) => {
  try {
    const result = await Axios.post(`/POSTeditDepartamento`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetAllDepartamentos = async () => {
  try {
    const result = await Axios.get(`/GETallDepartamentos`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiActivarDesactivarDepartamento = async (data) => {
  try {
    const result = await Axios.post(`/POSTtoggleEstadoDepartamento`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};
