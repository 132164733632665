import { useEffect, useState } from "react";
import { ItemAsignacion } from "./ItemAsignacion";

export const CardAsignacion = ({ grupo, isExternal }) => {
  return (
    <div className="d-flex justify-content-center">
      <div
        className={`d-flex mb-3 flex-column w-100`}
        style={{ height: "auto" }}
      >
        <div className=" d-flex  align-items-start justify-content-between">
          <div className="d-flex flex-column w-100 ">
            <div className="d-flex flex-row  mx-1 justify-content-start align-items-center">
              <label
                htmlFor=""
                className="bg-btn-small-circle-white"
                style={{ backgroundColor: "#fff" }}
              >
                <i className="fas fa-users"></i>
              </label>
              <label className=" negrita  card-title m-2">
                Grupo: {grupo.nombre}
              </label>
            </div>
          </div>
        </div>
        {grupo.pre_asignacion_formularios.map((formulario, index) => (
          <ItemAsignacion
            idGrupo={grupo.id}
            formulario={formulario}
            key={index}
            isExternal={isExternal}
          />
        ))}
      </div>
    </div>
  );
};
