import Axios from "../config/Axios";
export const ApiCrearTipoOpciones = async (data) => {
  try {
    const result = await Axios.post(`/POSTcreateTiposOpcion`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiEditarTipoOpcion = async (data) => {
  try {
    const result = await Axios.post(`/POSTeditOpcion`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetAllTipoOpciones = async (data) => {
  try {
    const result = await Axios.get(`/GETallTipos`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetAllTipoOpcionesPregunta = async (pre_pregunta_id) => {
  try {
    const result = await Axios.get(`/GETallTipos/${pre_pregunta_id}`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiActivarDesactivarTipos = async (data) => {
  try {
    const result = await Axios.post(`/GETActivarDesactivarTipos`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};