import "./style.css";
const MyCustomMP4Renderer = ({ mainState: { currentDocument } }) => {
  if (!currentDocument) return null;

  return (
    <div id="mp4-renderer" className="renderer-container">
      <video id="mp4-video" className="video-element" controls src={currentDocument.uri} />
    </div>
  );
};

MyCustomMP4Renderer.fileTypes = ["mp4", "video/mp4"];
//MyCustomMP4Renderer.weight = 1;
MyCustomMP4Renderer.fileLoader = ({ fileLoaderComplete }) => {
  fileLoaderComplete();
};

export default MyCustomMP4Renderer;
