import { useEffect, useState } from "react";
import { Stepper, TextInput, Group, Button, Title, Card, Badge, Image, Text } from "@mantine/core";
import NumericKeyboard from "../../components/NumericKeyboard";
import { ApiGetUserByCode } from "../../api/usuarios";
import CheckLogo from "../../assets/check.png";
import { Dots } from "./Dots";
import { ApiGetAllDocumentos } from "../../api/documentos";
import { toast } from "react-hot-toast";
import { ApiCrearSolicitud, ApiGetSolcitudesUsuario } from '../../api/solicitudes';

const SteperDocumentos = ({
  user,
  setUser,
  classes,
  active,
  setActive,
  nextStep,
  prevStep,
  open,
  setErrorMessage,
}) => {
  const [userCode, setUserCode] = useState("");
  const [documento, setDocumento] = useState(null);
  const [loadingStep1, setLoadingStep1] = useState(false);
  const [loading, setLoading] = useState(false);

  const [documentos, setDocumentos] = useState([]);
  const [loadingSolicitud, setLoadingSolicitud] = useState(false);
  const [solicitudes, setSolicitudes] = useState([]);

  useEffect(() => {
    init()
  }, []);

  const init = async () => {
    setLoading(true);
    const res = await ApiGetAllDocumentos();
    if (res === "err") {
      toast.error(res.mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    }
    setDocumentos(res.data);

    const res2 = await ApiGetSolcitudesUsuario(user?.pre_usuario_id);
    if (res2 === "err") {
      toast.error(res2.mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    }

    setSolicitudes(res2.data);
    setLoading(false);
  }

  const solicitarDocumento = async () => {
    if (!documento) {
      toast.error("Seleccione un documento", {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
      return;
    }
    setLoadingSolicitud(true);
    const data = {
      documento_id: documento.documento_id,
      usuario_id: user.pre_usuario_id,
    }
    const res = await ApiCrearSolicitud(data);
    if (res.sms === "err") {
      toast.error(res.mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    } else {
      toast.success(res.mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid green",
          padding: "16px",
          color: "white",
          background: "green",
          borderColor: "white",
        },
        iconTheme: {
          primary: "green",
          secondary: "white",
        },
      });
      setActive(2);
    }
    setLoadingSolicitud(false);
    setDocumento(null);
    init();
  }


  const handleKeyCode = (key) => {
    if (key === "enter") {
      handleGetUser(userCode);
    } else if (key === "borrar") {
      setUserCode(userCode.slice(0, -1));
    } else {
      setUserCode(userCode + key);
    }
  };

  const handleGetUser = async (userCode) => {
    setLoadingStep1(true);
    if (userCode === "") {
      setErrorMessage(["Ingresa un codigo de usuario"]);
      open();
      setLoadingStep1(false);
      return;
    }

    const result = await ApiGetUserByCode(userCode);

    if (result.sms === "err") {
      setErrorMessage([result.mensaje || "Ingresa un codigo de usuario"]);
      open();
      setUserCode("");
      setLoadingStep1(false);
      return;
    }

    if (result.sms === "ok") {

      setUser(result.data);
      setLoadingStep1(false);
      nextStep();
      setUserCode("");
      return;
    }

    setLoadingStep1(false);
  };

  return (
    <>
      <Dots
        style={{ left: 0, top: 0, position: "absolute", color: "#c6cfdd" }}
      />
      <Dots
        style={{ left: 60, top: 0, position: "absolute", color: "#c6cfdd" }}
      />
      <Dots
        style={{ left: 0, top: 140, position: "absolute", color: "#c6cfdd" }}
      />
      <Dots
        style={{ right: 0, top: 60, position: "absolute", color: "#c6cfdd" }}
      />
      <div className="position-relative z-index-1">
        <Stepper
          active={active}
          onStepClick={setActive}
          size="xl"
          allowNextStepsSelect={false}
          classNames={classes}
          m={0}
          p={0}
        >
          <Stepper.Step label="Digita tu código" loading={loadingStep1} >
            <div className="bg-white shadow px-5 rounded mt-3"
              style={{
                minHeight: "460px",
              }}
            >
              <DateHourSecondCounter />
              <div className="d-flex justify-content-center">
                <TextInput
                  placeholder="Digita tu codigo de usuario"
                  label="Codigo de usuario"
                  size="xl"
                  value={userCode}
                  withAsterisk
                  onChange={() => { }}
                />
                <NumericKeyboard onKeyClick={handleKeyCode} />
              </div>
            </div>
          </Stepper.Step>
          <Stepper.Step label="Elige y solicita" loading={loading} >
            <div className="bg-white shadow px-5 rounded mt-3"
              style={{
                minHeight: "460px",
              }}
            >
              <DateHourSecondCounter />
              <Title order={4} >
                Hola {user?.nombres}, aqui puedes solicitar documentos/certificados.
              </Title>
              <div className="d-flex mt-4">
                {loading ? (
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : documentos.map((doc, index) => {
                  return (
                    <Card shadow="sm" padding="lg" radius="md" withBorder mr={20}
                      style={{
                        cursor: "pointer",
                        border: documento?.documento_id === doc.documento_id ? "3px solid #6991cc" : "3px solid #eee",
                        transition: "all 0.3s ease",
                      }}
                      onClick={() => {
                        setDocumento(doc);
                      }}
                      key={index}
                    >
                      <Card.Section>
                        <Image
                          src="https://img.freepik.com/vector-premium/icono-documento-contrato-estilo-plano-ilustracion-vector-sello-carpeta-informe-sobre-fondo-aislado-concepto-negocio-signo-hoja-papel_157943-703.jpg"
                          height={160}
                          alt="Documento"
                        />
                      </Card.Section>

                      <Group position="apart" mt="md" mb="xs">
                        <Text weight={500}>{doc.nombre}</Text>
                      </Group>
                    </Card>
                  )
                })}
              </div>
              <div className="d-flex justify-content-end mt-5">
                <Button
                  size="xl"
                  variant="default"
                  onClick={() => { prevStep(); setDocumento(null) }}
                  mr={20}
                  style={{
                    borderColor: "#6991cc",

                  }}
                >
                  <i className="fas fa-arrow-left" style={{ fontSize: "1.3rem", marginRight: "5px" }} /><span style={{ fontSize: "1.3rem" }}>Atras</span>
                </Button>
                <Button
                  size="xl"
                  variant="default"
                  leftIcon={<i className="fas fa-paper-plane" style={{ fontSize: "1.3rem" }} />}
                  loading={loadingSolicitud}
                  onClick={() => {
                    solicitarDocumento();
                  }}
                  style={{
                    borderColor: "#6991cc",
                  }}
                >
                  <span style={{ fontSize: "1.3rem" }}>Solicitar</span>
                </Button>
              </div>
            </div>
          </Stepper.Step>
          <Stepper.Completed>
            <div className="bg-white shadow px-5 rounded mt-3"
              style={{
                minHeight: "460px",
              }}
            >
              <DateHourSecondCounter />
              <div className="justify-content-center">
                <Title order={2} align="center" >
                  Documento solicitado correctamente
                </Title>
                <Text align="center" size="lg" >
                  Tu solicitud ha sido registrada correctamente, en breve recibirás un correo con el documento solicitado.
                </Text>
                <Text align="center" size="lg" >
                  Tambien puedes descargar tus documentos o ver su estado, desde el apartado de "Documentos" al iniciar sesión.
                </Text>
                <Image
                  src={CheckLogo}
                  alt="Documento del dia"
                  style={{
                    display: "block",
                    margin: "20px auto",
                    width: "150px",
                    height: "150px",
                  }}
                />
                <Button
                  size="xl"
                  variant="default"
                  onClick={() => {
                    setActive(0);
                    setUserCode("");
                    setUser(null);
                    setDocumento(null);

                  }}
                  h={90}
                  style={{
                    display: "block",
                    borderColor: "#6991cc",
                    margin: "40px auto",
                  }}
                >
                  <i className="fas fa-check" style={{ fontSize: "1.3rem", marginRight: "5px" }} />
                  <span style={{ fontSize: "1.3rem" }}>Aceptar</span>
                </Button>
              </div>
            </div>
          </Stepper.Completed>
        </Stepper>
      </div>
    </>
  )
}

const DateHourSecondCounter = () => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(new Date());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="d-flex pt-4 pb-3">
      <Badge color="teal" variant="filled" style={{ fontSize: "1.2rem" }} mr={5}>
        {date.toLocaleDateString()}
      </Badge>
      <Badge color="teal" variant="filled" style={{ fontSize: "1.2rem" }}>
        {date.toLocaleTimeString()}
      </Badge>
    </div>
  );
};

export default SteperDocumentos