import { useEffect, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import useStyles from "./useStyles";

import HomeDocumentos from "./HomeDocumentos";
import SteperDocumentos from "./SteperDocumentos";
import ModalError from "./ModalError";

const InsdexSpa = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [user, setUser] = useState(null);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const { classes } = useStyles();
  const [active, setActive] = useState(0);
  const nextStep = () =>
    setActive((current) => (current < 2 ? current + 1 : current));
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  useEffect(() => {
    const timer = setInterval(() => {
      if (currentPage !== 1) {
        setCurrentPage(1);
        setUser(null);
        setActive(0);
      }
    }, 20000);
    return () => clearInterval(timer);
  }, [currentPage, active]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div
      style={{
        maxWidth: "768px",
        margin: "0 auto",
        padding: "20px 25px",
        marginTop: "50px",
      }}
    >
      {currentPage === 1 && (
        <HomeDocumentos
          handlePageChange={handlePageChange}
          loading={loading}
        />
      )}
      {currentPage === 2 && (
        <SteperDocumentos
          classes={classes}
          active={active}
          setActive={setActive}
          nextStep={nextStep}
          prevStep={prevStep}
          user={user}
          setUser={setUser}

          open={open}
          close={close}
          setErrorMessage={setErrorMessage}

        />
      )}
      <ModalError opened={opened} close={close} errorMessage={errorMessage} />
    </div>
  );
};

export default InsdexSpa;
