import React, { useEffect, useState } from "react";
import { ApiGetAllTipoOpciones } from "../../../../api/tipo_opciones";
import { ApiGetAllOpcionesPregunta } from "../../../../api/opciones";

import { ApiCrearPregunta, ApiEditarPregunta } from "../../../../api/preguntas";
import TextareaAutosize from "react-textarea-autosize";

import { toast } from "react-hot-toast";
import ListadoOpciones from "../opcionesPreguntas/listadoOpciones";

const CrearPregunta = ({
  closeModalCrearPregunta,
  formularioid,
  editInfo,
  updateListFn,
  resetStatePregunta,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [updateListOpciones, setUpdateListOpciones] = useState(false);
  const [tipo, setTipo] = useState("");

  const objOpcion = {
    opcion: "",
    value: "",
    opcion_pregunta: "",
  };

  const [state, setState] = useState({
    pre_tipo_opcion_id: "",
    pre_formulario_id: formularioid,
    pre_pregunta_id: "",
    pregunta: "",
    tipo: "",
    estado_preguntas: true,
    pre_opciones: [],
  });

  const [stateDefault, setStateDefault] = useState({
    pre_tipo_opcion_id: "",
    pre_formulario_id: formularioid,
    pre_pregunta_id: "",
    pregunta: "",
    tipo: "",
    estado_preguntas: true,
    pre_opciones: [],
  });

  const onchange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setState(stateDefault);
  }, [resetStatePregunta]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const res = await ApiGetAllTipoOpciones();
    if (res.sms === "ok") {
      setData(res.data);
    } else {
      toast.error(res.mensaje);
    }
    return;
  };

  useEffect(() => {
    if (tipo === "select" || tipo === "radio" || tipo === "checkbox")
      loadOpcionesPreguntaFn();
  }, []);

  useEffect(() => {
    editActionFn();
  }, []);

  const loadOpcionesPreguntaFn = async () => {
    const res = await ApiGetAllOpcionesPregunta(editInfo.pre_pregunta_id);
    console.log(res);
    if (res.sms === "ok") {
      setState({
        ...state,
        pre_opciones: res.data,
      });
    } else {
      toast.error(res.mensaje);
    }
    return;
  };

  const editActionFn = async () => {
    if (editInfo) {
      setTipo(editInfo.pre_tipos_opciones.tipo);

      setLoadingEdit(false);
      setState({
        ...state,
        pre_formulario_id: editInfo.pre_formulario_id,
        pre_tipo_opcion_id: editInfo.pre_tipo_opcion_id,
        pre_pregunta_id: editInfo.pre_pregunta_id,
        pregunta: editInfo.pregunta,
        pre_opciones: editInfo.pre_opciones,
      });
      setLoadingEdit(false);
    } else {
      setState(stateDefault);
    }

    return;
  };

  const EditarFn = async () => {
    setLoading(true);
    if (state.pre_tipo_opcion_id === "") {
      toast.error("No se seleccionó el tipo de pregunta", {
        position: "bottom-left",
        style: {
          background: "red",
          color: "white",
        },
      });
      return;
    }
    if (state.pregunta === "") {
      toast.error("No ingresó la pregunta", {
        position: "bottom-left",
        style: {
          background: "red",
          color: "white",
        },
      });
      return;
    }
    setState({
      ...state,
      pre_pregunta_id: editInfo.pre_pregunta_id,
    });
    const res = await ApiEditarPregunta(state);
    console.log(res);
    if (res.sms === "ok") {
      toast.success(res.mensaje);
      setLoading(false);
      updateListFn();
    } else {
      toast.error(res.mensaje);
      setLoading(false);
    }
  };
  const CrearFn = async () => {
    setLoading(true);
    if (state.pre_tipo_opcion_id === "") {
      toast.error("No se seleccionó el tipo de pregunta", {
        position: "bottom-left",
        style: {
          background: "red",
          color: "white",
        },
      });
      setLoading(false);
      return;
    }

    if (
      (tipo === "select" || tipo === "radio" || tipo === "checkbox") &&
      state.pre_opciones.length === 0
    ) {
      toast.error("Debe ingresar mínimo una opción", {
        position: "bottom-left",
        style: {
          background: "red",
          color: "white",
        },
      });
      setLoading(false);

      return;
    }

    const res = await ApiCrearPregunta(state);
    console.log(res);
    if (res.sms === "ok") {
      toast.success(res.mensaje);
      setState(stateDefault);
      setLoading(false);
      closeModalCrearPregunta(false);
      updateListFn();
    } else {
      toast.error(res.mensaje);
      setLoading(false);
    }
  };

  const setTipoOpction = (data) => {
    setState({
      ...state,
      pre_tipo_opcion_id: data.pre_tipo_opcion_id,
    });

    GetTipoPreguntaDefinicion(data);
  };

  const GetTipoPreguntaDefinicion = async (data2) => {
    console.log("=========");
    console.log(data2);
    const res = await data.filter(
      (x) => `${x.pre_tipo_opcion_id}` === `${data2.pre_tipo_opcion_id}`
    );
    let ab = res.length > 0 ? `${res[0].tipo}` : "...";
    console.log(res);
    setTipo(ab);
  };

  const crearOpcion = () => {
    let opciones = state.pre_opciones;
    opciones.push(objOpcion);
    setState({ ...state, pre_opciones: opciones });
  };

  const crearOpcionesFn = () => {
    return (
      <div className="w-100">
        <hr />
        <div className="d-flex justify-content-between align-items-center">
          <label htmlFor="">Opciones</label>
          <button type="" className="bg-btn-vet" onClick={crearOpcion}>
            <i className="fas fa-add"></i>
          </button>
        </div>
        <hr />
      </div>
    );
  };

  const setOpciones = (dataOpciones) => {
    setState({
      ...state,
      pre_opciones: dataOpciones,
    });
  };

  const updateListOpcionesFn = async () => {
    loadOpcionesPreguntaFn();
    updateListFn();
  };
  return (
    <>
      <div>
        {state.pre_tipo_opcion_id === "" ? (
          <div>
            <div className="w-100 d-flex justify-content-between my-2">
              <label htmlFor="">
                Seleccion el tipo de pregunta que deseas generar{" "}
              </label>
              <button
                type=""
                onClick={() => init()}
                className=" mx-3 mb-3 objetocircular bordecompleto bordesombreado"
              >
                <i className="fas fa-rotate"></i>
              </button>
            </div>
            {data.length > 0 ? (
              data.map((data, index) => {
                return (
                  <div
                    key={index}
                    className=" bordecompleto px-3  d-flex flex-row align-items-center justify-content-between align-self-center py-2"
                  >
                    <div className="d-flex flex-row justify-content-between align-items-center align-self-center">
                      <i className={`${data.icono}`}></i>

                      <label
                        className="mx-2 "
                        style={{ fontSize: "15px!important" }}
                      >
                        {data.detalle}
                      </label>
                    </div>
                    <div>
                      <i className={``}></i>
                      <button
                        className="bg-btn-vet"
                        onClick={() => setTipoOpction(data)}
                      >
                        Seleccionar
                      </button>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>no existen tipos</div>
            )}
          </div>
        ) : (
          <div>
            <div className="w-100 d-flex justify-content-between align-items-center align-self-center mb-3">
              <label htmlFor="">
                Tipo de pregunta({" "}
                <label htmlFor="" className="negrita">
                  {tipo}
                </label>{" "}
                )
              </label>
              {!editInfo ? (
                <button
                  type=""
                  className=" bordesombreado mx-2 bg-btn-vet  "
                  onClick={() => setState({ ...state, pre_tipo_opcion_id: "" })}
                >
                  <i className="fas fa-edit"></i>
                </button>
              ) : null}
            </div>
            <hr />
            <div className="col-md-12 d-flex flex-column justify-content-between align-items-center">
              <div className="w-100">
                <label className="py-2 negrita">Titulo de la pregunta</label>
              </div>
              <div className="w-100 d-flex justify-content-between align-items-center align-self-center">
                <TextareaAutosize
                  rows="2"
                  type="text"
                  id="pregunta"
                  name="pregunta"
                  className="textareapublicar bordecompleto bordesombreado"
                  placeholder="Digite  la pregunta"
                  value={state.pregunta}
                  onChange={onchange}
                  style={{
                    height: "70px!important",
                    width: "100%",
                  }}
                />
                {loadingEdit ? (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : editInfo ? (
                  <div>
                    <button
                      onClick={EditarFn}
                      type=""
                      className="bg-btn-vet bordesombreado mx-2"
                    >
                      <i className="fas fa-save m-2"></i>
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-md-12">
              {tipo === "select" || tipo === "radio" || tipo === "checkbox"
                ? crearOpcionesFn()
                : null}
              {state.pre_opciones.length > 0 &&
              (tipo === "select" || tipo === "radio" || tipo === "checkbox") ? (
                <div>
                  <ListadoOpciones
                    pre_opciones={state.pre_opciones}
                    setOpciones={setOpciones}
                    editInfo={editInfo}
                    updateListOpcionesFn={updateListOpcionesFn}
                    pre_pregunta_id={state.pre_pregunta_id}
                  />
                 
                </div>
              ) : null}
            </div>
            <div className="d-flex flex-row justify-content-end align-items-end w-100 my-3">
              {loading ? (
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : editInfo ? null : (
                <div>
                  <button
                    onClick={CrearFn}
                    type=""
                    className="bg-btn-vet bordesombreado"
                  >
                    {" "}
                    <i className="fas fa-save m-2"></i> Guardar pregunta
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CrearPregunta;
