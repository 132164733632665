import Axios from "../config/Axios";

export const ApiCrearSolicitud = async (data) => {
  try {
    const result = await Axios.post(`/POSTcreateSolicitudes`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetSolcitudesUsuario = async (usuario_id) => {
  try {
    const result = await Axios.get(
      `/GETUsuariosSolicitudesDocumentos/${usuario_id}`
    );
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiCancelarSolicitud = async (data) => {
  try {
    const result = await Axios.post(`/POSTcancelarSolicitud`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetAllSolicitudes = async () => {
  try {
    const result = await Axios.get(`/GETallSolicitudes`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiRechazarSolicitud = async (data) => {
  try {
    const result = await Axios.post(`/POSTrechazarSolicitud`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiAprobarSolicitud = async (data) => {
  try {
    const result = await Axios.post(`/POSTaprobarSolicitud`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};