import React, { useEffect } from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import DashBoard from "../pages/admin/dashboard/index";
import Proyectos from "../pages/admin/proyectos/index";
import Menu from "../components/admin/Sidebar/SideBar";
import Areas from "../pages/admin/areas/index.js";
import Reportes from "../pages/admin/reportes/index";
import RecursosDeSistemas from "../pages/admin/recursos/index.js";
import Documentos from "../pages/admin/documentos/index.js";
import Solicitudes from "../pages/admin/solicitudes/index.js";

import Roles from "../pages/admin/roles/index.js";
import Usuarios from "../pages/admin/usuarios/index.js";
import UpUsuarios from "../components/admin/usuarios/cargamasivaexel/CargaDeDatosExcelSeguro.js";
import ReportesUsuariosPage from "../pages/admin/usuarios/reportes.js";
import TipoOpciones from "../pages/admin/TipoOpciones/index";
import FormularioData from "../pages/admin/formularios/index";
import Departamentos from "../pages/admin/departamentos/index.js";
import CentrosCosto from "../pages/admin/centrosCosto/index.js";
import Cargos from "../pages/admin/cargos/index.js";
import SpaPage from "../pages/spa/index";
import AuthPage from "../pages/spa/login";
import Grupos from "../pages/admin/incidentes/grupos/index.js";
import Asignaciones from "../pages/admin/incidentes/asignaciones/index.js";

const AdminLayout = ({ panelControl, setPanelControl }) => {
  const [rol, setRol] = React.useState(null);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("datauser"));
    setRol(data?.roles.code);
  }, []);

  const routes = [
    /*  {
      path: "/",
      name: "Dashboard",
      component: <DashBoard />,
      icon: "fa fa-solar-panel",
      exact: true,
      activo: true,
      type: "menu",
    }, */
    ...(rol === "1"
      ? [
          {
            path: "/usuarios",
            name: "Usuarios",
            icon: "fas fa-users",
            exact: true,
            component: <Usuarios />,
            type: "menu",
          },
          {
            path: "/upuser",
            name: "Carga de usuarios excel",
            icon: "fas fa-users",
            exact: true,
            component: <UpUsuarios />,
            type: "menu",
          },
        ]
      : []),
    {
      path: "/proyectos",
      name: "Categorias",
      component: <Proyectos />,
      icon: "fa fa-briefcase",
      exact: true,
      activo: true,
      type: "menu",
    },
    {
      path: "/recursos",
      name: "Recursos del sistema",
      component: <RecursosDeSistemas isExternal={false} />,
      icon: "fa fa-solar-panel",
      exact: true,
      activo: true,
      type: "menu",
    },
    {
      path: "/documentos",
      name: "Documentos",
      component: <Documentos />,
      icon: "fa fa-file-text",
      exact: true,
      activo: true,
      type: "menu",
    },
    {
      path: "/solicitudes",
      name: "Solicitudes",
      component: <Solicitudes />,
      icon: "fas fa-paper-plane",
      exact: true,
      activo: true,
      type: "menu",
    },
    /**
     {
      path: "/#",
      name: "Incidentes",
      icon: "fas fa-exclamation-triangle",
      exact: true,
      type: "submenu",
      subRoutes: [
        {
          path: "/incidentes/grupos",
          name: "Grupos de acceso",
          component: <Grupos />,
          icon: "fas fa-user-shield",
          exact: true,
          type: "submenu",
        },
        {
          path: "/incidentes/asignaciones",
          name: "Asignaciones",
          component: <Asignaciones />,
          icon: "fas fa-user-shield",
          exact: true,
          type: "submenu",
        },
      ],
    },
     */
    ...(rol === "1"
      ? [
          {
            path: "/#",
            name: "Configuraciones",
            icon: "fas fa-gear",
            exact: true,
            type: "submenu",
            subRoutes: [
              {
                path: "/configuraciones/opciones",
                name: "Tipo de Opciones",
                component: <TipoOpciones />,
                icon: "fas fa-cog",
                exact: true,
                type: "submenu",
              },
              {
                path: "/configuraciones/areas",
                name: "Areas",
                component: <Areas />,
                icon: "fas fa-cog",
                exact: true,
                type: "submenu",
              },
              {
                path: "/configuraciones/departamentos",
                name: "Departamentos",
                component: <Departamentos />,
                icon: "fas fa-cog",
                exact: true,
                type: "submenu",
              },
              {
                path: "/configuraciones/centrosCosto",
                name: "Centros de Costo",
                component: <CentrosCosto />,
                icon: "fas fa-cog",
                exact: true,
                type: "submenu",
              },
              {
                path: "/configuraciones/cargos",
                name: "Cargos",
                component: <Cargos />,
                icon: "fas fa-cog",
                exact: true,
                type: "submenu",
              },

              {
                path: "/configuraciones/roles",
                name: "Roles",
                component: <Roles />,
                icon: "fas fa-cog",
                exact: true,
                type: "submenu",
              },
            ],
          },
        ]
      : []),
    {
      path: "/reportes",
      name: "Reportes",
      component: <Reportes isAdmin={true} />,
      icon: "fa fa-chart-pie",
      exact: true,
      activo: true,
      type: "menu",
    },
  ];

  return (
    <Router>
      <Menu
        routes={routes}
        titulo="Opciones"
        subpath={"/"}
        panelControl={panelControl}
        setPanelControl={setPanelControl}
      >
        <Routes>
          <Route path="*" element={<> </>} />
          {routes.map((data) => {
            return data.subRoutes ? (
              data.subRoutes.map((subRoute) => (
                <Route
                  key={subRoute.path}
                  path={subRoute.path}
                  element={subRoute.component}
                ></Route>
              ))
            ) : (
              <Route
                key={data.path}
                path={data.path}
                element={data.component}
              ></Route>
            );
          })}
          <Route
            path="/proyectos/formulario/:formularioid"
            element={<FormularioData isExternal={false} />}
          />
          <Route path="*" element={<> </>} />
        </Routes>
      </Menu>
    </Router>
  );
};

export default AdminLayout;
