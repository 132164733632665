import { CardAsignacion } from "./CardAsignacion";

export const Incidencias = ({ isExternal, gruposAcceso }) => {
  return (
    <>
      <div className="w-100 d-flex justify-content-start flex-column py-3">
        <p>
          Reporte de manera eficiente cualquier incidencia, garantizando un
          seguimiento detallado y una resolución oportuna para mantener la
          seguridad y el bienestar en el entorno de trabajo.
        </p>
      </div>
      {gruposAcceso.map((grupo, index) => (
        <CardAsignacion grupo={grupo} isExternal={isExternal} key={index} />
      ))}

      {/**
      <div className="w-100 d-flex justify-content-start flex-column py-3">
        <h4>Mis reportes</h4>
      </div>
       */}
    </>
  );
};
