import React from "react";
import { Button } from "@mantine/core";
import { ApiDescargaFile } from "../../api/formularios";

const parseResponse = (response) => {
  try {
    const respuestaParsed = JSON.parse(response);
    if (Array.isArray(respuestaParsed)) {
      return respuestaParsed
        .filter((respuesta) => respuesta.selected)
        .map((respuesta) => respuesta.opcion)
        .join(", ");
    } else {
      return response;
    }
  } catch (e) {
    return response;
  }
};

const parseFiles = (response) => {
  try {
    return JSON.parse(response);
  } catch (e) {
    return [];
  }
};

const FichaDetalleCard = ({ fichaPregunta }) => {
  const onDowload = async (urlDocument) => {
    const { sms, url } = await ApiDescargaFile({ file: urlDocument });
    if (sms === "ok") {
      window.open(url, "_blank");
    }
  };

  return (
    <div className="mb-4">
      {fichaPregunta.tipe_file ? (
        parseFiles(fichaPregunta.respuesta).length > 0 ? (
          <div
            className="card"
            style={{ boxShadow: "-1px 3px 6px 1px rgb(202 212 228)" }}
          >
            <div className="card-header" style={{ boxShadow: "none" }}>
              <h5 className="fw-bold">
                {fichaPregunta.pre_preguntas.pregunta}
              </h5>
            </div>
            <div className="card-body">
              {parseFiles(fichaPregunta.respuesta).map((archivo, idx) => (
                <Button
                  key={idx}
                  onClick={() => {
                    onDowload(archivo.file);
                  }}
                  color="green"
                  className="text-decoration-none mb-2 d-flex align-items-center"
                >
                  <i className="fas fa-download me-2"></i> Descargar
                </Button>
              ))}
            </div>
          </div>
        ) : (
          <AlertaNoDisponible
            pregunta={fichaPregunta.pre_preguntas.pregunta}
            tipo="archivos"
          />
        )
      ) : fichaPregunta.respuesta.trim() ? (
        <TarjetaRespuesta
          fichaPregunta={fichaPregunta}
          parseResponse={parseResponse}
        />
      ) : (
        <AlertaNoDisponible
          pregunta={fichaPregunta.pre_preguntas.pregunta}
          tipo="respuesta"
        />
      )}
    </div>
  );
};

const AlertaNoDisponible = ({ pregunta, tipo }) => (
  <div
    className="card"
    style={{ boxShadow: "-1px 3px 6px 1px rgb(202 212 228)" }}
  >
    <div className="card-header" style={{ boxShadow: "none" }}>
      <h5 className="fw-bold">{pregunta}</h5>
    </div>
    <div className="card-body">
      <div
        className="alert alert-danger"
        role="alert"
        style={{ marginBottom: "0px" }}
      >
        {tipo === "archivos"
          ? "No hay archivos disponibles en la respuesta"
          : "No hay respuesta disponible para esta pregunta."}
      </div>
    </div>
  </div>
);

const TarjetaRespuesta = ({ fichaPregunta, parseResponse }) => (
  <div
    className="card"
    style={{ boxShadow: "-1px 3px 6px 1px rgb(202 212 228)" }}
  >
    <div className="card-header" style={{ boxShadow: "none" }}>
      <h5 className="fw-bold">{fichaPregunta.pre_preguntas.pregunta}</h5>
    </div>
    <div className="card-body">
      <p className="card-text">{parseResponse(fichaPregunta.respuesta)}</p>
    </div>
  </div>
);

export default FichaDetalleCard;
