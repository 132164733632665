import React, { useEffect, useState } from "react";
import CrearCentrosCosto from "../../../components/admin/centrosCosto/centrosCosto";
import { ApiGetAllDepartamentos } from "../../../api/departamentos";
import TablaCentrosCosto from "../../../components/admin/centrosCosto/listadoCentrosCosto";

const initialState = {
  pre_departamento_id: "",
  pre_cod_centro_costo: "",
  pre_centro_costo: "",
};

const CentrosCosto = () => {
  const [updateList, setUpdateList] = useState(false);
  const [type, setType] = useState("save");
  const [state, setState] = useState(initialState);
  const [listadoDepartamentos, setListadoDepartamentos] = useState([]);

  useEffect(() => {
    const fetchDepartamentos = async () => {
      const response = await ApiGetAllDepartamentos();
      if (response.sms === "ok") {
        const departamentosActivos = response.data.filter(
          (departamento) => departamento.estado === true
        );
        setListadoDepartamentos(departamentosActivos);
      } else {
        console.log(response);
      }
    };

    fetchDepartamentos();
  }, [updateList]);

  const updateListFn = () => {
    setUpdateList(!updateList);
  };

  const resetState = () => {
    setState(initialState);
  };

  return (
    <div className="h-100 px-3 bgfondo2 d-flex justify-content-start flex-column ">
      <CrearCentrosCosto
        updateListFn={updateListFn}
        state={state}
        setState={setState}
        type={type}
        setType={setType}
        resetState={resetState}
        listadoDepartamentos={listadoDepartamentos}
      />
      <TablaCentrosCosto
        updateList={updateList}
        updateListFn={updateListFn}
        setState={setState}
        setType={setType}
      />
    </div>
  );
};

export default CentrosCosto;
