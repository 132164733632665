import logo from "../../assets/images/lg.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Drawer, Menu, UnstyledButton } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

const handleLogin = () => {
  window.location = "/auth";
};
export const Nav = ({ panelControl, setPanelControl }) => {
  const navigate = useNavigate();
  const dataUser = JSON.parse(localStorage.getItem("datauser"));
  const [ventanaIncidentes, setVentanaIncidentes] = useState(false);
  const location = useLocation();

  const [isReadyForInstall, setIsReadyForInstall] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);

  //instalar pwa
  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault();
      window.deferredPrompt = event;
      setIsReadyForInstall(true);
      open();
    });
  }, []);

  async function downloadApp() {
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) {
      return;
    }
    promptEvent.prompt();
    await promptEvent.userChoice;
    window.deferredPrompt = null;
    setIsReadyForInstall(false);
  }

  useEffect(() => {
    if (location.pathname === "/incidentes") {
      setVentanaIncidentes(true);
    } else {
      setVentanaIncidentes(false);
    }
  }, [location]);

  return (
    <div
      className=" w-100 px-3 d-flex justify-content-between align-items-center "
      style={{ background: "white", height: "10vh" }}
    >
      <div
        className="h-100 d-flex justify-content-start align-items-center mx-2 flex-row"
        style={{
          maxWidth: "20%",
          cursor: "pointer",
        }}
        onClick={() => {
          navigate("/");
        }}
      >
        <img src={logo} alt="" className="h-100" />
      </div>
      <div>
        {dataUser ? (
          <Menu shadow="md" width={230}>
            <Menu.Target>
              <UnstyledButton
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
                bg="blue.9"
              >
                <i className="fa fa-user" />
                <label className="mx-3">{dataUser.nombres}</label>
                <i className="fa fa-sort-desc" />
              </UnstyledButton>
            </Menu.Target>

            <Menu.Dropdown>
              {dataUser && dataUser.roles.code !== "3" && (
                <Menu.Item
                  icon={<i className="fa fa-cog" />}
                  onClick={() => {
                    setPanelControl(!panelControl);
                  }}
                >
                  Panel de control
                </Menu.Item>
              )}

              <Menu.Item
                icon={<i className="fas fa-list" />}
                onClick={() => {
                  navigate("/");
                }}
              >
                Formularios
              </Menu.Item>

              {dataUser &&
                dataUser.formulario_compartido &&
                dataUser.formulario_compartido.length > 0 && (
                  <Menu.Item
                    icon={<i className="fa fa-share-alt-square" />}
                    onClick={() => {
                      navigate("/reportes/compartidos");
                    }}
                  >
                    Reportes compartidos
                  </Menu.Item>
                )}

              <Menu.Item
                icon={<i className="fa fa-file-text" />}
                onClick={() => {
                  navigate("/mis-documentos");
                }}
              >
                Documentos
              </Menu.Item>

              <Menu.Item
                icon={<i className="fa fa-folder-o" />}
                onClick={() => {
                  navigate("/recursos");
                }}
              >
                Recursos del sistema
              </Menu.Item>

              <Menu.Item
                onClick={() => {
                  localStorage.removeItem("datauser");
                  localStorage.removeItem("accessTokenAdm");
                  localStorage.setItem("panel", false);
                  setPanelControl(false);
                  window.location = "/";
                }}
                icon={<i className="fa fa-sign-out-alt" />}
                color="red"
              >
                Cerrar sesión
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        ) : (
          <button
            onClick={handleLogin}
            type=""
            className="bg-btn-vet borde-tarjeta"
          >
            Iniciar sesión
          </button>
        )}

        {/**
             * <Link
              to={ventanaIncidentes ? "/" : "/incidentes"}
              className="bg-btn-vet-warning borde-tarjeta mx-3"
            >
              {ventanaIncidentes ? "Formularios" : "Incidentes"}
            </Link>
             */}
      </div>
      <Drawer
        overlayProps={{ opacity: 0.5, blur: 4 }}
        opened={opened}
        onClose={close}
        position="bottom"
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div
            style={{
              maxWidth: "350px",
            }}
          >
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img
                src={logo}
                alt="logo app"
                style={{
                  height: "100px",
                  width: "auto",
                }}
              />
              <hr
                style={{
                  height: "10px",
                  color: "black",
                  backgroundColor: "black",
                  width: "100%",
                }}
              />
              <h5 className="text-center mb-4">
                {isReadyForInstall
                  ? `Instala la aplicación para acceder a todas las funcionalidades
                  en pc y móvil(android e ios).`
                  : `Lamentablemente tu navegador no soporta la instalación de la aplicación.`}
              </h5>
            </div>
            {isReadyForInstall ? (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  close();
                  downloadApp();
                }}
                className="bg-btn-vet borde-tarjeta"
                style={{ width: "100%" }}
              >
                <i className="fas fa-download mr-2" /> Instalar
              </button>
            ) : (
              <button
                onClick={close}
                className="bg-btn-vet borde-tarjeta"
                style={{ width: "100%" }}
              >
                <i className="fas fa-times mr-2" /> Cerrar
              </button>
            )}
          </div>
        </div>
      </Drawer>
    </div>
  );
};
