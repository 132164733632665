import { Button, Modal, Alert } from "@mantine/core";

function ModalError({
  opened,
  close,
  errorMessage
}) {
  return (
    <Modal
      opened={opened}
      onClose={close}
      size="md"
      title="Informacion"
      centered
    >
      <Alert icon={<i className="fas fa-exclamation-triangle"></i>} color="red">
        {errorMessage.map((err, index) => (
          <div key={`${index}_${err}`}>
            <span style={{ fontSize: "1rem" }}>{err}</span> <br />
          </div>
        ))}
      </Alert>
      <div className="d-flex justify-content-end">
        <Button mt={10} color="red" onClick={close}>
          Ok
        </Button>
      </div>
    </Modal>
  )
}

export default ModalError