import Axios from "../config/Axios";

export const ApiCrearCentroCosto = async (data) => {
  try {
    const result = await Axios.post(`/POSTcreateCentroCosto`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiEditarCentroCosto = async (data) => {
  try {
    const result = await Axios.post(`/POSTeditCentroCosto`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetAllCentrosCosto = async () => {
  try {
    const result = await Axios.get(`/GETallCentrosCosto`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiActivarDesactivarCentroCosto = async (data) => {
  try {
    const result = await Axios.post(`/POSTtoggleEstadoCentroCosto`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};
