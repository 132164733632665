import React, { useEffect, useState } from "react";
import CrearCargos from "../../../components/admin/cargos/cargos";
import { ApiGetAllCentrosCosto } from "../../../api/centrosCosto";
import ListadoCargos from "../../../components/admin/cargos/listadoCargos";

const initialState = {
  pre_centro_costo_id: "",
  pre_cod_cargo: "",
  pre_cargo: "",
};

const Cargos = () => {
  const [updateList, setUpdateList] = useState(false);
  const [type, setType] = useState("save");
  const [state, setState] = useState(initialState);
  const [listadoCentrosCosto, setListadoCentrosCosto] = useState([]);

  useEffect(() => {
    const fetchCentrosCosto = async () => {
      const response = await ApiGetAllCentrosCosto();
      if (response.sms === "ok") {
        const centrosCostoActivos = response.data.filter(
          (centro) => centro.estado === true
        );
        setListadoCentrosCosto(centrosCostoActivos);
      } else {
        console.log(response);
      }
    };

    fetchCentrosCosto();
  }, [updateList]);

  const updateListFn = () => {
    setUpdateList(!updateList);
  };

  const resetState = () => {
    setState(initialState);
  };

  return (
    <div className="h-100 px-3 bgfondo2 d-flex justify-content-start flex-column ">
      <CrearCargos
        updateListFn={updateListFn}
        state={state}
        setState={setState}
        type={type}
        setType={setType}
        resetState={resetState}
        listadoCentrosCosto={listadoCentrosCosto}
      />
      <ListadoCargos
        updateList={updateList}
        updateListFn={updateListFn}
        setState={setState}
        setType={setType}
      />
    </div>
  );
};

export default Cargos;
