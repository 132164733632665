import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiGetTransmisionById } from "../../api/transmisiones";
import toast from "react-hot-toast";
import { ApiDescargaFile } from "../../api/formularios";

const Reproductor = () => {
  const { transmision_recurso_id } = useParams();
  const [transmision, setTransmision] = useState(null);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    const init = async () => {
      try {
        const res = await ApiGetTransmisionById(transmision_recurso_id);

        if (res.sms === "err") {
          toast.error("Error al crear el recurso");
          setLoading(false);
          return;
        }

        const filesTemp = await buildFiles(
          res?.data?.recursos_documentos?.recursos_documentos_files
        );
        setFiles(filesTemp);

        setTransmision(res.data);
        setLoading(false);
      } catch (error) {
        console.error("Error al obtener la transmisión:", error);
        toast.error("Error al obtener la transmisión");
        setLoading(false);
      }
    };

    const buildFiles = async (files) => {
      if (!files) return [];
      const filesTemp = [];
      for (const file of files) {
        try {
          const { sms, url } = await ApiDescargaFile({
            file: file.file,
          });
          if (sms === "ok") {
            filesTemp.push({
              uri: url,
              nombre: file.nombre,
              type: file.type,
            });
          }
        } catch (error) {
          console.error("Error al descargar el archivo:", error);
          toast.error("Error al descargar el archivo");
        }
      }
      return filesTemp;
    };

    init();
  }, [transmision_recurso_id]);

  const handleVideoEnd = () => {
    // Cambiar al siguiente video cuando el video actual ha terminado
    setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % files.length);
    setVideoLoaded(false); // Restablecer el estado de carga del video
  };

  const handleMetadata = () => {
    setVideoLoaded(true); // El video se ha cargado correctamente
  };

  useEffect(() => {
    // Actualizar el índice y restablecer el estado de carga del video
    setCurrentVideoIndex(0);
    setVideoLoaded(false);
  }, [transmision_recurso_id]);

  useEffect(() => {
    // Cambiar automáticamente al siguiente video cuando el video actual ha terminado
    if (videoLoaded) {
      setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % files.length);
      setVideoLoaded(false); // Restablecer el estado de carga del video
    }
  }, [videoLoaded, files]);

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center vh-100">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Cargando...</span>
        </div>
      </div>
    );
  }

  if (!transmision || transmision.estado === false) {
    return (
      <div className="alert alert-danger text-center h1 my-auto">
        La transmisión no existe
      </div>
    );
  }

  if (!transmision.transmitiendo) {
    return (
      <div className="alert alert-warning text-center h1 my-auto">
        La transmisión ha finalizado o se ha pausado
      </div>
    );
  }

  if (files.length === 0) {
    return (
      <div className="alert alert-warning text-center h1 my-auto">
        No hay videos disponibles
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center justify-content-center">
      <video
        key={currentVideoIndex}
        autoPlay
        loop={files.length === 1}
        controls
        controlsList="nodownload noremoteplayback nofullscreen"
        className="w-100"
        style={{
          height: "100vh",
          width: "100hw",
          objectFit: "cover",
        }}
        onEnded={handleVideoEnd}
      >
        <source
          key={files[currentVideoIndex]?.nombre}
          src={files[currentVideoIndex]?.uri}
          type={files[currentVideoIndex]?.type}
        />
        Tu navegador no soporta el elemento de video.
      </video>
    </div>
  );
};

export default Reproductor;
