import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import categorias from "./enumCategorias";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import ModalDefault from "../../modalDefault";
import { ApiDescargaFile } from "../../../api/formularios";
import { ApiDeleteRecurso, ApiTogglePublico } from "../../../api/recursos";
import toast from "react-hot-toast";
import bigInt from "big-integer";
import MyCustomMP4Renderer from "../../customVideo";

const ListadoRecursos = ({
  recursos,
  loading,
  isExternal,
  deleteRecursoFn,
  toggleRecursoFn,
}) => {
  const [busqueda, setBusqueda] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [recursoSeleccionado, setRecursoSeleccionado] = useState(null);
  const [files, setFiles] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(9);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteResourceId, setDeleteResourceId] = useState(null);

  const [showVisibilityConfirmation, setShowVisibilityConfirmation] =
    useState(false);
  const [visibilityResourceId, setVisibilityResourceId] = useState(null);

  const recursosFiltrados = recursos.filter((recurso) =>
    recurso.nombre.toLowerCase().includes(busqueda.toLowerCase())
  );

  // Agregamos lógica de paginación
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentRecursos = recursosFiltrados.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = Math.ceil(recursosFiltrados.length / itemsPerPage);

    if (pageNumbers <= 1) {
      return null; // No se muestra la paginación si solo hay una página
    }

    const maxPageToShow = 3; // Puedes ajustar este valor según tus necesidades
    let startPage, endPage;

    if (pageNumbers <= maxPageToShow) {
      // Muestra todas las páginas si hay menos o igual al número máximo permitido
      startPage = 1;
      endPage = pageNumbers;
    } else {
      // Calcula el rango de páginas a mostrar cuando hay más páginas disponibles
      const halfMaxPageToShow = Math.floor(maxPageToShow / 2);
      const isNearStart = currentPage <= halfMaxPageToShow;
      const isNearEnd = currentPage > pageNumbers - halfMaxPageToShow;

      if (isNearStart) {
        startPage = 1;
        endPage = maxPageToShow;
      } else if (isNearEnd) {
        startPage = pageNumbers - maxPageToShow + 1;
        endPage = pageNumbers;
      } else {
        startPage = currentPage - halfMaxPageToShow;
        endPage = currentPage + halfMaxPageToShow;
      }
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    ).map((pageNumber) => (
      <li
        key={pageNumber}
        className={`page-item ${currentPage === pageNumber ? "active" : ""}`}
      >
        <button
          className="page-link"
          onClick={() => handlePageChange(pageNumber)}
        >
          {pageNumber}
        </button>
      </li>
    ));
  };

  const handleBusquedaChange = (event) => {
    setBusqueda(event.target.value);
  };

  const handleVerRecurso = (recurso) => {
    setRecursoSeleccionado(recurso);
    setShowModal(true);
  };

  const handleShowDeleteConfirmation = (recurso_documento_id) => {
    setDeleteResourceId(recurso_documento_id);
    setShowDeleteConfirmation(true);
  };

  const handleEliminarRecurso = (recurso_documento_id) => async () => {
    handleShowDeleteConfirmation(recurso_documento_id);
  };

  const handleDeleteConfirmation = async () => {
    // Lógica para eliminar el recurso usando deleteResourceId
    const res = await ApiDeleteRecurso(deleteResourceId);

    if (res.sms === "ok") {
      toast.success("Recurso eliminado");
      deleteRecursoFn(deleteResourceId);
    } else {
      toast.error("Error al eliminar recurso");
    }

    // Oculta el modal de confirmación de eliminación
    setShowDeleteConfirmation(false);
  };

  const handleShowVisibilityConfirmation = (recurso_documento_id) => {
    setVisibilityResourceId(recurso_documento_id);
    setShowVisibilityConfirmation(true);
  };

  const handleTogglePublico = (recurso_documento_id) => async () => {
    handleShowVisibilityConfirmation(recurso_documento_id);
  };

  const handleVisibilityConfirmation = async () => {
    // Lógica para cambiar la visibilidad usando visibilityResourceId
    const res = await ApiTogglePublico({
      recurso_documento_id: visibilityResourceId,
    });

    if (res.sms === "ok") {
      toast.success("Visibilidad cambiada");
      toggleRecursoFn(visibilityResourceId);
    } else {
      toast.error("Error al cambiar visibilidad");
    }

    // Oculta el modal de confirmación de cambio de visibilidad
    setShowVisibilityConfirmation(false);
  };

  const onDowload = async () => {
    for (const file of files) {
      window.open(file.uri, "_blank");
    }
    setShowModal(false);
    setRecursoSeleccionado(null);
    setLoadingFiles(true);
  };

  const buildFiles = async (files) => {
    const filesTemp = [];
    for (const file of files) {
      const { sms, url, fileType, fileName } = await ApiDescargaFile({
        file: file.file,
      });
      if (sms === "ok") {
        filesTemp.push({
          uri: url,
          fileName,
          fileType,
        });
      }
    }
    setFiles(filesTemp);
    setLoadingFiles(false);
  };

  useEffect(() => {
    if (recursoSeleccionado) {
      buildFiles(recursoSeleccionado.recursos_documentos_files);
    }
  }, [recursoSeleccionado]);

  const sumaBytes = recursos.reduce(
    (total, recurso) =>
      total.add(
        recurso.recursos_documentos_files.reduce(
          (acc, file) => acc.add(bigInt(file.size)),
          bigInt(0)
        )
      ),
    bigInt(0)
  );

  const formatBytes = (bytes) => {
    const kb = 1024;
    const mb = kb * 1024;
    const gb = mb * 1024;
    const tb = gb * 1024;

    const sizeInBytes = parseFloat(bytes.toString());

    if (sizeInBytes < kb) {
      return sizeInBytes.toFixed(2) + " B";
    } else if (sizeInBytes < mb) {
      return (sizeInBytes / kb).toFixed(2) + " KB";
    } else if (sizeInBytes < gb) {
      return (sizeInBytes / mb).toFixed(2) + " MB";
    } else if (sizeInBytes < tb) {
      return (sizeInBytes / gb).toFixed(2) + " GB";
    } else {
      return (sizeInBytes / tb).toFixed(2) + " TB";
    }
  };

  const calculateTotalSize = (files) => {
    return files.reduce(
      (total, file) => total.add(bigInt(file.size)),
      bigInt(0)
    );
  };

  return (
    <div className="row mt-3 mb-4">
      <div className="d-flex justify-content-between align-items-center mt-2 mb-3">
        <div className="w-100">
          <h3>
            🗂️ Todos los recursos{" "}
            {!isExternal && formatBytes(sumaBytes) + " de 50 GB"}{" "}
          </h3>
        </div>
        <div
          className="input-group"
          style={{
            maxWidth: "200px",
          }}
        >
          <input
            type="text"
            className="form-control"
            placeholder="🔎 Buscar por nombre"
            aria-label="Buscar por nombre"
            aria-describedby="button-addon2"
            value={busqueda}
            onChange={handleBusquedaChange}
          />
        </div>
      </div>
      <div className="col-md-12 col-lg-8 w-100">
        <div className="row">
          {loading ? (
            <div className="col-md-12">
              <div className="d-flex justify-content-center align-items-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          ) : currentRecursos.length > 0 ? (
            currentRecursos.map((recurso, index) => (
              <div className="col-md-6 col-lg-4 mb-3" key={index}>
                <div className="card h-100">
                  <div className="card-body d-flex flex-column">
                    <h5 className="card-title">{recurso.nombre}</h5>
                    {!isExternal && (
                      <small className="text-muted">
                        {formatBytes(
                          calculateTotalSize(recurso.recursos_documentos_files)
                        )}
                      </small>
                    )}
                    <p className="card-text flex-grow-1">
                      {recurso.descripcion}
                    </p>
                    <div className="d-flex justify-content-between align-items-center my-2">
                      <div>
                        {!isExternal && (
                          <span
                            className={`mx-2 badge ${
                              recurso.publico ? "badge-success" : "badge-danger"
                            }`}
                          >
                            {recurso.publico ? "Publicado" : "No publicado"}
                          </span>
                        )}
                        <small className="text-muted">
                          {categorias[recurso.categoria]}
                        </small>
                      </div>
                      {!isExternal && (
                        <div>
                          <i
                            className="fa fa-trash mx-2"
                            style={{
                              cursor: "pointer",
                              color: "red",
                            }}
                            onClick={handleEliminarRecurso(
                              recurso.recurso_documento_id
                            )}
                          />

                          <i
                            className={
                              recurso.publico
                                ? "fa fa-eye-slash mx-2"
                                : "fa fa-eye mx-2"
                            }
                            style={{
                              cursor: "pointer",
                              color: recurso.publico ? "orange" : "green",
                            }}
                            onClick={handleTogglePublico(
                              recurso.recurso_documento_id
                            )}
                          />
                        </div>
                      )}
                    </div>
                    <div
                      className="card-footer d-flex justify-content-between align-items-center"
                      style={{
                        backgroundColor: "#fff",
                        cursor: "pointer",
                        padding: "0.5rem 0 0 0",
                      }}
                      onClick={() => handleVerRecurso(recurso)}
                    >
                      <p
                        style={{
                          color: "#243c54",
                          margin: "0",
                          padding: "0",
                          textDecoration: "underline",
                        }}
                      >
                        {" "}
                        Ver recurso
                      </p>
                      <i
                        className="fa fa-chevron-right"
                        style={{
                          color: "#243c54",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="col-md-12">
              <div className="d-flex justify-content-center align-items-center">
                <h5>No se encontraron recursos{isExternal && " Publicos"}. </h5>
              </div>
            </div>
          )}
        </div>
        {currentRecursos.length > 0 && (
          <div className="d-flex justify-content-center align-items-center mt-3">
            <nav>
              <ul className="pagination justify-content-center">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <i className="fas fa-chevron-left"></i>
                  </button>
                </li>
                {renderPageNumbers()}
                <li
                  className={`page-item ${
                    currentPage ===
                    Math.ceil(recursosFiltrados.length / itemsPerPage)
                      ? "disabled"
                      : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <i className="fas fa-chevron-right"></i>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>
      <ModalDefault
        bandera={"recursos"}
        modal={showModal}
        size={"lg"}
        titulo={`${
          recursoSeleccionado && categorias[recursoSeleccionado.categoria]
        } / ${recursoSeleccionado && recursoSeleccionado.nombre} / ${
          recursoSeleccionado &&
          dayjs(recursoSeleccionado.create_at).format("YYYY-MM-DD")
        }`}
        closeAction={() => {
          setShowModal(false);
          setRecursoSeleccionado(null);
          setLoadingFiles(true);
        }}
      >
        <div className="row py-4 justify-content-center text-align-center ">
          {loadingFiles ? (
            <div className="col-md-12">
              <div className="d-flex justify-content-center align-items-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          ) : files && files.length > 0 ? (
            <DocViewer
              pluginRenderers={[...DocViewerRenderers, MyCustomMP4Renderer]}
              documents={files}
              style={{
                height: "400px",
              }}
              language="es"
            />
          ) : (
            <p>No hay archivos</p>
          )}
        </div>
        <div className="d-flex justify-content-end flex-row align-self-start my-4">
          <button
            type=""
            onClick={() => {
              setShowModal(false);
              setRecursoSeleccionado(null);
              setLoadingFiles(true);
            }}
            className="bg-btn-vet-danger sombra mx-1"
          >
            Cerrar
          </button>
          <button
            type=""
            onClick={onDowload}
            className="bg-btn-vet sombra mx-1"
          >
            <i className="fas fa-download mx-2"></i> Descargar todo
          </button>
        </div>
      </ModalDefault>
      <ModalDefault
        bandera={"confirmacionEliminacion"}
        modal={showDeleteConfirmation}
        size={"sm"}
        titulo={"Confirmar Eliminación"}
        closeAction={() => setShowDeleteConfirmation(false)}
      >
        <div className="text-center">
          <p>¿Estás seguro de eliminar este recurso?</p>
          <button
            type="button"
            onClick={handleDeleteConfirmation}
            className="bg-btn-vet sombra mx-1"
          >
            Confirmar
          </button>
          <button
            type="button"
            onClick={() => setShowDeleteConfirmation(false)}
            className="bg-btn-vet-danger sombra mx-1"
          >
            Cancelar
          </button>
        </div>
      </ModalDefault>
      <ModalDefault
        bandera={"confirmacionCambioVisibilidad"}
        modal={showVisibilityConfirmation}
        size={"sm"}
        titulo={"Confirmar Cambio de Visibilidad"}
        closeAction={() => setShowVisibilityConfirmation(false)}
      >
        <div className="text-center">
          <p>¿Estás seguro de cambiar la visibilidad?</p>
          <button
            type="button"
            onClick={handleVisibilityConfirmation}
            className="bg-btn-vet sombra mx-1"
          >
            Confirmar
          </button>
          <button
            type="button"
            onClick={() => setShowVisibilityConfirmation(false)}
            className="bg-btn-vet-danger sombra mx-1"
          >
            Cancelar
          </button>
        </div>
      </ModalDefault>
    </div>
  );
};

export default ListadoRecursos;
