import { useState } from "react";
import toast from "react-hot-toast";
import { QRCodeCanvas } from "qrcode.react";
import {
  ApiActivarDesactivarFormulario,
  ApiGenerateUrlFormulario,
  ApiPublicarDesPublicarFormulario,
} from "../../../api/formularios";
import ModalDefault from "../../modalDefault";
import { useNavigate } from "react-router-dom";
export const ItemFormulario = ({
  formularioData,
  updateListFn,
  isExternal,
  tieneFormSist,
}) => {
  const {
    pre_proyecto_id,
    formulario,
    pre_formulario_id,
    estado_publicacion,
    estado,
  } = formularioData;
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalActivar, setModalActivar] = useState(false);
  const [UrlForm, setUrlForm] = useState("");
  const [modalUrl, setModalUrl] = useState(false);

  const url = process.env.REACT_APP_URL;

  const onShowModal = () => {
    setShowModal(true);
  };

  const onClosedModal = () => {
    setShowModal(false);
  };

  const onCloseModalDesactivacion = () => {
    setModalActivar(false);
  };
  const onShowModalDesactivacion = () => {
    setModalActivar(true);
  };

  const onCloseModalUrl = () => {
    setModalUrl(false);
    setCopied(false);
  };
  const onShowModalUrl = () => {
    setModalUrl(true);
  };
  const goTo = (link) => {
    navigate(link);
  };

  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    const textarea = document.createElement("textarea");
    textarea.value = UrlForm;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopied(true);
  };

  const onActivarDesactivar = async () => {
    const { sms, mensaje } = await ApiPublicarDesPublicarFormulario({
      pre_formulario_id,
      estado_publicacion: !estado_publicacion,
    });
    if (sms === "ok") {
      toast.success(mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid green",
          padding: "16px",
          color: "white",
          background: "green",
          borderColor: "white",
        },
        iconTheme: {
          primary: "green",
          secondary: "white",
        },
      });
      updateListFn();
    } else {
      toast.error(mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    }
    onCloseModalDesactivacion();
  };

  const onDeleted = async () => {
    const { sms, mensaje } = await ApiActivarDesactivarFormulario({
      pre_formulario_id,
      pre_estado: !estado,
    });
    if (sms === "ok") {
      toast.success(mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid green",
          padding: "16px",
          color: "white",
          background: "green",
          borderColor: "white",
        },
        iconTheme: {
          primary: "green",
          secondary: "white",
        },
      });
      updateListFn();
    } else {
      toast.error(mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    }
    setShowModal(false);
  };

  const onGenerateUrl = async () => {
    /*
    const { sms, mensaje, data } = await ApiGenerateUrlFormulario({
      pre_formulario_id,
      pre_proyecto_id,
    });
    if (sms === "ok") {
      toast.success(mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid green",
          padding: "16px",
          color: "white",
          background: "green",
          borderColor: "white",
        },
        iconTheme: {
          primary: "green",
          secondary: "white",
        },
      });
      */
    setUrlForm(`${url}proyectos/formulario/${pre_formulario_id}/${true}`); //isExternal
    onShowModalUrl();
    /**} else {
      toast.error(mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    } */
    setShowModal(false);
  };
  return (
    <div
      className="d-flex justify-content-between  flex-row my-2  bordesombreado bordecompleto "
      style={{ background: estado_publicacion ? "#6FA06F" : "#dc3545" }}
    >
      <div className="h-100"></div>
      <div className="w-100  py-4 px-3 " style={{ background: "#f9f9f9" }}>
        <div className="d-flex justify-content-between align-items-center">
          <h5>{formulario}</h5>
          <div className=" d-flex flex-row">
            {isExternal ? (
              <button
                type=""
                className={`bg-btn-vet bordesombreado mx-2 ${
                  tieneFormSist ? "btn-disabled" : ""
                }`}
                onClick={() => {
                  if (!tieneFormSist) {
                    goTo(
                      `/proyectos/formulario/${pre_formulario_id}/${isExternal}`
                    );
                  }
                }}
                disabled={tieneFormSist}
                style={{
                  cursor: tieneFormSist ? "not-allowed" : "pointer",
                  minWidth: "100px",
                }}
              >
                <i
                  className={
                    tieneFormSist
                      ? "fa-solid fa-lock"
                      : "fa-solid fa-list-check"
                  }
                ></i>
                {tieneFormSist ? " De sistema" : " Responder"}
              </button>
            ) : (
              <div className="dropdown">
                <button
                  className="bg-btn-small-circle bordesombreado"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fas fa-ellipsis-vertical mx-1"></i>
                </button>
                <ul
                  className="dropdown-menu p-0 m-0"
                  aria-labelledby="dropdownMenuButton1"
                  style={{ width: "200px" }}
                >
                  <li>
                    <button
                      type=""
                      className="  w-100 d-flex justify-content-start p-2 align-items-center"
                      onClick={() =>
                        goTo(`/proyectos/formulario/${pre_formulario_id}`)
                      }
                    >
                      <i className="fas fa-list"></i>
                      <label htmlFor="" className="mx-2">
                        Listado de preguntas
                      </label>
                    </button>
                  </li>
                  <li>
                    <button
                      type=""
                      className="  w-100 d-flex justify-content-start p-2 align-items-center"
                      onClick={onGenerateUrl}
                    >
                      <i className="fas fa-share"></i>
                      <label htmlFor="" className="mx-2">
                        Compartir
                      </label>
                    </button>
                  </li>
                  <li>
                    <button
                      type=""
                      className=" w-100 d-flex justify-content-start p-2 align-items-center"
                      onClick={() =>
                        goTo(`/proyectos/formulario/${pre_formulario_id}`)
                      }
                    >
                      <i className="fas fa-edit"></i>
                      <label htmlFor="" className="mx-2">
                        Editar
                      </label>
                    </button>
                  </li>
                  <li>
                    {estado_publicacion ? (
                      <button
                        type=""
                        onClick={
                          /* publicarQuitar(proyecto.pre_proyecto_id, false) */
                          onShowModalDesactivacion
                        }
                        className=" w-100 d-flex justify-content-start align-items-center p-2"
                      >
                        <label
                          htmlFor=""
                          className="bg-btn-small-circle-danger"
                        >
                          {" "}
                          <i className="fas fa-close"></i>
                        </label>
                        <label htmlFor="" className="mx-1">
                          Desactivar publicación
                        </label>
                      </button>
                    ) : (
                      <button
                        type=""
                        onClick={onShowModalDesactivacion}
                        className=" w-100 d-flex justify-content-start align-items-center p-2"
                      >
                        <label htmlFor="" className="bg-btn-small-circle-check">
                          <i className="fas fa-check"></i>
                        </label>
                        <label htmlFor="" className="mx-1">
                          Publicar
                        </label>
                      </button>
                    )}
                  </li>
                  <li>
                    <button
                      type=""
                      onClick={onShowModal}
                      className=" w-100 d-flex justify-content-start align-items-center p-2"
                    >
                      <i className="fas fa-close"></i>
                      <label htmlFor="" className="mx-1">
                        Eliminar
                      </label>
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <ModalDefault
          bandera={"confirmacion"}
          modal={showModal}
          size={"sm"}
          icontitulo={<null />}
          titulo={`Confirmación`}
          closeAction={onClosedModal}
        >
          <div className="row py-4 justify-content-center text-align-center ">
            Realmente desea eliminar el Formulario
          </div>
          <div className="d-flex justify-content-end flex-row align-self-start my-4">
            <button
              type=""
              onClick={onClosedModal}
              className="bg-btn-vet-danger sombra mx-1"
            >
              Cancelar
            </button>
            <button
              type=""
              className="bg-btn-vet sombra mx-1"
              onClick={onDeleted}
            >
              <i className="fas fa-save"> Eliminar</i>
            </button>
          </div>
        </ModalDefault>
        <ModalDefault
          bandera={"confirmacion"}
          modal={modalActivar}
          size={"md"}
          icontitulo={<null />}
          titulo={`Confirmación`}
          closeAction={onCloseModalDesactivacion}
        >
          <div className="row py-4 justify-content-center text-align-center ">
            {estado_publicacion ? (
              <h4>
                Se desactivará la publicación del formulario y no estará
                disponible para los usuarios
              </h4>
            ) : (
              <h4>
                Se activará la publicación del formulario y estará disponible
                para los usuarios
              </h4>
            )}
          </div>
          <div className="d-flex justify-content-between   flex-row align-self-start my-4">
            <button
              type=""
              onClick={onCloseModalDesactivacion}
              className="bg-btn-vet-danger sombra mx-1 w-100"
            >
              Cancelar
            </button>
            <button
              type=""
              onClick={onActivarDesactivar}
              className="bg-btn-vet sombra mx-1 w-100"
            >
              <i className="fas fa-save"></i>
              <label htmlFor="">
                {estado_publicacion ? "Desactivar" : "Activar"}
              </label>
            </button>
          </div>
        </ModalDefault>
        <ModalDefault
          bandera={"confirmacion"}
          modal={modalUrl}
          size={"md"}
          icontitulo={<null />}
          titulo={`Compartir Formulario`}
          closeAction={onCloseModalUrl}
        >
          <div className="row py-4 d-flex flex-column justify-content-center text-align-center ">
            <div className="col-12">
              <textarea
                className="form-control"
                rows={2}
                readOnly
                value={UrlForm}
              ></textarea>
            </div>
            <div className="col-12 d-flex justify-content-end mt-2">
              <button
                onClick={copyToClipboard}
                className="bg-btn-vet sombra m-2 w-30"
              >
                {copied ? "Copiado" : "Copiar"}
              </button>
            </div>

            <h6 className="">O comparte el codigo QR</h6>

            <div className="d-flex justify-content-center mt-2">
              <QRCodeCanvas
                value={UrlForm}
                bgColor="#FFFFFF"
                fgColor="#000000"
                level="Q"
                size={300}
                includeMargin={true}
              />
            </div>
          </div>
        </ModalDefault>
      </div>
    </div>
  );
};
