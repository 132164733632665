import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import ModalDefault from "../../modalDefault";
import InputFile from "../../drop-file-input/InputFile";
import { ApiCrearRecurso } from "../../../api/recursos";
import categorias from "./enumCategorias";
import { Progress } from "@mantine/core";
import { uploadFile } from "../../../s3";

const CrearRecurso = ({ updateListFn }) => {
  const [state, setState] = useState({
    nombre: "",
    categoria: "",
    descripcion: "",
    publico: true,
    recursos_documentos_files: [],
  });

  const [files, setFiles] = useState([]);

  const [progress, setProgress] = useState({
    status: false,
    progress: 0,
    file: "",
  });

  const resetState = () => {
    setState({
      nombre: "",
      categoria: "",
      descripcion: "",
      publico: true,
      recursos_documentos_files: [],
    });
    setFiles([]);
    setProgress({
      status: false,
      progress: 0,
      file: "",
    });
  };

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const onCloseModal = () => {
    setShowModal(false);
    resetState();
  };

  const onchange = (e, filesInput = null) => {
    if (filesInput) {
      setFiles(filesInput);
      return;
    }

    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  };

  const CrearRecursoFn = async () => {
    if (state.nombre.replace(/\s/g, "") === "") {
      toast.error("Digite el nombre del recurso");
      return false;
    }
    if (state.categoria === "") {
      toast.error("Seleccione la categoria del recurso");
      return false;
    }
    if (state.descripcion.replace(/\s/g, "") === "") {
      toast.error("Digite la descripción del recurso");
      return false;
    }

    if (files.length === 0) {
      toast.error("Cargue al menos un archivo");
      return false;
    }

    setLoading(true);

    const recursos = [];
    for (const file of files) {
      const res = await uploadFile(file, setProgress);
      if (res.success) {
        recursos.push({
          file: res.key,
          nombre: file.name,
          size: file.size.toString(),
          type: file.type,
          extension: obtenerExtension(file.name),
          estado: true,
        });
      } else {
        setLoading(false);
        toast.error("Error al cargar archivos");
        return;
      }
    }

    setState({
      ...state,
      recursos_documentos_files: recursos,
    });

    const data = await ApiCrearRecurso({
      ...state,
      recursos_documentos_files: recursos,
    });

    if (data.sms === "err") {
      toast.error("Error al crear el recurso");
      setLoading(false);
      return;
    }

    toast.success("Recurso creado correctamente");
    setLoading(false);
    onCloseModal();
    updateListFn(data.data);
  };

  return (
    <>
      <div className="row mt-3 mb-4">
        <div className="col-md-6 col-lg-4 w-100">
          <div
            className=" bg-white  border-0 borde-tarjeta"
            style={{ height: "auto" }}
          >
            <div className="card-body m-0 p-0">
              <div className="w-100 bgfondo2 bordeabajo bordesombreado d-flex flex-column align-items-center d-flex justify-content-center py-3 px-3">
                <div className="w-100  d-flex justify-content-between align-items-center px-3">
                  <h5 className=" negrita card-title">Recursos del sistema</h5>
                  <div className="d-flex justify-content-between flex-row align-self-start">
                    <div className="d-flex justify-content-end ">
                      <button
                        type=""
                        className="bg-btn-vet bordesombreado"
                        onClick={() => setShowModal(true)}
                      >
                        <i className="fa fa-plus mx-2"></i>
                        Crear recurso
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <ModalDefault
                bandera={"Crear recurso del sistema"}
                modal={showModal}
                size={"lg"}
                titulo={`Crear recurso del sistema`}
                closeAction={onCloseModal}
              >
                <div className="row py-4 ">
                  <div className="col-md-6">
                    <label className="py-2 negrita">Nombre del recurso :</label>
                    <input
                      type="text"
                      id="nombre"
                      name="nombre"
                      value={state.nombre}
                      className="inputtext2 bordecompleto"
                      placeholder="Digite el nombre"
                      onChange={onchange}
                    />
                  </div>
                  <div className="col-md-6 ">
                    <div className=" row">
                      <label className="x-0 py-2 negrita">
                        Categoria del recurso :
                      </label>

                      <div className={"col-md-12 p-0 m-0"}>
                        <select
                          className="inputselect2 bordecompleto"
                          id="categoria"
                          name="categoria"
                          style={{ width: "100%" }}
                          onChange={onchange}
                          value={state.categoria}
                        >
                          <option value="">Seleccione una categoria...</option>
                          {Object.entries(categorias).map(([valor, label]) => (
                            <option key={valor} value={valor}>
                              {label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="d-flex flex-column">
                      <label className=" py-2 negrita">
                        Descripción del recurso :
                      </label>
                      <p style={{ color: "blue" }}>
                        (Ingrese una descripción para informar al usuario sobre
                        lo que contiene este recurso)
                      </p>
                    </div>
                    <input
                      type="text"
                      id="descripcion"
                      name="descripcion"
                      value={state.descripcion}
                      placeholder="Digite una breve descripción"
                      className="inputtext2 bordecompleto"
                      onChange={onchange}
                    />
                  </div>

                  <div className="col-md-12">
                    <div className="d-flex flex-column">
                      <label className=" pt-2 negrita">
                        ¿Desea publicar este recurso?
                      </label>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="d-flex flex-row align-items-center">
                        <input
                          type="checkbox"
                          id="publico"
                          name="publico"
                          checked={state.publico}
                          value={state.publico}
                          className="mx-2 bordecompleto"
                          onChange={onchange}
                        />
                        <label className="py-2">Si, deseo publicarlo</label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <label className=" py-2 negrita">
                      Cargar recurso del sistema :
                    </label>
                    <div>
                      <InputFile onchange={onchange} name="files" id="files" />
                    </div>
                  </div>

                  <div className="d-flex justify-content-end flex-row align-self-start pt-4 px-4">
                    <div className="d-flex justify-content-end ">
                      <button
                        type=""
                        className="bg-btn-vet bordesombreado"
                        onClick={() => CrearRecursoFn()}
                        disabled={loading}
                        style={{ cursor: loading ? "not-allowed" : "pointer" }}
                      >
                        {loading ? (
                          <i className="fas fa-spinner fa-spin mx-2" />
                        ) : (
                          <i className="fas fa-save mx-2" />
                        )}
                        Guardar recurso
                      </button>
                    </div>
                  </div>
                </div>
                <Progress
                  radius="xl"
                  size={24}
                  sections={[
                    {
                      value: progress.progress,
                      color: "blue",
                      label: `${progress.file} - ${progress.progress}%`,
                    },
                  ]}
                />
              </ModalDefault>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const obtenerExtension = (filename) => {
  const lastIndex = filename.lastIndexOf(".");
  if (lastIndex === -1) {
    return "";
  }
  return filename.slice(lastIndex + 1);
};

export default CrearRecurso;
