import Axios from "../config/Axios";
export const ApiCrearRol = async (data) => {
  try {
    const result = await Axios.post(`/POSTcreateRoles`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiEditarRol = async (data) => {
  try {
    const result = await Axios.post(`/POSTeditRol`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetAllRoles = async (data) => {
  try {
    const result = await Axios.get(`/GETallRolesList`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};


export const ApiActivarDesactivarRol = async (data) => {
  try {
    const result = await Axios.post(`/GETActivarDesactivarRoles`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

