import React, { useEffect, useState } from "react";

import ListadoReportes from "../../../components/admin/reportes/listadoReportes";
import CrearReporte from "../../../components/admin/reportes/reportes";

const initialState = {
  pre_proyecto_id: "",
  pre_formulario_id: "",
  pre_formulario_compartido_id: "",
};
const Reportes = ({ isAdmin }) => {
  const [updateList, setUpdateList] = useState(false);
  const [type, setType] = useState("save");
  const [state, setState] = useState(initialState);
  const [preguntas, setPreguntas] = useState([]);
  const [usersRecords, setUsersRecords] = useState([]);
  const [verCompartidos, setVerCompartidos] = useState(false);
  const [fichasRecords, setFichasRecords] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    if (!isAdmin) {
      setVerCompartidos(true);
    }
  }, [isAdmin]);

  const updateListFn = () => {
    setUpdateList(!updateList);
  };

  const resetState = () => {
    setState(initialState);
  };
  return (
    <div className="hbgfondo2 d-flex justify-content-start flex-column ">
      <CrearReporte
        updateListFn={updateListFn}
        state={state}
        setState={setState}
        type={type}
        setType={setType}
        resetState={resetState}
        setPreguntas={setPreguntas}
        setUsersRecords={setUsersRecords}
        setVerCompartidos={setVerCompartidos}
        verCompartidos={verCompartidos}
        fichasRecords={fichasRecords}
        setFichasRecords={setFichasRecords}
        isAdmin={isAdmin}
        setLoadingData={setLoadingData}
        loadingData={loadingData}
      />

      {loadingData && (
        <div className="d-flex justify-content-center mt-2">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      {preguntas.length > 0 && loadingData === false && (
        <ListadoReportes
          updateList={updateList}
          updateListFn={updateListFn}
          setState={setState}
          preguntas={preguntas}
          usersRecords={usersRecords}
          setType={setType}
          state={state}
          setVerCompartidos={setVerCompartidos}
          verCompartidos={verCompartidos}
          fichasRecords={fichasRecords}
          setFichasRecords={setFichasRecords}
        />
      )}
    </div>
  );
};

export default Reportes;
