import Axios from "../../config/Axios";

export const ApiLogin = async (data) => {
  try {
    const result = await Axios.post(`/login`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiYo = async (data) => {
  try {
    const result = await Axios.post("/yo", data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};
