import Axios from "../config/Axios";
export const ApiCrearArea = async (data) => {
  try {
    const result = await Axios.post(`/POSTcreateAreas`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiEditarAreas = async (data) => {
  try {
    const result = await Axios.post(`/POSTeditArea`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetAllAreas = async (data) => {
  try {
    const result = await Axios.get(`/GETallAreas`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiActivarDesactivarAreas = async (data) => {
  try {
    const result = await Axios.post(`/GETActivarDesactivarAreas`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetAreasTree = async () => {
  try {
    const result = await Axios.get(`/GETAllAreasTree`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};
