import TextareaAutosize from "react-textarea-autosize";
import DropFileInput from "../../drop-file-input/DropFileInput";

export const getTiposFormulariosDesign = (
  info,
  onchange,
  i,
  onchangeObservacion
) => {
  const {
    pre_pregunta_id,
    pre_tipos_opciones,
    pregunta,
    pre_opciones,
    pre_fichas_preguntas,
    obligatorio,
    showobservacion,
    orden,
  } = info;

  if (pre_tipos_opciones.tipo === "text") {
    return (
      <div className="d-flex flex-column ">
        <TextareaAutosize
          value={`#${orden}) ${pregunta} ${obligatorio ? "(*)" : ""}`}
          htmlFor=""
          className="my-2 negrita"
          style={{
            border: "none",
            fontSize: "15px",
          }}
        ></TextareaAutosize>
        <TextareaAutosize
          className=" bordecompleto inputtext"
          type={pre_tipos_opciones.tipo}
          name={pre_pregunta_id}
          id={pre_pregunta_id}
          placeholder={`Respuesta`}
          value={pre_fichas_preguntas.respuesta}
          onChange={(e) => onchange(e, i, info)}
        />
      </div>
    );
  }
  if (pre_tipos_opciones.tipo === "radio") {
    return (
      <div className="d-flex flex-column ">
        <TextareaAutosize
          value={`#${orden}) ${pregunta} ${obligatorio ? "(*)" : ""}`}
          htmlFor=""
          className="my-2 negrita"
          style={{
            border: "none",
            fontSize: "15px",
          }}
        ></TextareaAutosize>
        {pre_opciones.length > 0
          ? pre_opciones.map((data2, index) => (
              <div className="my-2" key={index}>
                <input
                  onChange={(e) => onchange(e, index, info)}
                  type={pre_tipos_opciones.tipo}
                  name={pre_pregunta_id}
                  value={data2.pre_opcion_id}
                />
                <label>{data2.opcion}</label>
              </div>
            ))
          : null}
        {showobservacion ? (
          <div>
            <hr />
            <div className="d-flex flex-column ">
              <label> Observacion</label>
              <TextareaAutosize
                className=" bordecompleto inputtext"
                name={"observacion"}
                id={"observacion"}
                placeholder={`Realize una observación (Opcional)`}
                value={pre_fichas_preguntas.observacion}
                onChange={(e) => onchangeObservacion(e, i, info)}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  if (pre_tipos_opciones.tipo === "date") {
    return (
      <div className="d-flex flex-column ">
        <TextareaAutosize
          value={`#${orden}) ${pregunta} ${obligatorio ? "(*)" : ""}`}
          htmlFor=""
          className="my-2 negrita"
          style={{
            border: "none",
            fontSize: "15px",
          }}
        ></TextareaAutosize>
        <input
          className="inputtext bordecompleto"
          type={pre_tipos_opciones.tipo}
          name={pre_pregunta_id}
          id={pre_pregunta_id}
          placeholder={`Respuesta`}
          value={pre_fichas_preguntas.respuesta}
          onChange={(e) => onchange(e, i, info)}
        />
      </div>
    );
  }

  if (pre_tipos_opciones.tipo === "select") {
    return (
      <div className="d-flex flex-column ">
        <label
          htmlFor=""
          className="my-2 negrita"
          style={{
            fontSize: "15px",
          }}
        >
          {`#${orden}) ${pregunta} ${obligatorio ? "(*)" : ""}`}
        </label>
        <select
          className="inputselect bordecompleto"
          id={pre_pregunta_id}
          name={pre_pregunta_id}
          style={{ width: "100%" }}
          onChange={(e) => onchange(e, i, info)}
          value={pre_fichas_preguntas.respuesta}
        >
          <option value="">Seleccione su respuesta...</option>
          {pre_opciones.length > 0
            ? pre_opciones.map((data, index) => (
                <option value={data.pre_opcion_id} key={index}>
                  {data.opcion}
                </option>
              ))
            : null}
        </select>
        {showobservacion ? (
          <div>
            <hr />
            <div className="d-flex flex-column ">
              <label> Observacion</label>
              <TextareaAutosize
                className=" bordecompleto inputtext"
                name={"observacion"}
                id={"observacion"}
                placeholder={`Realize una observación (Opcional)`}
                value={pre_fichas_preguntas.observacion}
                onChange={(e) => onchangeObservacion(e, i, info)}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  if (pre_tipos_opciones.tipo === "time") {
    return (
      <div className="d-flex flex-column ">
        <TextareaAutosize
          value={`#${orden}) ${pregunta} ${obligatorio ? "(*)" : ""}`}
          htmlFor=""
          className="my-2 negrita"
          style={{
            border: "none",
            fontSize: "15px",
          }}
        ></TextareaAutosize>
        <input
          className="inputtext bordecompleto"
          type={pre_tipos_opciones.tipo}
          name={pre_pregunta_id}
          id={pre_pregunta_id}
          placeholder={`Respuesta`}
          value={pre_fichas_preguntas.respuesta}
          onChange={(e) => onchange(e, i, info)}
        />
      </div>
    );
  }

  if (pre_tipos_opciones.tipo === "file") {
    return (
      <div className="d-flex flex-column ">
        <TextareaAutosize
          value={`#${orden}) ${pregunta} ${obligatorio ? "(*)" : ""}`}
          htmlFor=""
          className="my-2 negrita"
          style={{
            border: "none",
            fontSize: "15px",
          }}
        ></TextareaAutosize>
        <input
          className="inputtext bordecompleto"
          type={pre_tipos_opciones.tipo}
          name={pre_pregunta_id}
          id={pre_pregunta_id}
          placeholder={`Respuesta`}
          value={pre_fichas_preguntas.respuesta}
          onChange={(e) => onchange(e, i, info)}
          multiple
          style={{ display: "none" }}
        />
        {/**previsualizacion de archivos */}
        <DropFileInput
          name={pre_pregunta_id}
          id={pre_pregunta_id}
          i={i}
          info={info}
          onchange={onchange}
        />
        {showobservacion ? (
          <div>
            <hr />
            <div className="d-flex flex-column ">
              <label> Observacion</label>
              <TextareaAutosize
                className=" bordecompleto inputtext"
                name={"observacion"}
                id={"observacion"}
                placeholder={`Realize una observación (Opcional)`}
                value={pre_fichas_preguntas.observacion}
                onChange={(e) => onchangeObservacion(e, i, info)}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  return <div>No hay datos que mostrar</div>;
};
