import React, { useContext, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
/* import { ReactComponent as HomeIcon } from "../../icons/home.svg";
import { ReactComponent as CogIcon } from "../../icons/cog.svg";
 */
/* import AuthContext from "../../Context/auth/authContext";
 */ import "./index.css";

//import { ReactComponent as HomeIcon } from "../../svg/home.svg";

import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import logo from "../../../assets/images/user.png"
import { ApiYo } from "../../../api/auth/ApiAuth";

const SideBar = ({ children, routes, titulo, subpath, panelControl, setPanelControl }) => {
  /*   const AuthenticationContext = useContext(AuthContext);
   */ /*  const { sessiondatacli } = AuthenticationContext; */
  /*  const { type, ruc } = sessiondatacli[0].veterinaria[0];*/

  const [isMovil, setIsMovil] = useState(false);
  const [inerwidth, setInerwidth] = useState(window.innerWidth);

  const handleResize = () => {
    setInerwidth(window.innerWidth);
    if (window.innerWidth <= 667) {
      setIsMovil(true);
    } else {
      setIsMovil(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => {
    setIsOpen(!isOpen);
    localStorage.setItem("isOpen", !isOpen);
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.1,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.1,
      },
    },
  };

  const onLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem("datauser");
    localStorage.removeItem("accessTokenAdm");
    localStorage.setItem("panel", false);
    setPanelControl(false);
    window.location = "/";
  };

  useEffect(() => {
    if (localStorage.getItem("isOpen") === "false") {
      setIsOpen(false);
    }
  }, []);

  const updateUser = async () => {
    try {

      const token = localStorage.getItem("accessTokenAdm");

      if (!token) {
        localStorage.removeItem("datauser");
        return;
      }

      const response = await ApiYo({ token: token });
      if (response.sms === "ok") {
        localStorage.setItem("datauser", JSON.stringify(response.data));
      } else {
        localStorage.removeItem("accessTokenAdm");
        localStorage.removeItem("datauser");
      }
      return;
    } catch (err) {
      console.log(err);
      localStorage.removeItem("accessTokenAdm");
    }
  };

  const handleButtonClick = async (e) => {
    e.preventDefault();
    await updateUser();
    setPanelControl(!panelControl);
    navigate("/");
  };

  const crearMenu = (route, index) => {
    if (route.subRoutes) {
      return (
        <SidebarMenu
          key={index}
          setIsOpen={setIsOpen}
          route={route}
          showAnimation={showAnimation}
          isOpen={isOpen}
          subpath={subpath}
          isMovil={isMovil}
        />
      );
    }

    return (
      <NavLink
        to={`${route.path}`}
        key={index}
        className="link py-2"
        activeclassname="active"
        onClick={() => {
          if (isMovil) {
            setIsOpen(false);
            localStorage.setItem("isOpen", false);
          }
        }}
      >
        <div className="icon">
          <i className={route.icon}></i>
        </div>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              variants={showAnimation}
              initial="hidden"
              animate="show"
              exit="hidden"
              className="link_text"
            >
              {route.name}
            </motion.div>
          )}
        </AnimatePresence>
      </NavLink>
    );
  };

  return (
    <>
      <div
        className="d-flex flex-row w-100 "
        style={{ height: "auto", minHeight: "100vh" }}
      >
        <motion.div
          animate={{
            width: isMovil ? isOpen ? `${inerwidth}px` : "40px" : isOpen ? "250px" : "50px",
            transition: {
              duration: 0.5,
              damping: 10,
            },
          }}
          className={`sidebar2 bordederecho borde-tarjeta bgfondo  ${isOpen ? "" : ""
            }`}
        >
          {isOpen && (
            <motion.div className="w-100">
              <div className="w-100 px-5 my-1"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <motion.img
                  src={logo}
                  style={{ height: "100px", width: "auto" }}
                ></motion.img>
              </div>
            </motion.div>
          )}
          {isOpen && (
            <div className="w-100 p-2 d-flex flex-column justify-content-between align-items-center ">
              <label htmlFor="" className="letrablanca">
                {JSON.parse(localStorage.getItem("datauser")).nombres}
              </label>
              <p style={{ color: "white" }}>
                {" "}
                ( {JSON.parse(localStorage.getItem("datauser")).cargo ? JSON.parse(localStorage.getItem("datauser")).cargo.pre_cargo : "Cargo no definido"} )
              </p>
              <div className="d-flex my-3 gap-3">
                <button
                  to={"/#"}
                  className=" bg-btn-vet-warning w-auto"
                  activeclassname="active"
                  onClick={handleButtonClick}
                >
                  <i className="fas fa-list"></i> <label htmlFor="">Formularios</label>
                </button>
                <button
                  to={"/#"}
                  className=" bg-btn-vet-danger w-auto"
                  activeclassname="active"
                  onClick={(e) => onLogout(e)}
                >
                  <i className="fa-solid fa-right-from-bracket"></i>{" "}
                  <label htmlFor="">Salir</label>
                </button>
              </div>
            </div>
          )}
          <div
            className={`top_section  d-flex flex-row w-100 bordearriba bordeabajo ${isOpen ? "justify-content-between" : "justify-content-center"
              } align-items-center align-self px-3 py-3`}
          >
            <AnimatePresence>
              {isOpen && (
                <motion.div>
                  <motion.h1
                    variants={showAnimation}
                    style={{ color: "white" }}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    className="logo m-0 titulosubmenusize"
                  >
                    {titulo}
                  </motion.h1>
                </motion.div>
              )}
            </AnimatePresence>
            <div className="bars icon" style={{
              cursor: "pointer",
            }} >
              {!isOpen ? (
                <FaBars onClick={toggle} fill="white" />
              ) : (
                <FaTimes onClick={toggle} fill="white" />
              )
              }
            </div>
          </div>
          <hr className="m-0 p-0" />
          <section className="routes" style={{ padding: isMovil ? "10px 5px" : "10px" }}>
            {!routes ? (
              <div>.......</div>
            ) : (
              routes.map((route, index) => {
                return crearMenu(route, index);
              })
            )}
          </section>
        </motion.div >
        <div className={isMovil ? isOpen ? "px-0" : "px-2" : "px-2"}
          style={{
            width: `calc(100% - ${isMovil ? !isOpen && "40px" : isOpen ? "250px" : "50px"})`,
            transition: !isMovil ? "all 0.5s" : "none",
            display: isMovil ? !isOpen ? "block" : "none" : "block",
          }}
        >{children}</div>
      </div >
    </>
  );
};

export default SideBar;
