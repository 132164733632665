import Axios from "../config/Axios";
export const ApiCrearAsignaciones = async (data) => {
  try {
    const result = await Axios.post(`/POSTcreateAsignaciones`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiObtenerAsignaciones = async () => {
  try {
    const result = await Axios.get(`/GETallAsignaciones`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiObtenerAsignacionesPorCodigo = async (codigo) => {
  try {
    const result = await Axios.get(`/GETAsignacionesByCodigo/${codigo}`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiActivarDesactivarAsignaciones = async (data) => {
  try {
    const result = await Axios.post(`/GETActivarDesactivarAsignaciones`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};
