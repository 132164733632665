import React, { useEffect, useState } from "react";
import {
  ApiGetDataFormulario,
  ApiEditarFormulario,
  ApiSaveAnonimoFormulario,
} from "../../../api/formularios";
import { firmarFoto } from "../../../api/files";

import { ApiAgregarBannerFormulario } from "../../../api/fichas";
import toast from "react-hot-toast";
import { renderPopover } from "../../../config/tools";
import ModalDefault from "../../modalDefault";
import InputFile from "../../inputFile";
import { useNavigate } from "react-router-dom";
const Formulario = ({
  updateListFn,
  formularioid,
  openModalCrearPregunta,
  setOrdenamiento,
  ordenamiento,
  isExternal,
  onSaveQuestionsOrdered,
  setShowModalImportar,
}) => {
  const navigate = useNavigate();
  const [showModalBanner, setShowModalBanner] = useState(false);
  const [imabanner, setImaBanner] = useState("");
  const [imabannerdb, setImaBannerdb] = useState("");
  const [isMovil, setIsMovil] = useState(false);
  const [loadingBanner, setlLoadingBanner] = useState(true);
  const [openModalNivelAnonimato, setOpenModalNivelAnonimato] = useState(false);
  const [nivelAnonimato, setNivelAnonimato] = useState(0);

  const handleResize = () => {
    if (window.innerWidth <= 667) {
      setIsMovil(true);
    } else {
      setIsMovil(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [data, setData] = useState(null);
  const [dataeditar, setDataEditar] = useState({
    pre_formulario_id: "",
    formulario: "",
    edit: false,
  });

  const [state, setState] = useState({
    opcion: "",
    value: "",
    pre_pregunta_id: "",
    opcion_pregunta: "",
    pre_opcion_id: "",
  });

  const [loading, setlLoading] = useState(false);
  const [loadingform, setlLoadingform] = useState(true);
  const [bannerFiles, setFilesBanner] = useState([]);

  const getFilesBanner = async (files) => {
    let aux = [];
    if (files.length > 0) {
      aux.push(files[0]);
      readAsFileUrl(files[0]);
    }
    setFilesBanner(aux);
  };

  const readAsFileUrl = (file) => {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onerror = reject;
      fr.onloadend = (e) => {
        setImaBanner(e.target.result);
        //resolve(e.target.result);
      };
      fr.readAsDataURL(file);
    });
  };

  useEffect(() => {
    init();
  }, []);

  const onchange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const editarFormuario = async () => {
    const { data, sms, mensaje } = await ApiEditarFormulario(dataeditar);
    if (sms === "ok") {
      renderPopover(sms, mensaje);
      init();
    } else {
      renderPopover(sms, mensaje);
    }
  };

  const onCloseModalBanner = () => {
    setFilesBanner([]);
    setImaBanner("");
    setShowModalBanner(false);
  };

  const onShowModalBanner = () => {
    setShowModalBanner(true);
  };

  const setbanner = async () => {
    const { sms: smsFile, mensaje: mensajeFile } =
      await ApiAgregarBannerFormulario({
        files: bannerFiles,
        pre_formulario_id: formularioid,
      });
    renderPopover(smsFile, mensajeFile);
    if (smsFile === "ok") {
      onCloseModalBanner();
      init();
    }
  };

  const init = async () => {
    setlLoadingform(true);
    const { data, sms, mensaje } = await ApiGetDataFormulario(formularioid);
    if (sms === "ok") {
      setData(data[0]);
      setNivelAnonimato(parseInt(data[0]?.anonimo));
      firmarFileFn(data[0].foto);
      setDataEditar({
        ...dataeditar,
        pre_formulario_id: data[0].pre_formulario_id,
        formulario: data[0].formulario,
        edit: false,
      });
      setlLoadingform(false);
    } else {
      toast.error(mensaje);
      setlLoadingform(false);
      setlLoadingBanner(false);
    }
  };
  const openModalImportar = (bool) => {
    setShowModalImportar(bool);
  };

  const firmarFileFn = async (foto) => {
    const firma = foto ? await firmarFoto(foto) : null;
    //setTimeout(setImaBannerdb(firma ? firma[0].data : ""), 1000);
    setImaBannerdb(firma ? firma[0].data : "");
    setlLoadingBanner(false);
    return;
  };

  const handleSliderChange = (e) => {
    setNivelAnonimato(parseFloat(e.target.value));
  };

  const handleSliderMouseUp = () => {
    let nivel = nivelAnonimato;
    if (nivelAnonimato < 0.5) {
      nivel = 0;
    } else if (nivelAnonimato < 1.5) {
      nivel = 1;
    } else {
      nivel = 2;
    }
    setNivelAnonimato(nivel);
  };

  const handleAnonimatoSave = async () => {
    const { sms, mensaje } = await ApiSaveAnonimoFormulario({
      pre_formulario_id: formularioid,
      anonimo: nivelAnonimato,
    });
    if (sms === "ok") {
      renderPopover(sms, mensaje);
      init();
    } else {
      renderPopover(sms, mensaje);
    }
  };

  return (
    <div className="row">
      {loadingform ? (
        <div className="w-100 h-100 d-flex  justify-content-center align-items-center my-3">
          <div className="spinner-border text-dark" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div
          className="row mt-3 mb-4"
          style={{
            padding: 0,
          }}
        >
          <div
            className="col-md-6 col-lg-4  w-100"
            style={{
              padding: 0,
            }}
          >
            {/* Add a style="height: XYZpx" to div.card to limit the card height and display scrollbar instead */}
            <div className="  border-0 " style={{ height: "auto" }}>
              <div className="card-body m-0 p-0">
                {isExternal ? null : (
                  <div className="w-100 bgfondo2  d-flex flex-column align-items-center d-flex justify-content-center py-3">
                    <div className="w-100  d-flex justify-content-between align-items-center">
                      <div className="w-100 d-flex justify-content-between align-items-center">
                        <div className="w-100 d-flex justify-content-start">
                          <button
                            type=""
                            className="bg-btn-vet bordesombreado mx-2"
                            onClick={() => {
                              // eslint-disable-next-line no-restricted-globals
                              navigate("/proyectos");
                            }}
                          >
                            <i className="fas fa-arrow-left"></i>
                          </button>
                          <div
                            className="w-100 negrita card-title mx-2"
                            style={{ margin: 0 }}
                          >
                            <div className="d-flex justify-content-start flex-row py-1">
                              <h5>Formulario: </h5>
                              {isExternal ? null : !dataeditar.edit ? null : (
                                <button
                                  type=""
                                  className="mx-2 bordesombreado bordecompleto"
                                  onClick={editarFormuario}
                                >
                                  Guardar cambios
                                </button>
                              )}
                            </div>
                            <input
                              type="text"
                              disabled={isExternal ? true : false}
                              name="pre_formulario_id"
                              className="bgfondo2 py-2 w-100"
                              onChange={(e) => {
                                if (e.target.value !== data.formulario) {
                                  setDataEditar({
                                    ...dataeditar,
                                    formulario: e.target.value,
                                    edit: true,
                                  });
                                } else {
                                  setDataEditar({
                                    ...dataeditar,
                                    formulario: e.target.value,
                                    edit: false,
                                  });
                                }
                              }}
                              style={{
                                border: "none",
                                fontSize: "13px",
                                textDecoration: "none",
                              }}
                              value={dataeditar.formulario}
                            />
                          </div>
                        </div>
                      </div>
                      {!isExternal && (
                        <div className="w-100 d-flex justify-content-end flex-row align-self-start">
                          {!isMovil && (
                            <div className="d-flex justify-content-end ">
                              {!ordenamiento && (
                                <button
                                  type=""
                                  className="bg-btn-vet bordesombreado mx-2"
                                  onClick={init}
                                >
                                  <i className="fas fa-rotate mx-2"></i>
                                </button>
                              )}
                              {!ordenamiento && (
                                <button
                                  type=""
                                  className="bg-btn-vet bordesombreado mx-2"
                                  onClick={() => {
                                    setOpenModalNivelAnonimato(true);
                                  }}
                                >
                                  <i
                                    className={`fas ${
                                      data.anonimo === "0"
                                        ? "fa-user"
                                        : data.anonimo === "1"
                                        ? "fa-user-circle"
                                        : "fa-user-secret"
                                    } mx-2`}
                                  />
                                  <br />
                                  Nivel anonimato
                                </button>
                              )}
                              {!ordenamiento && (
                                <button
                                  type=""
                                  className="bg-btn-vet bordesombreado mx-2"
                                  onClick={() => setOrdenamiento(true)}
                                >
                                  <i className="fa-solid fa-sort mx-2"></i>
                                  Ordenar Preguntas
                                </button>
                              )}
                              {ordenamiento && (
                                <button
                                  visible={ordenamiento}
                                  type=""
                                  className="bg-btn-vet bordesombreado mx-2"
                                  onClick={onSaveQuestionsOrdered}
                                >
                                  <i className="fas fa-save mx-2"></i>
                                  Guardar Orden
                                </button>
                              )}
                              {loading ? (
                                <div
                                  className="w-100 h-100 d-flex justify-content-center align-items-center"
                                  role="status"
                                >
                                  <span className="loadcustom"></span>
                                </div>
                              ) : (
                                <>
                                  {!ordenamiento && (
                                    <button
                                      type=""
                                      className="bg-btn-vet bordesombreado"
                                      onClick={() =>
                                        openModalCrearPregunta(true)
                                      }
                                      style={{ background: "red" }}
                                    >
                                      <i className="fas fa-save mx-2"></i>
                                      Agregar pregunta
                                    </button>
                                  )}
                                  {!ordenamiento && (
                                    <button
                                      type=""
                                      className="bg-btn-vet bordesombreado mx-2"
                                      onClick={() => openModalImportar(true)}
                                      style={{ background: "red" }}
                                    >
                                      <i className="fas fa-save mx-2"></i>
                                      Importar pregunta
                                    </button>
                                  )}
                                </>
                              )}
                            </div>
                          )}
                          {isMovil && (
                            <>
                              <div className="dropdown bg-btn-vet bordesombreado">
                                <button
                                  className="bg-btn-vet mx-2"
                                  type="button"
                                  id="menu-opciones-formulario"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fas fa-ellipsis-vertical mx-1"></i>
                                </button>
                                <ul
                                  className="dropdown-menu p-0 m-0"
                                  aria-labelledby="menu-opciones-formulario"
                                  style={{ width: "200px" }}
                                >
                                  {!ordenamiento && (
                                    <li>
                                      <button
                                        className="w-100 d-flex justify-content-start p-2 align-items-center"
                                        onClick={init}
                                      >
                                        <i className="fas fa-rotate mx-2"></i>
                                        Actualizar
                                      </button>
                                    </li>
                                  )}

                                  {!ordenamiento && (
                                    <li>
                                      <button
                                        className="w-100 d-flex justify-content-start p-2 align-items-center"
                                        onClick={() => {
                                          setOpenModalNivelAnonimato(true);
                                        }}
                                      >
                                        <i
                                          className={`fas ${
                                            data.anonimo === "0"
                                              ? "fa-user"
                                              : data.anonimo === "1"
                                              ? "fa-user-circle"
                                              : "fa-user-secret"
                                          } mx-2`}
                                        ></i>
                                        Nivel anonimato
                                      </button>
                                    </li>
                                  )}

                                  {!ordenamiento && (
                                    <li>
                                      <button
                                        type=""
                                        className="w-100 d-flex justify-content-start p-2 align-items-center"
                                        onClick={() => setOrdenamiento(true)}
                                      >
                                        <i className="fa-solid fa-sort mx-2"></i>
                                        Ordenar Preguntas
                                      </button>
                                    </li>
                                  )}

                                  {ordenamiento && (
                                    <li>
                                      <button
                                        visible={ordenamiento}
                                        type=""
                                        className="w-100 d-flex justify-content-start p-2 align-items-center"
                                        onClick={onSaveQuestionsOrdered}
                                      >
                                        <i className="fas fa-save mx-2"></i>
                                        Guardar Orden
                                      </button>
                                    </li>
                                  )}

                                  {!loading && (
                                    <>
                                      {!ordenamiento && (
                                        <li>
                                          <button
                                            type=""
                                            className="w-100 d-flex justify-content-start p-2 align-items-center"
                                            onClick={() =>
                                              openModalCrearPregunta(true)
                                            }
                                          >
                                            <i className="fas fa-save mx-2"></i>
                                            Agregar pregunta
                                          </button>
                                        </li>
                                      )}
                                      {!ordenamiento && (
                                        <li>
                                          <button
                                            type=""
                                            className="w-100 d-flex justify-content-start p-2 align-items-center"
                                            onClick={() =>
                                              openModalImportar(true)
                                            }
                                          >
                                            <i className="fas fa-save mx-2"></i>
                                            Importar pregunta
                                          </button>
                                        </li>
                                      )}
                                    </>
                                  )}
                                </ul>
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className="d-flex  justify-content-center align-items-center flex-column"
        style={{
          padding: 0,
        }}
      >
        <div
          className=" w-100 d-flex justify-content-center align-items-center"
          style={{
            position: "relative",
          }}
        >
          <div
            className="bordecompleto w-100"
            style={{
              minHeight: "200px",
              height: "200px",
              position: "relative",
            }}
            hidden={
              !loadingBanner && isExternal && imabannerdb === "" ? true : false
            }
          >
            {loadingBanner ? (
              <div
                className="w-100 h-100"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#1b3765",
                }}
              >
                <div className="spinner-border text-light" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : imabannerdb === "" ? (
              <img
                className="w-100 h-100"
                src={
                  "https://navisaf.com/wp-content/themes/consultix/images/no-image-found-360x250.png"
                }
                alt=""
              />
            ) : (
              <img
                className="img-fluid w-100 bgfondo borde-tarjeta"
                style={{
                  objectFit: "cover",
                  objectPosition: "center",
                  height: "200px",
                }}
                src={imabannerdb}
                alt=""
              />
            )}
          </div>
          {isExternal ? null : (
            <div
              className="bordecompleto d-flex flex-column  uploadbanner bg-white w-100 d-flex justify-content-center align-items-center"
              style={{
                minHeight: "200px",
                position: "absolute",
              }}
              onClick={onShowModalBanner}
            >
              <i className="fas fa-upload " style={{ fontSize: "40px" }}></i>
              <label htmlFor="" className="my-1">
                ...........
              </label>
              <h5 style={{ opacity: "0.4" }}>Subir banner</h5>
            </div>
          )}
        </div>
        {!isExternal ? null : (
          <div className="w-100 d-flex justify-content-center align-items-center flex-column my-4">
            <h1>{data ? data.formulario : "Cargando..."}</h1>
            <button
              className="bg-btn-vet-danger"
              style={{
                width: "80px",
              }}
              onClick={() => {
                // eslint-disable-next-line no-restricted-globals
                navigate("/");
              }}
            >
              <i className="fas fa-arrow-left"></i>
              <label htmlFor="">Salir</label>
            </button>
          </div>
        )}
      </div>

      <ModalDefault
        bandera={"confirmacion"}
        modal={showModalBanner}
        size={"md"}
        classnamebody={`p-0 m-0 w-100`}
        titulo={`UPLOAD BANNER`}
        closeAction={onCloseModalBanner}
      >
        <InputFile
          getFiles={getFilesBanner}
          absolute="absolute"
          opacidad="opacoUpload"
          multiple
          accept={"/*"}
        />
        <div className="w-100 p-3">
          <div>
            {imabanner === "" ? null : (
              <img
                className="w-100"
                src={imabanner}
                style={{ height: "200px" }}
                alt=""
              />
            )}
          </div>
        </div>
        <div className="w-100 p-2">
          <button
            type=""
            className="w-100 bg-btn-vet  bordecompleto bordesombreado"
            onClick={setbanner}
          >
            Guardar banner
          </button>
        </div>
      </ModalDefault>
      <ModalDefault
        bandera={"confirmacion"}
        modal={openModalNivelAnonimato}
        size={"md"}
        classnamebody={`p-0 m-0 w-100`}
        titulo={`Nivel de anonimato`}
        closeAction={() => setOpenModalNivelAnonimato(false)}
      >
        <div className="w-100 p-3">
          <div className="w-100 d-flex justify-content-center align-items-center flex-column">
            <div className="w-100 d-flex justify-content-center align-items-center position-relative my-4">
              <i className="fas fa-user mx-1 fa-lg" />{" "}
              <input
                type="range"
                min="0"
                max="2"
                step="0.01"
                value={nivelAnonimato}
                onChange={handleSliderChange}
                onMouseUp={handleSliderMouseUp}
                className="slider w-100"
                id="myRange"
              />
              <i className="fas fa-user-secret mx-1 fa-lg" />{" "}
            </div>
            <div className="w-100 text-center">
              {Number.isInteger(nivelAnonimato) ? (
                <>
                  <h5>
                    {nivelAnonimato === 0
                      ? "No anonimo"
                      : nivelAnonimato === 1
                      ? "A eleccion del usuario"
                      : "Anonimo"}
                  </h5>
                  <p>
                    {nivelAnonimato === 0
                      ? "Los datos del usuario serán siempre visibles."
                      : nivelAnonimato === 1
                      ? "El usuario decide si desea responder de forma anónima o no."
                      : "Todas las respuestas serán completamente anónimas."}
                  </p>
                </>
              ) : (
                <>
                  <h5>🔄️</h5>
                  <p>
                    Deslice el control para seleccionar el nivel de anonimato.
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end flex-row align-self-start my-4 mx-4">
          <button
            type=""
            onClick={() => {
              handleAnonimatoSave();
              setOpenModalNivelAnonimato(false);
            }}
            className="bg-btn-vet sombra mx-1"
          >
            <i className="fas fa-save mx-2"></i> Guardar
          </button>
        </div>
      </ModalDefault>
    </div>
  );
};

export default Formulario;
