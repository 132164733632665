import React, { useEffect, useRef, useState } from "react";
import ModalDefault from "../../modalDefault";

import { ActionIcon, Group, MultiSelect, TextInput } from "@mantine/core";
import { DataTable } from "mantine-datatable";
import { toast } from "react-hot-toast";
import {
  ApiActivarDesactivarUsuario,
  ApiGetAllUsers,
  UpdatePassword,
} from "../../../api/usuarios";

const BATCH_SIZE = 15;

const TablaOpciones = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showModalPassword, setShowModalPassword] = useState(false);
  const [allUsers, setAllUsers] = useState([]); // Nuevo estado para todos los usuarios
  const [users, setUsers] = useState([]); // Estado para usuarios filtrados
  const [areas, setAreas] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [centrosCosto, setCentrosCosto] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [fetching, setfetching] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [password, setPassword] = useState("");

  //estado de filtros
  const [queryCedula, setQueryCedula] = useState("");
  const [queryNombre, setQueryNombre] = useState("");
  const [queryApellido, setQueryApellido] = useState("");
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [selectedDepartamentos, setSelectedDepartamentos] = useState([]);
  const [selectedCentrosCosto, setSelectedCentrosCosto] = useState([]);
  const [selectedCargos, setSelectedCargos] = useState([]);

  //scroll infinito
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadedRecords, setLoadedRecords] = useState(BATCH_SIZE);
  const scrollViewportRef = useRef(null);

  //ordenamiento
  const [isInit, setIsInit] = useState(false);
  const [sortStatus, setSortStatus] = useState({
    columnAccessor: "",
    direction: "asc",
  });

  const columns = [
    {
      accessor: "usuario",
      title: "Cedula",
      textAlignment: "center",
      filter: (
        <TextInput
          placeholder="Buscar por cedula..."
          value={queryCedula}
          onChange={(e) => setQueryCedula(e.currentTarget.value)}
        />
      ),
      filtering: queryCedula !== "",
    },
    {
      accessor: "code",
      title: "Codigo",
      textAlignment: "center",
    },
    {
      accessor: "nombres",
      title: "Nombre",
      textAlignment: "center",
      filter: (
        <TextInput
          placeholder="Buscar por nombre..."
          value={queryNombre}
          onChange={(e) => setQueryNombre(e.currentTarget.value)}
        />
      ),
      filtering: queryNombre !== "",
      sortable: true,
    },
    {
      accessor: "apellidos",
      title: "Apellido",
      textAlignment: "center",
      filter: (
        <TextInput
          placeholder="Buscar por apellido..."
          value={queryApellido}
          onChange={(e) => setQueryApellido(e.currentTarget.value)}
        />
      ),
      filtering: queryApellido !== "",
      sortable: true,
    },
    {
      accessor: "genero",
      title: "Genero",
      textAlignment: "center",
    },
    {
      accessor: "pre_areas.pre_area",
      title: "Area",
      textAlignment: "center",
      filter: (
        <MultiSelect
          data={areas.map((area) => area.pre_area)}
          value={selectedAreas}
          onChange={setSelectedAreas}
          placeholder="Filtrar por área..."
        />
      ),
      filtering: selectedAreas.length > 0,
    },
    {
      accessor: "pre_departamentos.pre_departamento",
      title: "Departamento",
      textAlignment: "center",
      filter: (
        <MultiSelect
          data={departamentos.map(
            (departamento) => departamento.pre_departamento
          )}
          value={selectedDepartamentos}
          onChange={setSelectedDepartamentos}
          placeholder="Filtrar por departamento..."
        />
      ),
      filtering: selectedDepartamentos.length > 0,
    },
    {
      accessor: "pre_centros_costo.pre_centro_costo",
      title: "Centro de Costo",
      textAlignment: "center",
      filter: (
        <MultiSelect
          data={centrosCosto.map((centroCosto) => centroCosto.pre_centro_costo)}
          value={selectedCentrosCosto}
          onChange={setSelectedCentrosCosto}
          placeholder="Filtrar por centro de costo..."
        />
      ),
      filtering: selectedCentrosCosto.length > 0,
    },
    {
      accessor: "pre_cargos.pre_cargo",
      title: "Cargo",
      textAlignment: "center",
      filter: (
        <MultiSelect
          data={cargos.map((cargo) => cargo.pre_cargo)}
          value={selectedCargos}
          onChange={setSelectedCargos}
          placeholder="Filtrar por cargo..."
        />
      ),
      filtering: selectedCargos.length > 0,
    },
    {
      accessor: "acciones",
      title: "Acciones",
      textAlignment: "center",
      render: (record) => opciones(record),
    },
  ];

  const init = async () => {
    setfetching(true);
    const { data } = await ApiGetAllUsers();
    const filterUsers = data.filter((user) => user.estado !== false);
    setAllUsers(filterUsers);
    setUsers(filterUsers);
    setfetching(false);

    // Usando mapas para evitar duplicados
    let areasMap = new Map();
    let departamentosMap = new Map();
    let centrosCostoMap = new Map();
    let cargosMap = new Map();

    data.forEach((user) => {
      areasMap.set(user.pre_areas.pre_area_id, user.pre_areas);
      departamentosMap.set(
        user.pre_departamentos.pre_departamento_id,
        user.pre_departamentos
      );
      centrosCostoMap.set(
        user.pre_centros_costo.pre_centro_costo_id,
        user.pre_centros_costo
      );
      cargosMap.set(user.pre_cargos.pre_cargo_id, user.pre_cargos);
    });

    setAreas([...areasMap.values()]);
    setDepartamentos([...departamentosMap.values()]);
    setCentrosCosto([...centrosCostoMap.values()]);
    setCargos([...cargosMap.values()]);
    setIsInit(true);
  };

  //logica para manejar los filtros
  useEffect(() => {
    const queryCedulaLower = queryCedula.toLowerCase();
    const queryNombreLower = queryNombre.toLowerCase();
    const queryApellidoLower = queryApellido.toLowerCase();

    const selectedAreasSet = new Set(selectedAreas);
    const selectedDepartamentosSet = new Set(selectedDepartamentos);
    const selectedCentrosCostoSet = new Set(selectedCentrosCosto);
    const selectedCargosSet = new Set(selectedCargos);

    const isAreaSelected = (user) =>
      selectedAreas.length === 0 ||
      selectedAreasSet.has(user.pre_areas.pre_area);
    const isDepartamentoSelected = (user) =>
      selectedDepartamentos.length === 0 ||
      selectedDepartamentosSet.has(user.pre_departamentos.pre_departamento);
    const isCentroCostoSelected = (user) =>
      selectedCentrosCosto.length === 0 ||
      selectedCentrosCostoSet.has(user.pre_centros_costo.pre_centro_costo);
    const isCargoSelected = (user) =>
      selectedCargos.length === 0 ||
      selectedCargosSet.has(user.pre_cargos.pre_cargo);

    const filteredUsers = allUsers.filter((user) => {
      return (
        user.usuario.toLowerCase().includes(queryCedulaLower) &&
        user.nombres.toLowerCase().includes(queryNombreLower) &&
        user.apellidos.toLowerCase().includes(queryApellidoLower) &&
        isAreaSelected(user) &&
        isDepartamentoSelected(user) &&
        isCentroCostoSelected(user) &&
        isCargoSelected(user)
      );
    });
    setUsers(filteredUsers); // Actualiza users con el resultado del filtro
  }, [
    allUsers,
    queryCedula,
    queryNombre,
    queryApellido,
    selectedAreas,
    selectedCargos,
    selectedCentrosCosto,
    selectedDepartamentos,
  ]);

  //logica para manejar el ordenamiento
  useEffect(() => {
    if (!isInit) return;
    if (sortStatus.columnAccessor === "nombres") {
      const sortedRecords = [...allUsers].sort((a, b) => {
        const nombreA = a.nombres.toLowerCase();
        const nombreB = b.nombres.toLowerCase();
        return nombreA.localeCompare(nombreB);
      });

      if (sortStatus.direction === "desc") {
        setAllUsers(sortedRecords.reverse());
      } else {
        setAllUsers(sortedRecords);
      }
    }

    if (sortStatus.columnAccessor === "apellidos") {
      const sortedRecords = [...allUsers].sort((a, b) => {
        const apellidoA = a.apellidos.toLowerCase();
        const apellidoB = b.apellidos.toLowerCase();
        return apellidoA.localeCompare(apellidoB);
      });

      if (sortStatus.direction === "desc") {
        setAllUsers(sortedRecords.reverse());
      } else {
        setAllUsers(sortedRecords);
      }
    }
  }, [sortStatus]);

  useEffect(() => {
    setfetching(true);
    init();
  }, [props.updateList]);

  // Carga más datos cuando se llega al final del scroll
  const loadMoreData = () => {
    if (loadedRecords < users.length) {
      setLoadingMore(true);
      setTimeout(() => {
        setLoadedRecords((prevLoaded) =>
          Math.min(prevLoaded + BATCH_SIZE, users.length)
        );
        setLoadingMore(false);
      }, 1000);
    }
  };

  // Asegúrate de no cargar más registros de los disponibles
  const visibleUsers = users.slice(0, Math.min(loadedRecords, users.length));

  const editUser = (row) => {
    const {
      nombres,
      apellidos,
      correo,
      telefono,
      usuario,
      pre_rol_id,
      pre_area_id,
      pre_departamento_id,
      pre_centro_costo_id,
      pre_cargo_id,
      pre_usuario_id,
      code,
      genero,
    } = row;
    props.setType("editar");
    props.setState({
      ...props.state,
      nombres,
      apellidos,
      correo,
      telefono,
      usuario,
      pre_rol_id,
      pre_area_id,
      pre_departamento_id,
      pre_centro_costo_id,
      pre_cargo_id,
      pre_usuario_id,
      code,
      genero,
    });
    props.setShowModalSave(true);
  };

  const onShowModal = (e) => {
    setShowModal(true);
    setSelectedUser(e);
  };

  const onClosedModal = () => {
    setShowModal(false);
    setSelectedUser(null);
  };

  const onShowModalPassword = (e) => {
    setSelectedUser(e);
    setShowModalPassword(true);
  };

  const onClosedModalPassword = () => {
    setShowModalPassword(false);
    setSelectedUser(null);
  };

  const onDelete = async () => {
    const { estado, pre_usuario_id } = selectedUser;
    const { sms, mensaje } = await ApiActivarDesactivarUsuario({
      pre_usuario_id,
      pre_estado: !estado,
    });
    if (sms === "ok") {
      toast.success(mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid green",
          padding: "16px",
          color: "white",
          background: "green",
          borderColor: "white",
        },
        iconTheme: {
          primary: "green",
          secondary: "white",
        },
      });
      setSelectedUser(null);
      props.updateListFn();
    } else {
      toast.error(mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    }
    onClosedModal();
  };

  const onUpdatePassword = async () => {
    const { pre_usuario_id } = selectedUser;
    const { sms, mensaje } = await UpdatePassword({
      pre_usuario_id,
      password,
    });
    if (sms === "ok") {
      toast.success(mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid green",
          padding: "16px",
          color: "white",
          background: "green",
          borderColor: "white",
        },
        iconTheme: {
          primary: "green",
          secondary: "white",
        },
      });
      setSelectedUser(null);
      props.updateListFn();
    } else {
      toast.error(mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    }
    onClosedModalPassword();
  };

  const opciones = (row) => {
    return (
      <Group spacing={4} position="center" noWrap>
        <ActionIcon color="blue" onClick={() => onShowModalPassword(row)}>
          <i className="fas fa-key"></i>
        </ActionIcon>
        <ActionIcon
          color="blue"
          onClick={() => {
            editUser(row);
          }}
        >
          <i className="fas fa-file-pen"></i>
        </ActionIcon>
        <ActionIcon
          color="red"
          onClick={() => {
            onShowModal(row);
          }}
        >
          <i className="fas fa-trash"></i>
        </ActionIcon>
        <ModalDefault
          bandera={"confirmacion"}
          modal={showModal}
          size={"sm"}
          icontitulo={<null />}
          titulo={`Confirmación`}
          closeAction={onClosedModal}
        >
          <div className="row py-4 justify-content-center text-align-center ">
            Realmente desea eliminar
          </div>
          <div className="d-flex justify-content-end flex-row align-self-start my-4">
            <button
              type=""
              onClick={onClosedModal}
              className="bg-btn-vet-danger sombra mx-1"
            >
              Cancelar
            </button>
            <button
              type=""
              onClick={onDelete}
              className="bg-btn-vet sombra mx-1"
            >
              <i className="fas fa-save"> Eliminar</i>
            </button>
          </div>
        </ModalDefault>
      </Group>
    );
  };

  return (
    <div className="row">
      <div className="col-md-12 mb-3">
        <DataTable
          borderRadius="md"
          className="bordecompleto bgfondo"
          highlightOnHover
          noRecordsText="No se encontraron datos"
          columns={columns}
          totalRecords={users.length}
          withColumnBorders
          records={visibleUsers}
          fetching={fetching || loadingMore}
          onScrollToBottom={loadMoreData}
          scrollViewportRef={scrollViewportRef}
          style={{ maxHeight: "500px", overflowY: "auto" }}
          sortStatus={sortStatus}
          onSortStatusChange={setSortStatus}
        />
      </div>
      <ModalDefault
        bandera={"confirmacion"}
        modal={showModalPassword}
        size={"sm"}
        titulo={`Nueva Contraseña`}
        closeAction={onClosedModalPassword}
      >
        <div className="row py-4 ">
          <div className="col-md-12">
            <label className="py-2 negrita">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              className="inputtext2 bordecompleto"
              placeholder="Digite el Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end flex-row align-self-start my-4">
          <button
            type=""
            onClick={onClosedModalPassword}
            className="bg-btn-vet-danger sombra mx-1"
          >
            Cancelar
          </button>
          <button
            type=""
            onClick={onUpdatePassword}
            className="bg-btn-vet sombra mx-1"
          >
            <i className="fas fa-save"> Generar</i>
          </button>
        </div>
      </ModalDefault>
    </div>
  );
};

export default TablaOpciones;
