import fileDefault from "../assets/file-icons/file-default.png";
import fileDoc from "../assets/file-icons/file-doc.png";
import fileJpg from "../assets/file-icons/file-jpg.png";
import fileMp3 from "../assets/file-icons/file-mp3.png";
import fileMp4 from "../assets/file-icons/file-mp4.png";
import filePdf from "../assets/file-icons/file-pdf.png";
import filePng from "../assets/file-icons/file-png.png";
import filePpt from "../assets/file-icons/file-ppt.png";
import fileTxt from "../assets/file-icons/file-txt.png";
import fileXls from "../assets/file-icons/file-xls.png";
import fileZip from "../assets/file-icons/file-zip.png";

export const ImageInputFile = {
  default: fileDefault,
  png: filePng,
  jpg: fileJpg,
  jpeg: fileJpg,
  pdf: filePdf,
  "vnd.openxmlformats-officedocument.wordprocessingml.document": fileDoc,
  "vnd.openxmlformats-officedocument.spreadsheetml.sheet": fileXls,
  "vnd.openxmlformats-officedocument.presentationml.presentation": filePpt,
  mp3: fileMp3,
  mp4: fileMp4,
  "plain": fileTxt,
  "x-zip-compressed": fileZip,
};
