import React, { useState, useEffect } from "react";
import ModalDefault from "../../modalDefault";

import {} from "../../../api/areas.js";
import { toast } from "react-hot-toast";
import { DataTable } from "mantine-datatable";

import {
  ApiActivarDesactivarTipos,
  ApiGetAllTipoOpciones,
} from "../../../api/tipo_opciones";
import { ActionIcon, Group } from "@mantine/core";
const PAGE_SIZE = 7;
const TablaOpciones = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [tipos, setTipos] = useState([]);
  const [fetching, setfetching] = useState(true);
  const [paginatedTipos, setPaginatedTipos] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedTipo, setSelectedTipo] = useState(null);

  const columns = [
    {
      accessor: "detalle",
      title: "Detalle",
      textAlignment: "center",
    },
    {
      accessor: "tipo",
      title: "Tipo",
      textAlignment: "center",
    },
    {
      accessor: "icono",
      title: "Icono",
      textAlignment: "center",
    },
    {
      accessor: "acciones",
      title: "Acciones",
      textAlignment: "center",
      render: (record) => opciones(record),
    },
  ];

  useEffect(() => {
    setfetching(true);
    init();
  }, [props.updateList]);

  const init = async () => {
    const { data } = await ApiGetAllTipoOpciones();
    setTipos(data);
    const paginatedData = paginateData(data);
    setPaginatedTipos(paginatedData);
    setfetching(false);
  };

  const paginateData = (data) => {
    const startIndex = (page - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    return data.slice(startIndex, endIndex);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    const paginatedData = paginateData(tipos);
    setPaginatedTipos(paginatedData);
  };

  const editTipo = (row) => {
    const { tipo, icono, pre_tipo_opcion_id,detalle } = row;
    props.setType("editar");
    props.setState({
      ...props.state,
      tipo:tipo,
      icono:icono,
      pre_tipo_opcion_id:pre_tipo_opcion_id,
      detalle:detalle?detalle:""
    });
  };

  const onShowModal = (e) => {
    setShowModal(true);
    setSelectedTipo(e);
  };

  const onClosedModal = () => {
    setShowModal(false);
    setSelectedTipo(null);
  };

  const onDelete = async () => {
    const { estado, pre_tipo_opcion_id } = selectedTipo;
    const { sms, mensaje } = await ApiActivarDesactivarTipos({
      pre_tipo_opcion_id,
      pre_estado: !estado,
    });
    if (sms === "ok") {
      toast.success(mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid green",
          padding: "16px",
          color: "white",
          background: "green",
          borderColor: "white",
        },
        iconTheme: {
          primary: "green",
          secondary: "white",
        },
      });
      setSelectedTipo(null);
      props.updateListFn();
    } else {
      toast.error(mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    }
    onClosedModal();
  };

  const opciones = (row) => {
    return (
      <Group spacing={4} position="center" noWrap>
        <ActionIcon
          color="blue"
          onClick={() => {
            editTipo(row);
          }}
        >
          <i className="fas fa-file-pen"></i>
        </ActionIcon>
        <ActionIcon
          color="red"
          onClick={() => {
            onShowModal(row);
          }}
        >
          <i className="fas fa-trash"></i>
        </ActionIcon>
        <ModalDefault
          bandera={"confirmacion"}
          modal={showModal}
          size={"sm"}
          icontitulo={<null />}
          titulo={`Confirmación`}
          closeAction={onClosedModal}
        >
          <div className="row py-4 justify-content-center text-align-center ">
            Realmente desea eliminar
          </div>
          <div className="d-flex justify-content-end flex-row align-self-start my-4">
            <button
              type=""
              onClick={onClosedModal}
              className="bg-btn-vet-danger sombra mx-1"
            >
              Cancelar
            </button>
            <button
              type=""
              onClick={onDelete}
              className="bg-btn-vet sombra mx-1"
            >
              <i className="fas fa-save"> Eliminar</i>
            </button>
          </div>
        </ModalDefault>
      </Group>
    );
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <DataTable
          borderRadius="md"
          className="bordecompleto bgfondo "
          highlightOnHover
          noRecordsText="No se encontraron datos"
          records={paginatedTipos}
          columns={columns}
          totalRecords={tipos.length}
          recordsPerPage={PAGE_SIZE}
          page={page}
          onPageChange={handlePageChange}
          withColumnBorders
          fetching={fetching}
        />
      </div>
    </div>
  );
};

export default TablaOpciones;
