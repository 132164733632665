import React, { useState, useEffect } from "react";
import TableValidExcelData from "./tableValidExcelData";
import TableErrExcelData from "./tableErrExcelData";
import InputFile from "./inputFile";
import * as xlsx from "xlsx";
import { ApiSyncUsersFromExcel } from "../../../../api/syncUsersFromExcel";
import toast from "react-hot-toast";
import { FaChartBar, FaCheckCircle } from "react-icons/fa";

import ModalDefault from "../../../modalDefault";
import {
  Badge,
  Center,
  Group,
  RingProgress,
  Text,
  ThemeIcon,
} from "@mantine/core";
import { ApiGetAreasTree } from "../../../../api/areas";

const CargaDeDatosExcelSeguro = () => {
  const [state, setState] = useState([]);
  const [loadsync, setLoadsync] = useState(false);
  const [modal, setModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [report, setReport] = useState([]);
  const [mensaje, setmensaje] = useState(
    "Se ha iniciado sincronizacion de usuarios, espere en el pagina por favor..."
  );
  const [areasTree, setAreasTree] = useState([]);
  const [progress, setProgress] = useState({ completed: 0, total: 0 });

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const areas = await ApiGetAreasTree();
    if (areas.sms === "ok") {
      setAreasTree(areas.data);
    } else {
      toast.error("Error al cargar areas");
    }
  };

  const getFiles = (files) => {
    if (files.length > 0) {
      const reader = new FileReader();
      const selectedFile = files[0];
      reader.readAsArrayBuffer(selectedFile);
      reader.onload = async (e) => {
        const workbook = xlsx.read(e.target.result, { type: "buffer" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);

        const dax = await getarray(json);
        setState(dax);
      };

      reader.onerror = (e) => {
        console.log(e);
      };
    }
  };

  const getarray = async (json) => {
    const x = await json.map((data) => {
      return {
        nombres: data.NOMBRES ? data.NOMBRES.toString().trim() : "",
        apellidos: data.APELLIDOS ? data.APELLIDOS.toString().trim() : "",
        ci: data.CI ? data.CI.toString().trim() : "",
        cod: data.COD ? data.COD.toString().trim() : "",
        genero: data.GENERO ? data.GENERO.toString().trim() : "",
        area: data.AREA ? data.AREA.toString().trim() : "",
        centro: data.CENTRO_COSTO ? data.CENTRO_COSTO.toString().trim() : "",
        departamento: data.DEPARTAMENTO
          ? data.DEPARTAMENTO.toString().trim()
          : "",
        cargo: data.CARGO ? data.CARGO.toString().trim() : "",
        fila: data.__rowNum__ + 1,
      };
    });

    return x;
  };

  const sincronizar = async () => {
    if (state.length === 0) {
      toast.error("No hay datos para sincronizar");
      return;
    }

    setModal(true);
    setProgress({ completed: 0, total: state.length });
    setLoadsync(true);
    for (let i = 0; i < state.length; i++) {
      const data = state[i];
      const areaTree = await getAreaTree(data);
      let result;
      if (areaTree.sms === "err") {
        result = {
          sms: "err",
          status: "404",
          mensaje: areaTree.mensaje,
        };
      } else {
        const req = {
          ...data,
          ...areaTree.data,
        };

        result = await ApiSyncUsersFromExcel(req);
      }

      if (result.sms === "err") {
        setReport((old) => [
          ...old,
          {
            ...data,
            sms: "err",
            status: "500",
            mensaje: result.mensaje,
          },
        ]);
      } else {
        setReport((old) => [
          ...old,
          {
            ...data,
            sms: "ok",
            status: result.status,
            mensaje: result.mensaje,
          },
        ]);
      }
      setProgress((prevProgress) => ({
        ...prevProgress,
        completed: prevProgress.completed + 1,
      }));
    }
    setLoadsync(false);
    setmensaje(
      "Sincronización finalizada, cierra la ventana para ver el reporte"
    );
  };

  const getAreaTree = async (data) => {
    try {
      const area = areasTree.find((area) => area.pre_area === data.area);
      if (!area) {
        return {
          sms: "err",
          mensaje: `Área '${data.area}' no encontrada`,
          data: null,
        };
      }

      const departamento = area.pre_departamentos.find(
        (departamento) => departamento.pre_departamento === data.departamento
      );
      if (!departamento) {
        return {
          sms: "err",
          mensaje: `Departamento '${data.departamento}' no encontrado`,
          data: null,
        };
      }

      const centro = departamento.pre_centros_costo.find(
        (centro) => centro.pre_centro_costo === data.centro
      );
      if (!centro) {
        return {
          sms: "err",
          mensaje: `Centro de costo '${data.centro}' no encontrado`,
          data: null,
        };
      }

      const cargo = centro.pre_cargos.find(
        (cargo) => cargo.pre_cargo === data.cargo
      );
      if (!cargo) {
        return {
          sms: "err",
          mensaje: `Cargo '${data.cargo}' no encontrado`,
          data: null,
        };
      }

      return {
        sms: "ok",
        mensaje: "Área tree obtenido correctamente",
        data: {
          area: area.pre_area_id,
          departamento: departamento.pre_departamento_id,
          centro: centro.pre_centro_costo_id,
          cargo: cargo.pre_cargo_id,
        },
      };
    } catch (error) {
      return {
        sms: "err",
        mensaje: error.message,
        data: null,
      };
    }
  };

  const closeActionModal = (data, bandera) => {
    setModal(data);
    setProgress({ completed: 0, total: 0 });
    setReportModal(true);
  };

  const closeActionModalReport = (data, bandera) => {
    setReportModal(data);
    setmensaje(
      "Se ha iniciado sincronizacion de usuarios, espere en el pagina por favor..."
    );
    setReport([]);
    setState([]);
  };

  return (
    <div className="p-2 d-flex flex-column">
      <div>
        <h5>Subir archivo de Excel</h5>
      </div>
      <hr />
      <div className="py-3">
        <InputFile
          getFiles={getFiles}
          accept={
            "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          }
        />
      </div>
      <br />
      {state.length > 0 ? (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <h5>Datos válidos</h5>
            <button
              className="bgfondo2 bordecompleto bordesombreado p-3 negrita"
              onClick={sincronizar}
              style={{ background: "green", color: "white" }}
            >
              <i className="fas fa-sync"></i> Sincronizar con el servidor (
              {state.length} registros)
            </button>
          </div>
          <hr />
          <TableValidExcelData data={state} />
        </>
      ) : (
        <div>No hay datos</div>
      )}

      <ModalDefault
        size="lg"
        modal={modal}
        bandera="excel"
        closeAction={closeActionModal}
        titulo="Reporte de sincronización"
        icontitulo={null}
      >
        <Text align="center">{mensaje}</Text>
        <Center>
          <div className="d-flex flex-column">
            {progress.completed !== state.length ? (
              <RingProgress
                sections={[
                  {
                    value: (progress.completed / progress.total) * 100,
                    color: "blue",
                  },
                ]}
                label={
                  <Text color="blue" weight={700} align="center" size="sm">
                    {progress.completed}/{progress.total}
                  </Text>
                }
              />
            ) : (
              <RingProgress
                sections={[{ value: 100, color: "teal" }]}
                label={
                  <Center>
                    <ThemeIcon
                      color="teal"
                      variant="light"
                      radius="xl"
                      size="xl"
                    >
                      <FaCheckCircle size={22} />
                    </ThemeIcon>
                  </Center>
                }
              />
            )}
          </div>
        </Center>
      </ModalDefault>
      <ModalDefault
        size="lg"
        modal={reportModal}
        bandera="excel"
        closeAction={closeActionModalReport}
        titulo="Reporte de sincronización"
        icontitulo={null}
      >
        <Text align="center">Reporte de sincronizacion</Text>
        <Center>
          <RingProgress
            size={150}
            thickness={10}
            roundCaps
            sections={[
              {
                value:
                  (report.filter(
                    (data) => data.sms === "ok" && data.status === "200"
                  ).length /
                    report.length) *
                  100,
                color: "teal",
              },
              {
                value:
                  (report.filter(
                    (data) => data.sms === "ok" && data.status === "201"
                  ).length /
                    report.length) *
                  100,
                color: "orange",
              },
              {
                value:
                  (report.filter((data) => data.sms === "err").length /
                    report.length) *
                  100,
                color: "red",
              },
            ]}
            label={
              <Center>
                <ThemeIcon color="blue" variant="light" radius="xl" size={100}>
                  <FaChartBar size={50} />
                </ThemeIcon>
              </Center>
            }
          />
        </Center>
        <Group position="center">
          <Badge color="teal" variant="filled" radius="xl">
            {
              report.filter(
                (data) => data.sms === "ok" && data.status === "200"
              ).length
            }{" "}
            Registros sincronizados
          </Badge>
          <Badge color="orange" variant="filled" radius="xl">
            {
              report.filter(
                (data) => data.sms === "ok" && data.status === "201"
              ).length
            }{" "}
            Registros creados
          </Badge>
          <Badge color="red" variant="filled" radius="xl">
            {report.filter((data) => data.sms === "err").length} Registros con
            errores
          </Badge>
        </Group>
        <div className="mt-3">
          <TableErrExcelData
            data={report.filter(
              (data) =>
                data.sms === "err" ||
                (data.sms === "ok" && data.status === "201")
            )}
          />
        </div>
      </ModalDefault>
    </div>
  );
};

export default CargaDeDatosExcelSeguro;
