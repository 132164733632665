import React, { useEffect, useState } from "react";
import Select from "react-select";
import toast from "react-hot-toast";
import makeAnimated from "react-select/animated";

import {
  ApiCrearGrupoAcceso,
  ApiEditarGrupoAcceso,
} from "../../../../api/grupos";

import { ApiGetAllUsers } from "../../../../api/usuarios";

const animatedComponents = makeAnimated();

const CrearGrupo = ({
  updateListFn,
  state,
  setState,
  type,
  setType,
  resetState,
}) => {
  const [loading, setlLoading] = useState(false);
  const [usersOptions, setUsersOptions] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const fields = [
    { name: "nombre", label: "Nombre" },
    { name: "descripcion", label: "Descripcion" },
    { name: "pre_usuarios", label: "Usuarios" },
  ];

  useEffect(() => {
    init();
    return () => {};
  }, []);

  useEffect(() => {
    if (type !== "save") {
      setSelectedUsers(state.pre_usuarios);
    }
    return () => {};
  }, [state]);

  const init = async () => {
    const { data } = await ApiGetAllUsers();
    let filtered = data.filter((item) => item.estado !== false);
    const usuarios = filtered.map((item) => {
      return {
        value: item.pre_usuario_id,
        label: `${item.usuario} - ${item.nombres} ${item.apellidos}`,
      };
    });

    setUsersOptions(usuarios);
  };

  const onchange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const emptyFields = fields.filter((campo) => !state[campo.name]);

  const CrearFn = async () => {
    setlLoading(true);
    if (emptyFields.length > 0) {
      const mensajes = emptyFields.map(
        (campo) => `El campo "${campo.label}" no puede estar vacío.`
      );
      const mensajeError = mensajes.join("\n");
      toast.error(mensajeError, {
        position: "bottom-center",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
      setlLoading(false);
      return;
    }

    const res =
      type === "save"
        ? await ApiCrearGrupoAcceso(state)
        : await ApiEditarGrupoAcceso(state);
    const datares = res;

    if (datares.sms === "ok") {
      toast.success(datares.mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid green",
          padding: "16px",
          color: "white",
          background: "green",
          borderColor: "white",
        },
        iconTheme: {
          primary: "green",
          secondary: "white",
        },
      });
      setType("save");
      resetState();
      setSelectedUsers([]);
      updateListFn();
    } else {
      toast.error(datares.mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    }
    setlLoading(false);
  };

  return (
    <div>
      <div className="row mt-3 mb-4">
        <div className="col-md-6 col-lg-4  w-100">
          <div
            className=" bg-white  border-0 borde-tarjeta"
            style={{ height: "auto" }}
          >
            <div className="card-body m-0 p-0">
              <div className="w-100 bgfondo2 bordeabajo bordesombreado d-flex flex-column align-items-center d-flex justify-content-center py-3 px-3">
                <div className="w-100  d-flex justify-content-between align-items-center px-3">
                  <h5 className=" negrita card-title">
                    {type === "save" ? "Crear" : "Editar"} Grupo de acceso
                  </h5>
                  <div className="d-flex justify-content-between flex-row align-self-start">
                    <div className="d-flex justify-content-end ">
                      {loading ? (
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <button
                          type=""
                          className="bg-btn-vet bordesombreado"
                          onClick={() => CrearFn()}
                        >
                          <i className="fas fa-save mx-2"></i>
                          {type === "save" ? "Guardar" : "Editar"} Grupo de
                          acceso
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row py-4 ">
                <div className="col-md-6">
                  <label className="py-2 negrita">Nombre</label>
                  <input
                    type="text"
                    id="nombre"
                    name="nombre"
                    value={state.nombre}
                    className="inputtext2 bordecompleto"
                    placeholder="Digite el nombre del grupo"
                    onChange={onchange}
                  />
                </div>
                <div className="col-md-6">
                  <label className="py-2 negrita">Usuarios</label>
                  <Select
                    value={usersOptions.filter((option) =>
                      selectedUsers.includes(option.value)
                    )}
                    isMulti
                    name="pre_usuarios"
                    options={usersOptions}
                    placeholder="Seleccione los usuarios para el grupo"
                    onChange={(selectedOptions) => {
                      const values = selectedOptions.map(
                        (option) => option.value
                      );
                      setState({
                        ...state,
                        pre_usuarios: values,
                      });
                      setSelectedUsers(values);
                    }}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    className="basic-multi-select react-select-container"
                    classNamePrefix=" select react-select"
                  />
                </div>
                <div className="col-md-12">
                  <label className="py-2 negrita">Descripcion</label>
                  <textarea
                    type="text"
                    id="descripcion"
                    name="descripcion"
                    value={state.descripcion}
                    className="inputtext2 bordecompleto"
                    placeholder="Digite la descripcion del grupo"
                    onChange={onchange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrearGrupo;
