import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import CrearRecurso from "../../../components/admin/RecursosDeSistema/crearRecurso";
import ListaRecursos from "../../../components/admin/RecursosDeSistema/listadoRecursos";
import TransmisioneRecursos from "../../../components/admin/RecursosDeSistema/transmisionRecursos";
import { ApiGetRecursos } from "../../../api/recursos";
import { ApiGetTransmisiones } from "../../../api/transmisiones";

const RecursosDelSistemaPage = ({ isExternal }) => {
  const [recursos, setRecursos] = useState([]);
  const [transmisiones, setTransmisiones] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setLoading(true);
    let res = await ApiGetRecursos();

    if (res.sms === "err") {
      toast.error("Error al cargar los recursos");
      return;
    }

    if (isExternal) {
      res.data = res.data.filter((recurso) => recurso.publico === true);
    }

    setRecursos(res.data);

    let rest = await ApiGetTransmisiones();

    if (rest.sms === "err") {
      toast.error("Error al cargar las transmisiones");
      return;
    }

    setTransmisiones(rest.data);

    setLoading(false);
  };

  const updateListFn = async (recurso) => {
    setRecursos([...recursos, recurso]);
  };

  const updateTransmisionesFn = async (transmision, isEditMode = false) => {
    if (isEditMode) {
      const updatedTransmisiones = transmisiones.map((item) => {
        if (
          item.transmision_recurso_id === transmision.transmision_recurso_id
        ) {
          return transmision;
        }
        return item;
      });

      setTransmisiones(updatedTransmisiones);
    } else {
      setTransmisiones([...transmisiones, transmision]);
    }
  };

  const deleteRecursoFn = async (recurso_documento_id) => {
    const newRecursos = recursos.filter(
      (recurso) => recurso.recurso_documento_id !== recurso_documento_id
    );
    setRecursos(newRecursos);
  };

  const deleteTransmisionFn = async (transmision_id) => {
    const newTransmisiones = transmisiones.filter(
      (transmision) => transmision.transmision_recurso_id !== transmision_id
    );
    setTransmisiones(newTransmisiones);
  };

  const toggleRecursoFn = async (recurso_documento_id) => {
    const newRecursos = recursos.map((recurso) => {
      if (recurso.recurso_documento_id === recurso_documento_id) {
        recurso.publico = !recurso.publico;
      }
      return recurso;
    });
    setRecursos(newRecursos);
  };

  const toggleTransmisionFn = async (transmision_id) => {
    const newTransmisiones = transmisiones.map((transmision) => {
      if (transmision.transmision_recurso_id === transmision_id) {
        transmision.transmitiendo = !transmision.transmitiendo;
      }
      return transmision;
    });
    setTransmisiones(newTransmisiones);
  };

  return (
    <div>
      {!isExternal && <CrearRecurso updateListFn={updateListFn} />}
      <ListaRecursos
        recursos={recursos}
        loading={loading}
        isExternal={isExternal}
        deleteRecursoFn={deleteRecursoFn}
        toggleRecursoFn={toggleRecursoFn}
      />
      {!isExternal && (
        <TransmisioneRecursos
          recursos={recursos}
          transmisiones={transmisiones}
          updateTransmisionesFn={updateTransmisionesFn}
          deleteTransmisionFn={deleteTransmisionFn}
          toggleTransmisionFn={toggleTransmisionFn}
          loading={loading}
          isExternal={isExternal}
        />
      )}
    </div>
  );
};

export default RecursosDelSistemaPage;
