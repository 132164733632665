import { useEffect, useState } from "react";
import { Nav } from "./Nav";
import {
  ApiEditarInstanciaAccidente,
  ApiEliminarInstanciaAccidente,
  ApiGetInstanciasAccidentes,
  ApiToggleEstadoInstanciaAccidente,
  ApiToggleTipoInstanciaAccidente,
} from "../../api/instanciaAccidentes";
import { DataTable } from "mantine-datatable";
import { ActionIcon, Badge, Button, Group } from "@mantine/core";
import ModalDefault from "../../components/modalDefault";
import { ApiObtenerAsignacionesPorCodigo } from "../../api/asignaciones";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { ApiToggleEstadoFicha } from "../../api/instanciaFichas";
import ContadorIncidentes from "../../components/contadorIncidentes";
import { ApiGetGruposAccesoUser } from "../../api/usuarios";
import { Incidencias } from "../../components/admin/proyectos/Incidencias";
import FichaDetalleCard from "./FichaDetalleCard";

const IncidentesPage = ({ panelControl, setPanelControl, isExternal }) => {
  const userData = JSON.parse(localStorage.getItem("datauser"));
  const [instancias, setInstancias] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [fichasModal, setFichasModal] = useState([]);
  const [codigoModal, setCodigoModal] = useState("");
  const [instaciaIdModal, setInstaciaIdModal] = useState("");
  const [asinacionesModal, setAsignacionesModal] = useState([]);
  const [showModalTipoAccidente, setShowModalTipoAccidente] = useState(false);
  const [incideteModal, setIncidenteModal] = useState({});

  const [showModalEditar, setShowModalEditar] = useState(false);
  const [incidenteModalEditar, setIncidenteModalEditar] = useState({});

  const [showModalEliminar, setShowModalEliminar] = useState(false);
  const [incidenteModalEliminar, setIncidenteModalEliminar] = useState({});

  const [gruposAcceso, setGruposAcceso] = useState([]);

  const [showFichaModal, setShowFichaModal] = useState(false);
  const [fichaSeleccionada, setFichaSeleccionada] = useState(null);

  const [showFichaModalEdit, setShowFichaModalEdit] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const res = await ApiGetInstanciasAccidentes();
    if (res.sms === "ok") {
      //console.log(res.data[0].pre_grupo_acceso_usuario.estado);
      //filtrar todos los datas que tengan acceso usuario en estado false
      //const data = res.data.filter(
      //  (data) => data.pre_grupo_acceso_usuario.estado === false
      //);
      setInstancias(res.data);
    }

    const { data, sms, mensaje } = await ApiGetGruposAccesoUser(
      userData.pre_usuario_id
    );
    if (sms === "ok") {
      setGruposAcceso(data);
    } else {
      toast.error(mensaje, {
        position: "bottom-left",
      });
    }
  };

  const getAsignaciones = async (codigo) => {
    const res = await ApiObtenerAsignacionesPorCodigo(codigo);
    if (res.sms === "ok") {
      setAsignacionesModal(res.data);
    }
  };

  const onFichasClick = (fichas, codigo, instanciaId) => {
    setCodigoModal(codigo);
    setInstaciaIdModal(instanciaId);
    setFichasModal(fichas);
    getAsignaciones(codigo);
    setShowModal(true);
  };

  const columns = [
    {
      accessor: "fecha_accidente",
      title: "Fecha accidente",
      textAlignment: "Fecha accidente",
    },
    {
      accessor: "cedula_accidentado",
      title: "Cédula accidentado",
      textAlignment: "center",
    },
    {
      accessor: "nombres_accidentado",
      title: "Nombres accidentado",
      textAlignment: "center",
    },
    {
      accessor: "pre_grupo_acceso_usuario.pre_usuarios.nombres",
      title: "Reportado por",
      textAlignment: "center",
    },
    {
      accessor: "tipo_accidente",
      title: "Tipo accidente",
      textAlignment: "center",
    },
    {
      accessor: "estado_instancia",
      title: "Estado",
      textAlignment: "center",
      render: (record) => {
        let color = "";
        switch (record.estado_instancia) {
          case "Finalizado":
            color = "green";
            break;
          case "Pendiente":
            color = "gray";
            break;
          case "En proceso":
            color = "yellow";
            break;
          default:
            color = "gray";
        }

        return (
          <Badge color={color} variant="filled">
            {record.estado_instancia}
          </Badge>
        );
      },
    },
    {
      accessor: "instancias_accidentes_fichas",
      title: "Ficha",
      textAlignment: "center",
      render: (record) => {
        const codigo = record.pre_asignacion_formulario_cod;
        const instanciaId = record.instancia_accidente_id;
        const fichas = record.instancias_accidentes_fichas;
        return (
          <Button
            color="blue"
            onClick={() => {
              onFichasClick(fichas, codigo, instanciaId);
            }}
          >
            {fichas.length} ficha(s)
          </Button>
        );
      },
    },
    {
      accessor: "acciones",
      title: "Acciones",
      textAlignment: "center",
      render: (record) => opciones(record),
    },
  ];

  const opciones = (record) => {
    const esEstadoFinalizado = record.estado_instancia === "Finalizado";
    return (
      <Group>
        <ActionIcon
          color="blue"
          onClick={() => {
            toggleEstadoInstanciaAccidente(record);
          }}
          disabled={esEstadoFinalizado}
        >
          <i
            className={`fas ${esEstadoFinalizado ? "fa-undo" : "fa-check"}`}
          ></i>
        </ActionIcon>
        <ActionIcon
          color="yellow"
          onClick={() => {
            setIncidenteModal(record);
            setShowModalTipoAccidente(true);
          }}
        >
          <i className="fas fa-exclamation-triangle"></i>
        </ActionIcon>
        <ActionIcon
          color="blue"
          onClick={() => {
            setShowModalEditar(true);
            setIncidenteModalEditar(record);
          }}
        >
          <i className="fas fa-edit"></i>
        </ActionIcon>
        <ActionIcon
          color="red"
          onClick={() => {
            setShowModalEliminar(true);
            setIncidenteModalEliminar(record);
          }}
        >
          <i className="fas fa-trash"></i>
        </ActionIcon>
      </Group>
    );
  };

  const getFormulariosNoCompletados = () => {
    // Extrae los IDs de los formularios de las fichas completadas
    const idsFormulariosCompletados = fichasModal.map(
      (ficha) => ficha.pre_fichas.pre_formulario_id
    );

    // Filtra las asignaciones para obtener solo aquellas cuyos formularios no están en los completados
    return asinacionesModal.filter(
      (asignacion) =>
        !idsFormulariosCompletados.includes(
          asignacion.pre_formularios.pre_formulario_id
        )
    );
  };

  const llenarFicha = (asignacion) => {
    // Verifica que el estado de todas las fichas de ficha modal sean diferentes de "En proceso"
    const fichasEnProceso = fichasModal.filter(
      (ficha) => ficha.estado_ficha === "En proceso"
    );

    if (fichasEnProceso.length > 0) {
      toast.error(
        "No se puede llenar la ficha porque existen fichas en proceso"
      );
      return;
    }

    // Verifica que el usuario logueado esté en el grupo de acceso de la asignación
    const userId = userData.pre_usuario_id;
    const usuariosAsignacion = asignacion.pre_grupos_acceso.pre_usuarios.map(
      (u) => u.pre_usuarios.pre_usuario_id
    );

    if (!usuariosAsignacion.includes(userId)) {
      toast.error("No tienes acceso a esta ficha");
      return;
    }

    // Si pasa las verificaciones, procede con la acción
    localStorage.setItem(
      "instancia",
      JSON.stringify({
        instancia_accidente_id: instaciaIdModal,
        isEdit: false,
      })
    );

    navigate(
      `/proyectos/formulario/${
        asignacion.pre_formularios.pre_formulario_id
      }/${true}`
    );
  };

  const editFicha = (ficha) => {
    console.log(ficha);
    // Si pasa las verificaciones, procede con la acción
    localStorage.setItem(
      "instancia",
      JSON.stringify({
        instancia_accidente_id: ficha.instancia_accidente_id,
        isEdit: true,
        instancia_accidente_ficha: ficha,
      })
    );

    navigate(
      `/proyectos/formulario/${
        ficha.pre_fichas.pre_formularios.pre_formulario_id
      }/${true}`
    );
  };
  const toggleFicha = async (instancia_accidente_ficha_id) => {
    const res = await ApiToggleEstadoFicha({
      instancia_accidente_ficha_id,
    });

    if (res.sms === "ok") {
      toast.success(res.mensaje);
      setShowModal(false);
      init();
    } else {
      toast.error(res.mensaje);
    }
  };

  const sonTodasLasFichasCompletadas = (data) => {
    // Obtiene todos los códigos de formulario asignados a esta instancia
    const codigosFormulariosAsignados =
      data.pre_grupo_acceso_usuario.pre_grupos_acceso.pre_asignacion_formularios.map(
        (asignacion) => asignacion.codigo
      );

    // Verifica si cada código de formulario asignado tiene una ficha correspondiente
    const fichasCompletadas = data.instancias_accidentes_fichas.map(
      (ficha) =>
        ficha.pre_fichas.pre_formularios.pre_asignacion_formularios[0].codigo
    );

    return codigosFormulariosAsignados.every((codigo) =>
      fichasCompletadas.includes(codigo)
    );
  };

  const toggleEstadoInstanciaAccidente = async (data) => {
    // Verifica si el usuario logueado fue el que creo la asignación
    const userId = userData.pre_usuario_id;

    if (
      data.pre_grupo_acceso_usuario.pre_grupos_acceso
        .pre_asignacion_formularios[0].pre_usuario_id !== userId
    ) {
      toast.error("No eres el administrador de esta asignación");
      return;
    }

    // Primero, verifica si hay fichas faltantes
    if (!sonTodasLasFichasCompletadas(data)) {
      toast.error("No se puede cambiar el estado porque hay fichas faltantes.");
      return;
    }

    // Luego, verifica si todas las fichas están finalizadas
    if (sonTodasLasFichasFinalizadas(data)) {
      const res = await ApiToggleEstadoInstanciaAccidente({
        instancia_accidente_id: data.instancia_accidente_id,
      });
      if (res.sms === "ok") {
        toast.success(res.mensaje);
        init();
      } else {
        toast.error(res.mensaje);
      }
    } else {
      const fichasEnProceso = data.instancias_accidentes_fichas.filter(
        (ficha) => ficha.estado_ficha === "En proceso"
      );

      const detallesFichas = fichasEnProceso
        .map(
          (ficha) =>
            `Ficha: ${ficha.pre_fichas.pre_formularios.formulario}, Hecha por: ${ficha.pre_fichas.nombres}`
        )
        .join(", ");

      toast.error(
        `No se puede llenar la ficha porque existen fichas en proceso: ${detallesFichas}`
      );
    }
  };

  const toggleTipoInstanciaAccidente = async (incideteModal) => {
    // Verifica si el usuario logueado fue el que creo la asignación
    const userId = userData.pre_usuario_id;

    if (
      incideteModal.pre_grupo_acceso_usuario.pre_grupos_acceso
        .pre_asignacion_formularios[0].pre_usuario_id !== userId
    ) {
      toast.error("No eres el administrador de esta asignación");
      return;
    }

    if (incideteModal.estado_instancia !== "Finalizado") {
      toast.error(
        "No se puede cambiar el tipo de accidente porque la instancia no está finalizada"
      );
      return;
    }

    const res = await ApiToggleTipoInstanciaAccidente({
      instancia_accidente_id: incideteModal.instancia_accidente_id,
      tipo_accidente: incideteModal.tipo_accidente,
    });

    if (res.sms === "ok") {
      toast.success(res.mensaje);
      setShowModalTipoAccidente(false);
      init();
      setAsignacionesModal([]);
    } else {
      toast.error(res.mensaje);
    }
  };
  const sonTodasLasFichasFinalizadas = (data) => {
    // Verificar si todas las fichas en todas las instancias están finalizadas
    return data.instancias_accidentes_fichas.every(
      (ficha) => ficha.estado_ficha === "Finalizado"
    );
  };

  const editInstanciaAccidente = async (incidente) => {
    // Verifica que el usuario logueado fue el que creo este incidente
    const userId = userData.pre_usuario_id;
    if (userId !== incidente.pre_grupo_acceso_usuario.pre_usuario_id) {
      toast.error("No fuistes quien creo esta asignacion");
      return;
    }

    const res = await ApiEditarInstanciaAccidente(incidente);

    if (res.sms === "ok") {
      toast.success(res.mensaje);
      setShowModalEditar(false);
      setIncidenteModalEditar({});
      init();
    } else {
      toast.error(res.mensaje);
    }
  };

  const deleteInstanciaAccidente = async (incidente) => {
    // Verifica si el usuario logueado fue el que creo la asignación
    const userId = userData.pre_usuario_id;

    if (
      incidente.pre_grupo_acceso_usuario.pre_grupos_acceso
        .pre_asignacion_formularios[0].pre_usuario_id !== userId
    ) {
      toast.error("No eres el administrador de esta asignación");
      return;
    }

    const res = await ApiEliminarInstanciaAccidente(incidente);

    if (res.sms === "ok") {
      toast.success(res.mensaje);
      setShowModalEliminar(false);
      setIncidenteModalEliminar({});
      init();
    } else {
      toast.error(res.mensaje);
    }
  };

  return (
    <>
      <Nav panelControl={panelControl} setPanelControl={setPanelControl} />
      <div className="p-2">
        <ContadorIncidentes />
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="my-3">Incidentes</h3>
          </div>
          {isExternal && gruposAcceso.length > 0 && (
            <Incidencias isExternal={isExternal} gruposAcceso={gruposAcceso} />
          )}
        </div>
        <div className="row">
          <div className="col-md-12 mb-4 ">
            <DataTable
              borderRadius="md"
              className="bordecompleto bgfondo"
              highlightOnHover
              noRecordsText="No se encontraron datos"
              columns={columns}
              records={instancias}
            />
          </div>
        </div>
      </div>
      <ModalDefault
        bandera={"fichas"}
        modal={showModal}
        size={"lg"}
        titulo={`Fichas de la instancia`}
        closeAction={() => setShowModal(false)}
      >
        <div className="d-flex justify-content-end flex-column align-self-start py-2">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Hecha por</th>
                <th>Reporte</th>
                <th>Codigo asignacion</th>
                <th>Estado</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {fichasModal.map((ficha) => {
                const esFichaFinalizada = ficha.estado_ficha === "Finalizado";
                return (
                  <tr key={ficha.pre_ficha_id}>
                    <td>{ficha.pre_fichas.nombres}</td>
                    <td>{ficha.pre_fichas.pre_formularios.formulario}</td>
                    <td>
                      {
                        ficha.pre_fichas.pre_formularios
                          .pre_asignacion_formularios[0].codigo
                      }
                    </td>
                    <td>{ficha.estado_ficha}</td>
                    <td>
                      <Group>
                        <ActionIcon
                          color="blue"
                          onClick={() => {
                            toggleFicha(ficha.instancia_accidente_ficha_id);
                          }}
                          disabled={esFichaFinalizada}
                        >
                          <i
                            className={`fas ${
                              esFichaFinalizada ? "fa-undo" : "fa-check"
                            }`}
                          ></i>
                        </ActionIcon>
                        <ActionIcon
                          color="blue"
                          onClick={() => {
                            editFicha(ficha);
                          }}
                          //disabled={esFichaFinalizada}
                        >
                          <i className="fas fa-edit"></i>
                        </ActionIcon>
                        <ActionIcon
                          color="blue"
                          onClick={() => {
                            setFichaSeleccionada(ficha);
                            setShowFichaModal(true);
                          }}
                        >
                          <i className="fas fa-eye"></i>
                        </ActionIcon>
                      </Group>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="d-flex flex-column gap-2">
            {getFormulariosNoCompletados().length > 0 && (
              <h5 className="my-3">Fichas faltantes</h5>
            )}

            {getFormulariosNoCompletados().map((asignacion) => (
              <Button
                onClick={() => {
                  llenarFicha(asignacion);
                }}
                className="py-2"
                key={asignacion.pre_formularios.pre_formulario_id}
              >
                {asignacion.pre_formularios.formulario} -{" "}
                {asignacion.pre_formularios.pre_formulario_id}
              </Button>
            ))}
          </div>
        </div>
      </ModalDefault>
      <ModalDefault
        bandera={"tipoAccidente"}
        modal={showModalTipoAccidente}
        size={"md"}
        titulo={`Tipo de accidente`}
        closeAction={() => setShowModalTipoAccidente(false)}
      >
        <div className="d-flex justify-content-end flex-column align-self-start py-2">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="negrita" htmlFor="">
                  Tipo de accidente
                </label>
                <select
                  className="inputtext2 bordecompleto my-3"
                  value={incideteModal.tipo_accidente}
                  onChange={(e) => {
                    setIncidenteModal({
                      ...incideteModal,
                      tipo_accidente: e.target.value,
                    });
                  }}
                >
                  <option value="">Seleccione una opción</option>
                  <option value="Pendiente">Pendiente</option>
                  <option value="Con baja">Con baja</option>
                  <option value="Sin baja">Sin baja</option>
                </select>
              </div>
              <Button
                color="blue"
                className="py-2"
                onClick={() => {
                  console.log(incideteModal);
                  toggleTipoInstanciaAccidente(incideteModal);
                }}
              >
                Guardar
              </Button>
            </div>
          </div>
        </div>
      </ModalDefault>
      <ModalDefault
        bandera={"editar"}
        modal={showModalEditar}
        size={"md"}
        titulo={`Editar incidente`}
        closeAction={() => {
          setShowModalEditar(false);
          setIncidenteModalEditar({});
        }}
      >
        <div className="d-flex justify-content-end flex-column align-self-start py-2">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="negrita" htmlFor="">
                  Cédula accidentado
                </label>
                <input
                  type="text"
                  className="inputtext2 bordecompleto my-3"
                  value={incidenteModalEditar.cedula_accidentado}
                  onChange={(e) => {
                    setIncidenteModalEditar({
                      ...incidenteModalEditar,
                      cedula_accidentado: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="form-group">
                <label className="negrita" htmlFor="">
                  Nombres accidentado
                </label>
                <input
                  type="text"
                  className="inputtext2 bordecompleto my-3"
                  value={incidenteModalEditar.nombres_accidentado}
                  onChange={(e) => {
                    setIncidenteModalEditar({
                      ...incidenteModalEditar,
                      nombres_accidentado: e.target.value,
                    });
                  }}
                />
              </div>
              <Button
                color="blue"
                className="py-2"
                onClick={() => {
                  editInstanciaAccidente(incidenteModalEditar);
                }}
              >
                Guardar
              </Button>
            </div>
          </div>
        </div>
      </ModalDefault>
      <ModalDefault
        bandera={"eliminar"}
        modal={showModalEliminar}
        size={"sm"}
        titulo={`Eliminar incidente`}
        closeAction={() => {
          setShowModalEliminar(false);
        }}
      >
        <div className="row py-4 justify-content-center text-align-center ">
          Realmente desea eliminar el incidente
        </div>
        <div className="d-flex justify-content-end flex-row align-self-start my-4">
          <button
            type=""
            onClick={() => {
              setShowModalEliminar(false);
            }}
            className="bg-btn-vet-danger sombra mx-1"
          >
            Cancelar
          </button>
          <button
            type=""
            onClick={() => {
              deleteInstanciaAccidente(incidenteModalEliminar);
            }}
            className="bg-btn-vet sombra mx-1"
          >
            <i className="fas fa-save"> Eliminar</i>
          </button>
        </div>
      </ModalDefault>
      <ModalDefault
        bandera={"verFicha"}
        modal={showFichaModal}
        size={"lg"}
        titulo={`Detalle de la Ficha`}
        closeAction={() => setShowFichaModal(false)}
      >
        <div>
          {fichaSeleccionada &&
          fichaSeleccionada.pre_fichas.pre_fichas_preguntas ? (
            fichaSeleccionada.pre_fichas.pre_fichas_preguntas.map(
              (fichaPregunta, index) => (
                <FichaDetalleCard key={index} fichaPregunta={fichaPregunta} />
              )
            )
          ) : (
            <div className="alert alert-danger" role="alert">
              No hay respuestas disponibles para esta ficha.
            </div>
          )}
        </div>
      </ModalDefault>
      <ModalDefault
        bandera={"editarFicha"}
        modal={showFichaModalEdit}
        size={"lg"}
        titulo={`Editar Ficha`}
        closeAction={() => setShowFichaModalEdit(false)}
      >
        <div>
          {fichaSeleccionada &&
          fichaSeleccionada.pre_fichas.pre_fichas_preguntas ? (
            <>Hola</>
          ) : (
            <div className="alert alert-danger" role="alert">
              No hay respuestas disponibles para esta ficha.
            </div>
          )}
        </div>
      </ModalDefault>
    </>
  );
};

export default IncidentesPage;
