import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import toast from "react-hot-toast";
import ModalDefault from "../../modalDefault";
import { ApiCrearProyectos } from "../../../api/proyectos";
import { ApiGetAllAreas } from "../../../api/areas";

const animatedComponents = makeAnimated();

const CrearProyectos = ({ updateListFn }) => {
  const dataUser = JSON.parse(localStorage.getItem("datauser"));

  const [state, setState] = useState({
    proyecto: "",
    descripcion: "",
    pre_usuario_id: "",
    tipo_proyecto_code: "interno",
    tieneFormSist: false,
    areas_asignadas: [],
  });

  const [loading, setlLoading] = useState(false);
  const [areas, setAreas] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [areasOptions, setAreasOptions] = useState([]);

  useEffect(() => {
    init();
  }, []);

  const onCloseModal = () => {
    setShowModal(false);
    setState({
      proyecto: "",
      descripcion: "",
      tipo_proyecto_code: "interno",
      tieneFormSist: false,
      areas_asignadas: [],
    });
  };

  const init = async () => {
    setState({
      ...state,
      pre_usuario_id: JSON.parse(localStorage.getItem("datauser"))
        .pre_usuario_id,
    });
    const { data } = await ApiGetAllAreas();
    setAreas(data);
    const options = data.map((item) => {
      return {
        value: item.pre_cod_area,
        label: `${item.pre_cod_area} - ${item.pre_area}`,
      };
    });
    setAreasOptions(options);
  };

  const onchange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  };

  const CrearProyectoFn = async () => {
    setlLoading(true);
    if (state.proyecto === "") {
      toast.error("El nombre del proyecto se encuentra vacío", {
        position: "bottom-center",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
      setlLoading(false);
      return;
    }

    if (
      state.areas_asignadas.length === 0 &&
      state.tipo_proyecto_code === "interno"
    ) {
      toast.error(
        "Si es privado debe asignar al menos un área a la categoria",
        {
          position: "bottom-center",
          style: {
            border: "1px solid red",
            padding: "16px",
            color: "white",
            background: "red",
            borderColor: "white",
          },
          iconTheme: {
            primary: "red",
            secondary: "white",
          },
        }
      );
      setlLoading(false);
      return;
    }

    const res = await ApiCrearProyectos(state);
    const datares = res;
    if (datares.sms === "ok") {
      toast.success(datares.mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid green",
          padding: "16px",
          color: "white",
          background: "green",
          borderColor: "white",
        },
        iconTheme: {
          primary: "green",
          secondary: "white",
        },
      });
      updateListFn();
      onCloseModal();

      setState({
        proyecto: "",
        descripcion: "",
        tipo_proyecto_code: "interno",
        areas_asignadas: [],
        tieneFormSist: false,
      });
    } else {
      toast.error(datares.mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    }
    setlLoading(false);
  };

  return (
    <div>
      <div className="row mt-3 mb-4">
        <div className="col-md-6 col-lg-4  w-100">
          {/* Add a style="height: XYZpx" to div.card to limit the card height and display scrollbar instead */}
          <div
            className=" bg-white  border-0 borde-tarjeta"
            style={{ height: "auto" }}
          >
            <div className="card-body m-0 p-0">
              <div className="w-100 bgfondo2 bordeabajo bordesombreado d-flex flex-column align-items-center d-flex justify-content-center py-3 px-3">
                <div className="w-100  d-flex justify-content-between align-items-center px-3">
                  <h5 className=" negrita card-title">
                    Categorías de formularios
                  </h5>
                  <div className="d-flex justify-content-between flex-row align-self-start">
                    <div className="d-flex justify-content-end ">
                      <button
                        type=""
                        className="bg-btn-vet bordesombreado"
                        onClick={() => setShowModal(true)}
                      >
                        <i className="fa fa-plus mx-2"></i>
                        Crear categoría
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <ModalDefault
                bandera={"Crear categoría"}
                modal={showModal}
                size={"lg"}
                titulo={`Crear categoría`}
                closeAction={onCloseModal}
              >
                <div className="row py-4 ">
                  <div className="col-md-6">
                    <label className="py-2 negrita">
                      Nombre de la categoria{" "}
                    </label>
                    <input
                      type="text"
                      id="proyecto"
                      name="proyecto"
                      value={state.proyecto}
                      className="inputtext2 bordecompleto"
                      placeholder="Digite el nombre de la categoría del proyecto"
                      onChange={onchange}
                    />
                  </div>
                  <div className="col-md-6 ">
                    <div className=" row">
                      <label className="x-0 py-2 negrita">
                        Tipo de acceso para está categoría :
                      </label>

                      <div className={"col-md-12 p-0 m-0"}>
                        <select
                          className="inputselect2 bordecompleto"
                          id="tipo_proyecto_code"
                          name="tipo_proyecto_code"
                          style={{ width: "100%" }}
                          onChange={onchange}
                          value={state.tipo_proyecto_code}
                        >
                          <option value="">
                            Seleccione tipo de proyecto...
                          </option>
                          <option value="externo">Categoría pública</option>
                          <option value="interno">Categoría privada</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {state.tipo_proyecto_code === "interno" && (
                    <div className="col-md-12">
                      <div className="d-flex flex-column">
                        <label className=" py-2 negrita">
                          Areas que podran acceder a esta categoria
                        </label>
                      </div>
                      <Select
                        isMulti
                        name="areas_asignadas"
                        options={areasOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Seleccione las areas que podran acceder a esta categoria"
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        onChange={(e) => {
                          setState({
                            ...state,
                            areas_asignadas: e.map((item) => item.value),
                          });
                        }}
                      />
                    </div>
                  )}
                  <div className="col-md-12">
                    <div className="d-flex flex-column">
                      <label className=" py-2 negrita">
                        Descripción del de la categoria
                      </label>
                      <p style={{ color: "blue" }}>
                        (Ingrese una descripción para informar al usuario sobre
                        el uso de los formularios de esta categoría.)
                      </p>
                    </div>
                    <input
                      type="text"
                      id="descripcion"
                      name="descripcion"
                      value={state.descripcion}
                      placeholder="Digite una breve dscripción de esta categoria"
                      className="inputtext2 bordecompleto"
                      onChange={onchange}
                    />
                  </div>
                  {dataUser?.roles.code === "1" && (
                    <div className="col-md-12">
                      <div className="d-flex flex-column">
                        <label className=" pt-2 negrita">
                          ¿Esta categoria es para formularios de sistema?
                        </label>
                      </div>
                      <div className="d-flex flex-row">
                        <div className="d-flex flex-row align-items-center">
                          <input
                            type="checkbox"
                            id="tieneFormSist"
                            name="tieneFormSist"
                            value={state.tieneFormSist}
                            className="mx-2 bordecompleto"
                            onChange={onchange}
                          />
                          <label className="py-2">
                            Si, esta categoría es para formularios de sistema
                          </label>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="d-flex justify-content-end flex-row align-self-start pt-4 px-4">
                    <div className="d-flex justify-content-end ">
                      {loading ? (
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <button
                          type=""
                          className="bg-btn-vet bordesombreado"
                          onClick={() => CrearProyectoFn()}
                        >
                          <i className="fas fa-save mx-2"></i>
                          Guardar categoría
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </ModalDefault>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrearProyectos;
