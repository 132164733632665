
import React, { useRef, useState } from 'react';
import './drop-file-input.css';

import { ImageInputFile } from '../../config/ImageInputFile';
import uploadImg from '../../assets/upload.png';

import ModalDefault from '../modalDefault';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import MyCustomMP4Renderer from '../customVideo';

const InputFile = props => {
  const wrapperRef = useRef(null);
  const [showModal, setShowModal] = useState(false);

  const [fileList, setFileList] = useState([]);

  const onDragEnter = () => wrapperRef.current.classList.add('dragover');

  const onDragLeave = () => wrapperRef.current.classList.remove('dragover');

  const onDrop = () => wrapperRef.current.classList.remove('dragover');

  const onFileDrop = (e) => {
    const newFile = e.target.files[0];
    if (newFile) {
      const updatedList = [...fileList, newFile];
      setFileList(updatedList);
      props.onchange(e, updatedList)
    }
  }

  const fileRemove = (file) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    props.onchange(null, updatedList)
  }

  const bytesToMegabytes = (bytes) => {
    const megabytes = bytes / (1024 * 1024);
    return megabytes.toFixed(2);
  }

  return (
    <>
      <div
        ref={wrapperRef}
        className="drop-file-input w-100"
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div className="drop-file-input__label">
          <img src={uploadImg} alt="" />
          <p>Suelte o cargue sus archivos aqui</p>
        </div>
        <input
          type="file"
          name={props.name}
          id={props.id}
          onChange={onFileDrop}
          value=""
        />
      </div>
      {
        fileList.length > 0 ? (
          <div className="drop-file-preview">
            <button onClick={() => {
              setShowModal(true);
            }}
              style={{
                borderRadius: '50px',
                padding: "8px 10px",
                border: "1px solid #e4e6eb",
                boxShadow: "0 4px 6px 0 rgba(0,0,0,.05)",
                backgroundColor: "#e4e6eb",
                marginBottom: "10px",
                fontSize: "12px",
                fontWeight: "600",
              }}
            >
              <i className="fas fa-eye mx-2"></i>
              Vista previa
            </button>
            {
              fileList.map((item, index) => (
                <div key={index} className="drop-file-preview__item">
                  <img src={ImageInputFile[item.type.split('/')[1]] || ImageInputFile['default']} alt="" />
                  <div className="drop-file-preview__item__info">
                    <p>{item.name}</p>
                    <p>{bytesToMegabytes(item.size)}MB</p>
                  </div>
                  <span className="drop-file-preview__item__del" onClick={() => fileRemove(item)}>
                    <i className="fas fa-trash"></i>
                  </span>
                </div>
              ))
            }
          </div>
        ) : null
      }
      <ModalDefault
        bandera={"vista"}
        modal={showModal}
        size={"lg"}
        titulo={"Vista previa de archivos"}
        closeAction={setShowModal}
      >
        <div className="row py-4 justify-content-center text-align-center ">
          <DocViewer
            pluginRenderers={[...DocViewerRenderers, MyCustomMP4Renderer]}
            documents={fileList.map((file) => {
              return {
                uri: URL.createObjectURL(file),
                fileName: file.name,
                fileType: file.name.split('.').pop(),
                key: file.name,
              };
            })}
            style={{
              height: "400px",
            }}
            language="es"
          />

        </div>
      </ModalDefault>
    </>
  );
}

export default InputFile;
