import Axios from "../config/Axios";
export const ApiCreateUser = async (data) => {
  try {
    const result = await Axios.post(`/POSTcreateUser`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiEditUser = async (data) => {
  try {
    const result = await Axios.post(`/POSTeditUser`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const UpdatePassword = async (data) => {
  try {
    const result = await Axios.post(`/POSTUpdatePassword`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetAllUsers = async (data) => {
  try {
    const result = await Axios.get(`/GETallUsers`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiActivarDesactivarUsuario = async (data) => {
  try {
    const result = await Axios.post(`/GETActivarDesactivarUser`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetGruposAccesoUser = async (pre_usuario_id) => {
  try {
    const result = await Axios.get(`/GETallGruposAccesoUser/${pre_usuario_id}`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetInstanciasAccidentesUser = async (pre_usuario_id) => {
  try {
    const result = await Axios.get(
      `/GETInstanciasAccidentesUser/${pre_usuario_id}`
    );
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetFichasxUsuarios = async (data) => {
  try {
    const result = await Axios.post(`/GETFichasxUsuarios`, data);
    return result.data;
  } catch (e) {
    return {
      sms: "err",
      data: [],
      mensaje: e,
    };
  }
};

export const ApiGetFichasxFormulario = async (pre_formulario_id) => {
  try {
    const result = await Axios.get(`/GETFichasxFormulaio/${pre_formulario_id}`);
    return result.data;
  } catch (e) {
    return {
      sms: "err",
      data: [],
      mensaje: e,
    };
  }
};

export const ApiGetUsuariosFichasxFormularioWithDates = async (data) => {
  try {
    const result = await Axios.get(`/GETUsuariosFichasxFormularioWithDates`, {
      params: data,
    });
    return result.data;
  } catch (e) {
    return {
      sms: "err",
      data: [],
      mensaje: e,
    };
  }
};

export const ApiGetUserByCode = async (code) => {
  try {
    const result = await Axios.get(`/GETuserByCode/${code}`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};
