import Axios from "../config/Axios";
export const ApiCrearFicha = async (data) => {
  try {
    /* const formdata = new FormData();
    files.files.forEach((element) => {
      formdata.append("file", element);
    });
    formdata.append("pre_pregunta_id", dataFiles.pre_pregunta_id); */
    const result = await Axios.post(`/POSTcreateFichas`, data);
    return result.data;
  } catch (e) {
    return {
      sms: "err",
      data: [],
      mensaje: e.message,
    };
  }
};

export const ApiCrearRespuestasFiles = async (dataFiles) => {
  try {
    const formdata = new FormData();
    dataFiles.files.forEach((element) => {
      formdata.append("file", element);
    });
    formdata.append("pre_ficha_pregunta_id", dataFiles.pre_ficha_pregunta_id);
    const result = await Axios.post(`/POSTcreateRespuestasFiles`, formdata);
    return result.data;
  } catch (e) {
    return {
      sms: "err",
      data: [],
      mensaje: e.message,
    };
  }
};

export const ApiCrearRespuesta = async (data, ficha) => {
  try {
    const formData = new FormData();
    data.forEach(function (pregunta, i) {
      formData.append(
        "preguntas_files[" + i + "][pre_pregunta_id]",
        pregunta.pre_pregunta_id
      );
      pregunta.files.forEach(function (file) {
        formData.append("preguntas_files[" + i + "][file]", file);
      });
    });

    formData.append("ficha", ficha);

    const result = await Axios.post(`/POSTcreateRespuesta`, formData);
    return result.data;
  } catch (e) {
    return {
      sms: "err",
      data: [],
      mensaje: e.message,
    };
  }
};

export const ApiAgregarBannerFormulario = async (dataFiles) => {
  try {
    const formdata = new FormData();
    dataFiles.files.forEach((element) => {
      formdata.append("file", element);
    });
    formdata.append("pre_formulario_id", dataFiles.pre_formulario_id);

    const result = await Axios.post(`/POSTcreateFormularioBanner`, formdata);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiFichasxFormulario = async (pre_formulario_id) => {
  try {
    const result = await Axios.get(
      `/GETFichasxFormulario/${pre_formulario_id}`
    );
    return result.data;
  } catch (e) {
    return {
      sms: "err",
      data: [],
      mensaje: e,
    };
  }
};

export const ApiFichasxFormularioWithDates = async (data) => {
  try {
    const result = await Axios.get(`/GETFichasxFormularioWithDates`, {
      params: data,
    });
    return result.data;
  } catch (e) {
    return {
      sms: "err",
      data: [],
      mensaje: e,
    };
  }
};

export const ApiTotalFichasxFormularioWithDates = async (data) => {
  try {
    const result = await Axios.get(`/GETTotalFichasxFormularioWithDates`, {
      params: data,
    });
    return result.data;
  } catch (e) {
    return {
      sms: "err",
      data: [],
      mensaje: e,
    };
  }
};

export const ApiPaginadoFichasxFormularioWithDates = async (data) => {
  try {
    const result = await Axios.get(`/GETPaginadoTotalFichasxFormularioWithDates`, {
      params: data,
    });
    return result.data;
  } catch (e) {
    return {
      sms: "err",
      data: [],
      mensaje: e,
    };
  }
};
export const ApiCreatePresignedUrl = async (key) => {
  try {
    const result = await Axios.post(`/POSTcreatePresignedUrl`, {
      key,
    });
    return result.data;
  } catch (e) {
    return {
      sms: "err",
      data: [],
      mensaje: e,
    };
  }
};

export const ApiDeleteFile = async (key) => {
  try {
    const result = await Axios.post(`/POSTdeleteFile`, {
      key,
    });
    return result.data;
  } catch (e) {
    return {
      sms: "err",
      data: [],
      mensaje: e,
    };
  }
};
