import React, { useEffect, useState } from "react";
import CrearAreas from "../../../components/admin/areas/areas";
import ListadoAreas from "../../../components/admin/areas/listadoAreas";

const initialState = {
  pre_area: "",
  pre_cod_area: "",
};
const Proyectos = () => {
  const [updateList, setUpdateList] = useState(false);
  const [type, setType] = useState("save");
  const [state, setState] = useState(initialState);
  useEffect(() => {
    return () => {};
  }, []);

  const updateListFn = () => {
    setUpdateList(!updateList);
  };

  const resetState = () => {
    setState(initialState);
  };
  return (
    <div className="h-100 px-3 bgfondo2 d-flex justify-content-start flex-column ">
      <CrearAreas
        updateListFn={updateListFn}
        state={state}
        setState={setState}
        type={type}
        setType={setType}
        resetState={resetState}
      />
      <ListadoAreas
        updateList={updateList}
        updateListFn={updateListFn}
        setState={setState}
        setType={setType}
      />
    </div>
  );
};

export default Proyectos;
