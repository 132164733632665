import React, { useRef, useEffect, useState } from "react";
import ModalDefault from "../../modalDefault";

import { DataTable } from "mantine-datatable";
import { toast } from "react-hot-toast";
import {
  ApiActivarDesactivarAreas,
  ApiGetAllAreas,
} from "../../../api/areas.js";
import { ActionIcon, Group, TextInput } from "@mantine/core";

const BATCH_SIZE = 12;
const TablaOpciones = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [allAreas, setAllAreas] = useState([]); // Estado para todas las áreas
  const [filteredAreas, setFilteredAreas] = useState([]); // Estado para áreas filtradas
  const [fetching, setfetching] = useState(true);
  const [selectedArea, setSelectedArea] = useState(null);

  // Estados para scroll infinito y filtros
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadedRecords, setLoadedRecords] = useState(BATCH_SIZE);
  const [queryArea, setQueryArea] = useState("");
  const [queryCodigo, setQueryCodigo] = useState("");
  const scrollViewportRef = useRef(null);

  const columns = [
    {
      accessor: "pre_area",
      title: "Area",
      textAlignment: "center",
      filter: (
        <TextInput
          placeholder="Buscar por area..."
          value={queryArea}
          onChange={(e) => setQueryArea(e.currentTarget.value)}
        />
      ),
      filtering: queryArea !== "",
    },
    {
      accessor: "pre_cod_area",
      title: "Código",
      textAlignment: "center",
      filter: (
        <TextInput
          placeholder="Buscar por código..."
          value={queryCodigo}
          onChange={(e) => setQueryCodigo(e.currentTarget.value)}
        />
      ),
      filtering: queryCodigo !== "",
    },
    {
      accessor: "acciones",
      title: "Acciones",
      textAlignment: "center",
      render: (record) => opciones(record),
    },
  ];

  useEffect(() => {
    init();
  }, [props.updateList]);

  const init = async () => {
    setfetching(true);
    const { data } = await ApiGetAllAreas();
    const filterAreas = data.filter((area) => area.estado !== false);
    setAllAreas(filterAreas);
    setFilteredAreas(filterAreas);
    setfetching(false);
  };

  // Logica para filtros
  useEffect(() => {
    const filtered = allAreas.filter(
      (area) =>
        area.pre_area.toLowerCase().includes(queryArea.toLowerCase()) &&
        area.pre_cod_area.toLowerCase().includes(queryCodigo.toLowerCase())
    );
    setFilteredAreas(filtered);
  }, [allAreas, queryArea, queryCodigo]);

  // Carga más datos cuando se llega al final del scroll
  const loadMoreData = () => {
    if (loadedRecords < filteredAreas.length) {
      setLoadingMore(true);
      setTimeout(() => {
        setLoadedRecords(loadedRecords + BATCH_SIZE);
        setLoadingMore(false);
      }, 1000);
    }
  };

  // Asegúrate de no cargar más registros de los disponibles
  const visibleAreas = filteredAreas.slice(
    0,
    Math.min(loadedRecords, filteredAreas.length)
  );

  const editArea = (row) => {
    const { pre_area, pre_cod_area, pre_area_id } = row;
    props.setType("editar");
    props.setState({
      ...props.state,
      pre_area,
      pre_cod_area,
      pre_area_id,
    });
  };

  const onShowModal = (e) => {
    setShowModal(true);
    setSelectedArea(e);
  };

  const onClosedModal = () => {
    setShowModal(false);
    setSelectedArea(null);
  };

  const onDelete = async () => {
    const { estado, pre_area_id } = selectedArea;
    const { sms, mensaje } = await ApiActivarDesactivarAreas({
      pre_area_id,
      pre_estado: !estado,
    });
    if (sms === "ok") {
      toast.success(mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid green",
          padding: "16px",
          color: "white",
          background: "green",
          borderColor: "white",
        },
        iconTheme: {
          primary: "green",
          secondary: "white",
        },
      });
      setSelectedArea(null);
      props.updateListFn();
    } else {
      toast.error(mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    }
    onClosedModal();
  };

  const opciones = (row) => {
    return (
      <Group spacing={4} position="center" noWrap>
        <ActionIcon
          color="blue"
          onClick={() => {
            editArea(row);
          }}
        >
          <i className="fas fa-file-pen"></i>
        </ActionIcon>
        <ActionIcon
          color="red"
          onClick={() => {
            onShowModal(row);
          }}
        >
          <i className="fas fa-trash"></i>
        </ActionIcon>
        <ModalDefault
          bandera={"confirmacion"}
          modal={showModal}
          size={"sm"}
          icontitulo={<null />}
          titulo={`Confirmación`}
          closeAction={onClosedModal}
        >
          <div className="row py-4 justify-content-center text-align-center ">
            Realmente desea eliminar
          </div>
          <div className="d-flex justify-content-end flex-row align-self-start my-4">
            <button
              type=""
              onClick={onClosedModal}
              className="bg-btn-vet-danger sombra mx-1"
            >
              Cancelar
            </button>
            <button
              type=""
              onClick={onDelete}
              className="bg-btn-vet sombra mx-1"
            >
              <i className="fas fa-save"> Eliminar</i>
            </button>
          </div>
        </ModalDefault>
      </Group>
    );
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <DataTable
          borderRadius="md"
          className="bordecompleto bgfondo "
          highlightOnHover
          noRecordsText="No se encontraron datos"
          columns={columns}
          totalRecords={allAreas.length}
          withColumnBorders
          records={visibleAreas}
          fetching={fetching || loadingMore}
          onScrollToBottom={loadMoreData}
          scrollViewportRef={scrollViewportRef}
          style={{ maxHeight: "430px", overflowY: "auto" }}
        />
      </div>
    </div>
  );
};

export default TablaOpciones;
