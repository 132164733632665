import React, { useEffect, useState } from 'react'
import { Title, Text, Button, Badge, Card, Group, Image, Table } from "@mantine/core";
import { ApiGetAllDocumentos } from "../../api/documentos";
import { ApiCrearSolicitud, ApiGetSolcitudesUsuario, ApiCancelarSolicitud } from '../../api/solicitudes';
import { toast } from "react-hot-toast";
import { ApiDescargaFile } from '../../api/formularios';

const Documentos = () => {

  const user = JSON.parse(localStorage.getItem("datauser"));

  const statuds = ["Pendiente", "Aprobado", "Rechazado", "Cancelado"];

  const [loading, setLoading] = useState(false);
  const [documentos, setDocumentos] = useState([]);
  const [documento, setDocumento] = useState(null);
  const [loadingSolicitud, setLoadingSolicitud] = useState(false);
  const [solicitudes, setSolicitudes] = useState([]);

  const [pageMisSolicitudes, setPageMisSolicitudes] = useState(false);

  useEffect(() => {
    init()
  }, []);

  const init = async () => {
    setLoading(true);
    const res = await ApiGetAllDocumentos();
    if (res === "err") {
      toast.error(res.mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    }
    setDocumentos(res.data);

    const res2 = await ApiGetSolcitudesUsuario(user.pre_usuario_id);
    if (res2 === "err") {
      toast.error(res2.mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    }

    setSolicitudes(res2.data);
    setLoading(false);
  }

  const solicitarDocumento = async () => {
    if (!documento) {
      toast.error("Seleccione un documento", {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
      return;
    }
    setLoadingSolicitud(true);
    const data = {
      documento_id: documento.documento_id,
      usuario_id: user.pre_usuario_id,
    }
    const res = await ApiCrearSolicitud(data);
    if (res.sms === "err") {
      toast.error(res.mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    } else {
      toast.success(res.mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid green",
          padding: "16px",
          color: "white",
          background: "green",
          borderColor: "white",
        },
        iconTheme: {
          primary: "green",
          secondary: "white",
        },
      });
    }
    setLoadingSolicitud(false);
    setDocumento(null);
    init();
  }

  const handlerPageMisSolicitudes = () => {
    setPageMisSolicitudes(!pageMisSolicitudes);
  }

  const cancelarSolicitud = async (solicitud) => {
    const data = {
      solicitud_id: solicitud.solicitud_id,
    }
    const res = await ApiCancelarSolicitud(data);
    if (res.sms === "err") {
      toast.error(res.mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    } else {
      toast.success(res.mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid green",
          padding: "16px",
          color: "white",
          background: "green",
          borderColor: "white",
        },
        iconTheme: {
          primary: "green",
          secondary: "white",
        },
      });
    }
    init();
  }

  const onDowload = async (file) => {
    const { sms, url } = await ApiDescargaFile({ file: file });
    if (sms === "ok") {
      window.open(url, "_blank");
    }
  };

  return (
    <div
      style={{
        maxWidth: "900px",
        margin: "0 auto",
        padding: "20px 25px",
      }}
    >
      <Title order={2} ml={5} >
        Documentos
      </Title>
      {!pageMisSolicitudes ? (
        <div className="bg-white shadow px-5 rounded mt-3 d-flex flex-column justify-content-between"
          style={{
            minHeight: "460px",
          }}
        >
          <div>
            <DateHourSecondCounter />
            <Title order={4} >
              Hola {user?.nombres}, aqui puedes solicitar documentos/certificados.
            </Title>
            <div className="d-flex mt-4">
              {loading ? (
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : documentos.map((doc, index) => {
                return (
                  <Card shadow="sm" padding="lg" radius="md" withBorder mr={20}
                    style={{
                      cursor: "pointer",
                      border: documento?.documento_id === doc.documento_id ? "3px solid #6991cc" : "3px solid #eee",
                      transition: "all 0.3s ease",
                    }}
                    onClick={() => {
                      setDocumento(doc);
                    }}
                    key={index}
                  >
                    <Card.Section>
                      <Image
                        src="https://img.freepik.com/vector-premium/icono-documento-contrato-estilo-plano-ilustracion-vector-sello-carpeta-informe-sobre-fondo-aislado-concepto-negocio-signo-hoja-papel_157943-703.jpg"
                        height={160}
                        alt="Documento"
                      />
                    </Card.Section>

                    <Group position="apart" mt="md" mb="xs">
                      <Text weight={500}>{doc.nombre}</Text>
                    </Group>
                  </Card>
                )
              })}
            </div>
          </div>
          <div className='d-flex mt-5 mb-5 justify-content-between'>
            <Button
              leftIcon={<i className="fas fa-file-alt" style={{ fontSize: "1.3rem" }} />}
              size="xl"
              variant="default"
              onClick={() => { handlerPageMisSolicitudes() }}
              style={{
                borderColor: "#6991cc",
                marginRight: "20px"
              }}
            >
              <span style={{ fontSize: "1.3rem" }}>Mis Solicitudes</span>
            </Button>
            <Button
              size="xl"
              variant="default"
              leftIcon={<i className="fas fa-paper-plane" style={{ fontSize: "1.3rem" }} />}
              loading={loadingSolicitud}
              onClick={() => {
                solicitarDocumento();
              }}
              style={{
                borderColor: "#6991cc",
              }}
            >
              <span style={{ fontSize: "1.3rem" }}>Solicitar</span>
            </Button>
          </div>
        </div>
      ) : (
        <div className="bg-white shadow px-5 rounded mt-3 d-flex flex-column justify-content-between"
          style={{
            minHeight: "460px",
          }}
        >
          <div>
            <DateHourSecondCounter />
            <Title order={4} >
              Aqui puedes ver el estado de tus solicitudes.
            </Title>
            <div className="d-flex mt-4">

              <Table striped highlightOnHover withBorder withColumnBorders >
                <thead>
                  <tr>
                    <th>Documento</th>
                    <th>Archivo</th>
                    <th>Estado</th>
                    <th>Fecha y hora</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {solicitudes.map((sol, index) => {
                    return (
                      <tr key={index}>
                        <td>{sol.documentos.nombre}</td>
                        <td>{sol.file ? (<Button size="sm" variant="outline" color="teal" onClick={() => {
                          onDowload(sol.file)
                        }}>Descargar</Button>) : "Sin contenido"}</td>
                        <td>{
                          <Badge color={sol.statud === "Pendiente" ? "orange" : sol.statud === "Aprobado" ? "green" : sol.statud === "Rechazado" ? "red" : "gray"} variant="filled">
                            {sol.statud}
                          </Badge>
                        }</td>
                        <td>{
                          new Date(sol.create_at).toLocaleDateString() + " " + new Date(sol.create_at).toLocaleTimeString()
                        }</td>
                        <td>
                          <Button
                            disabled={sol.statud !== "Pendiente" ? true : false}
                            compact
                            variant="outline"
                            color="red"
                            onClick={() => {
                              cancelarSolicitud(sol)
                            }}
                          >
                            Cancelar
                          </Button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          </div>
          <div className='d-flex mt-5 mb-5 justify-content-end'>
            <Button
              leftIcon={<i className="fas fa-arrow-left" style={{ fontSize: "1.3rem" }} />}
              size="xl"
              variant="default"
              onClick={() => { handlerPageMisSolicitudes() }}
              style={{
                borderColor: "#6991cc",
                marginRight: "20px"
              }}
            >
              <span style={{ fontSize: "1.3rem" }}>Volver</span>
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

const DateHourSecondCounter = () => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(new Date());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="d-flex pt-4 pb-3">
      <Badge color="teal" variant="filled" style={{ fontSize: "1.2rem" }} mr={5}>
        {date.toLocaleDateString()}
      </Badge>
      <Badge color="teal" variant="filled" style={{ fontSize: "1.2rem" }}>
        {date.toLocaleTimeString()}
      </Badge>
    </div>
  );
};

export default Documentos