import React, { useEffect, useState } from "react";
import CrearDepartamentos from "../../../components/admin/departamentos/departamentos";
import { ApiGetAllAreas } from "../../../api/areas";
import ListadoDepartamentos from "../../../components/admin/departamentos/listadoDepartamentos";

const initialState = {
  pre_area_id: "",
  pre_cod_departamento: "",
  pre_departamento: "",
};

const Departamentos = () => {
  const [updateList, setUpdateList] = useState(false);
  const [type, setType] = useState("save");
  const [state, setState] = useState(initialState);
  const [listadoAreas, setListadoAreas] = useState([]);

  useEffect(() => {
    const fetchAreas = async () => {
      const response = await ApiGetAllAreas();
      if (response.sms === "ok") {
        const areasActivas = response.data.filter(
          (area) => area.estado === true
        );
        setListadoAreas(areasActivas);
      } else {
        console.log(response);
      }
    };

    fetchAreas();
  }, [updateList]);

  const updateListFn = () => {
    setUpdateList(!updateList);
  };

  const resetState = () => {
    setState(initialState);
  };

  return (
    <div className="h-100 px-3 bgfondo2 d-flex justify-content-start flex-column ">
      <CrearDepartamentos
        updateListFn={updateListFn}
        state={state}
        setState={setState}
        type={type}
        setType={setType}
        resetState={resetState}
        listadoAreas={listadoAreas}
      />
      <ListadoDepartamentos
        updateList={updateList}
        updateListFn={updateListFn}
        setState={setState}
        setType={setType}
      />
    </div>
  );
};

export default Departamentos;
