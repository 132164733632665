import Axios from "../config/Axios";
export const ApiCrearPregunta = async (data) => {
  try {
    const result = await Axios.post(`/POSTcreatePregunta`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiObligatorioObservacion = async (data) => {
  try {
    const result = await Axios.post(`/POSTUpdateObservacionObligatorio`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiEditarPregunta = async (data) => {
  try {
    const result = await Axios.post(`/POSTeditarPregunta`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiOrdenamiento = async (data) => {
  try {
    const result = await Axios.post(`/POSTUpdateQuestionOrdering`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetAllPreguntas = async (pre_formulario_id) => {
  try {
    const result = await Axios.get(`/GETallPreguntasFormulario/${pre_formulario_id}`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetDataPreguntas = async (pre_pregunta_id) => {
  try {
    const result = await Axios.get(`/GETdataPregunta/${pre_pregunta_id}`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApitActivarDesactivarPregunta = async (data) => {
  try {
    const result = await Axios.post(
      `/PostActivarDesactivarPregunta`,data
    );
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};