import Axios from "../config/Axios";

export const ApiCrearFormularioCompartido = async (data) => {
  try {
    const result = await Axios.post(`/POSTcreateFormularioCompartido`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiObtenerAccesoCompartidos = async (pre_formulario_id) => {
  try {
    const result = await Axios.get(
      `/GETaccesosCompartidos/${pre_formulario_id}`
    );
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};
