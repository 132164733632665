import { Title, Text, Button, Container } from "@mantine/core";
import { Dots } from "./Dots";

const HomeDocumentos = ({ handlePageChange, loading }) => {
  return (
    <>
      <Dots
        style={{ left: 0, top: 0, position: "absolute", color: "#c6cfdd" }}
      />
      <Dots
        style={{ left: 60, top: 0, position: "absolute", color: "#c6cfdd" }}
      />
      <Dots
        style={{ left: 0, top: 140, position: "absolute", color: "#c6cfdd" }}
      />
      <Dots
        style={{ right: 0, top: 60, position: "absolute", color: "#c6cfdd" }}
      />
      <div
        className="position-relative z-index-1 d-flex flex-column justify-content-center align-items-center bg"
        style={{ height: "400px" }}
      >
        <Title align="center" mb={2} style={{ fontSize: "xx-large" }}>
          Solicita tus documentos/certificados
        </Title>
        <Container p={0} size={600}>
          <Text size="lg" c="dimmed" align="center">
            Solicita tus documentos/certificados de Conservas Isabel de manera
            rápida y sencilla.
          </Text>
        </Container>
        <div className="d-flex justify-content-center mt-5">
          <Button
            loading={loading}
            size="xl"
            style={{
              backgroundColor: "#0446aa",
              color: "#fff",
            }}
            onClick={() => { handlePageChange(2) }}
          >
            <span style={{ fontSize: "1.5rem" }}>Solicitar</span>
          </Button>
        </div>
      </div>
    </>
  )
}

export default HomeDocumentos