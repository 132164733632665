import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Collapse } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ApiCrearFormulario } from "../../../api/formularios";
import {
  ApiActivarDesactivarProyecto,
  ApiEditarProyectos,
  ApiPublicarDesPublicarProyecto,
} from "../../../api/proyectos";
import ModalDefault from "../../modalDefault";
import { ItemFormulario } from "./ItemFormulario";

const animatedComponents = makeAnimated();

const CardProyecto = ({
  proyecto,
  updateListFn,
  classx,
  updateFnInit,
  isExternal,
  areas,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalDeleted, setModalDeleted] = useState(false);
  const [nameFormulario, setNameFormulario] = useState("");
  const [modalActivar, setModalActivar] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [proyectoUpdate, setProyectoUpdate] = useState(proyecto);
  const [loading, setlLoading] = useState(false);
  const [defaultAreas, setDefaultAreas] = useState([]);

  const dataUser = JSON.parse(localStorage.getItem("datauser"));

  const [opened, { toggle }] = useDisclosure(false);

  useEffect(() => {
    setProyectoUpdate(proyecto);
    if (proyecto.tipo_proyecto_code === "interno") {
      const nuevasAreasDefault = proyecto.areas_asignadas
        .map((asignada) => {
          return areas.find((area) => area.value === asignada);
        })
        .filter((area) => area !== undefined);

      setDefaultAreas(nuevasAreasDefault);
    }
  }, [proyecto, areas]);

  const [isMovil, setIsMovil] = useState(false);
  const [inerwidth, setInerwidth] = useState(window.innerWidth);

  const handleResize = () => {
    setInerwidth(window.innerWidth);
    if (window.innerWidth <= 667) {
      setIsMovil(true);
    } else {
      setIsMovil(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const {
    pre_proyecto_id,
    descripcion,
    estado,
    estado_publicacion,
    pre_usuario_id,
    tieneFormSist,
  } = proyecto;

  const onDeleted = async () => {
    const { sms, mensaje } = await ApiActivarDesactivarProyecto({
      pre_proyecto_id,
      pre_estado: !estado,
    });
    if (sms === "ok") {
      toast.success(mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid green",
          padding: "16px",
          color: "white",
          background: "green",
          borderColor: "white",
        },
        iconTheme: {
          primary: "green",
          secondary: "white",
        },
      });
      updateListFn();
    } else {
      toast.error(mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    }
    onCloseModalDeleted();
  };

  const onActivarDesactivar = async () => {
    const { sms, mensaje } = await ApiPublicarDesPublicarProyecto({
      pre_proyecto_id,
      estado_publicacion: !estado_publicacion,
    });
    if (sms === "ok") {
      toast.success(mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid green",
          padding: "16px",
          color: "white",
          background: "green",
          borderColor: "white",
        },
        iconTheme: {
          primary: "green",
          secondary: "white",
        },
      });
      updateListFn();
    } else {
      toast.error(mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    }
    onCloseModalDesactivacion();
  };

  const UpdateProyectoFn = async () => {
    setlLoading(true);
    if (proyectoUpdate.proyecto === "") {
      toast.error("El nombre del proyecto se encuentra vacío", {
        position: "bottom-center",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
      setlLoading(false);
      return;
    }

    if (
      proyectoUpdate.areas_asignadas.length === 0 &&
      proyectoUpdate.tipo_proyecto_code === "interno"
    ) {
      toast.error(
        "Si es privado debe asignar al menos un área a la categoria",
        {
          position: "bottom-center",
          style: {
            border: "1px solid red",
            padding: "16px",
            color: "white",
            background: "red",
            borderColor: "white",
          },
          iconTheme: {
            primary: "red",
            secondary: "white",
          },
        }
      );
      setlLoading(false);
      return;
    }

    const res = await ApiEditarProyectos(proyectoUpdate);
    const datares = res;
    if (datares.sms === "ok") {
      toast.success(datares.mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid green",
          padding: "16px",
          color: "white",
          background: "green",
          borderColor: "white",
        },
        iconTheme: {
          primary: "green",
          secondary: "white",
        },
      });
      updateListFn();
      onCloseModalEdit();
    } else {
      toast.error(datares.mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    }
    setlLoading(false);
    onCloseModalEdit();
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  const onShowModal = () => {
    setShowModal(true);
  };

  const onCloseModalDeleted = () => {
    setModalDeleted(false);
  };

  const onCloseModalDesactivacion = () => {
    setModalActivar(false);
  };
  const onShowModalDesactivacion = () => {
    setModalActivar(true);
  };

  const onShowModalDeleted = () => {
    setModalDeleted(true);
  };

  const onShowModalEdit = () => {
    setModalEdit(true);
  };

  const onCloseModalEdit = () => {
    setModalEdit(false);
    setProyectoUpdate(proyecto);
  };

  const onchange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setProyectoUpdate({
      ...proyectoUpdate,
      [e.target.name]: value,
    });
  };

  const handleSaveForm = async () => {
    if (nameFormulario === "") {
      toast.error("El nombre del formulario se encuentra vacío", {
        position: "bottom-center",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
      return;
    }

    const { sms, mensaje } = await ApiCrearFormulario({
      formulario: nameFormulario,
      pre_proyecto_id,
      pre_usuario_id: pre_usuario_id,
    });

    if (sms === "ok") {
      toast.success(mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid green",
          padding: "16px",
          color: "white",
          background: "green",
          borderColor: "white",
        },
        iconTheme: {
          primary: "green",
          secondary: "white",
        },
      });
      updateListados();
    } else {
      toast.error(mensaje, {
        position: "bottom-left",
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "white",
          background: "red",
          borderColor: "white",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    }
    onCloseModal();
  };

  const updateListados = () => {
    updateFnInit();
  };
  return (
    <div className="d-flex justify-content-center">
      <div
        className={` border-0 d-flex  my-3 flex-column w-100 ${classx} bordesombreado`}
        style={{
          height: "auto",
          paddingRight: "10px",
          paddingLeft: isExternal ? "10px" : "0px",
        }}
      >
        <div
          style={
            !isExternal
              ? {
                  borderLeft: `10px solid ${
                    proyecto.estado_publicacion ? "#6FA06F" : "#dc3545"
                  }`,
                  paddingLeft: "5px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }
              : {
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }
          }
        >
          <div className=" d-flex  align-items-start justify-content-between">
            <div className="d-flex flex-column w-100 ">
              <div className="d-flex flex-row  mx-1 justify-content-start align-items-center">
                <label htmlFor="" className="bg-btn-small-circle">
                  <i className="fas fa-list"></i>
                </label>
                <label className=" negrita  card-title m-2">
                  Categoría: {proyecto.proyecto}
                </label>
              </div>
              <p className="negrita m-2">{descripcion}</p>
            </div>

            <div className="d-flex justify-content-between flex-row align-self-center align-items-center">
              <label htmlFor="" className="bg-btn-small-circle">
                {proyecto.tipo_proyecto_code === "interno" ? (
                  <i className="fas fa-lock"></i>
                ) : (
                  <i className="fas fa-globe"></i>
                )}
              </label>
              <button
                htmlFor=""
                className="bg-btn-small-circle bordesombreado mx-2"
                onClick={() => toggle()}
              >
                {opened ? (
                  <i className="fas fa-chevron-up" />
                ) : (
                  <i className="fas fa-chevron-down" />
                )}
              </button>
              {!isExternal ? (
                isMovil ? (
                  <>
                    <div className="dropdown">
                      <button
                        className="bg-btn-small-circle bordesombreado"
                        type="button"
                        id="menu-proyecto"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          marginRight: "10px",
                        }}
                      >
                        <i className="fas fa-ellipsis-vertical mx-1"></i>
                      </button>
                      <ul
                        className="dropdown-menu p-0 m-0"
                        aria-labelledby="menu-proyecto"
                        style={{ width: "200px" }}
                      >
                        <li>
                          <button
                            type=""
                            onClick={onShowModal}
                            className="  w-100 d-flex justify-content-start p-2 align-items-center"
                          >
                            <label htmlFor="" className="">
                              <i className="fas fa-add"></i>
                            </label>
                            <label htmlFor="" className="mx-1">
                              Nuevo
                            </label>
                          </button>
                        </li>
                        {estado_publicacion ? (
                          <li>
                            <button
                              type=""
                              onClick={
                                /* publicarQuitar(proyecto.pre_proyecto_id, false) */
                                onShowModalDesactivacion
                              }
                              className="  w-100 d-flex justify-content-start p-2 align-items-center"
                            >
                              <label htmlFor="" className="">
                                {" "}
                                <i className="fas fa-close"></i>
                              </label>
                              <label htmlFor="" className="mx-1">
                                Ocultar
                              </label>
                            </button>
                          </li>
                        ) : (
                          <li>
                            <button
                              type=""
                              onClick={onShowModalDesactivacion}
                              className="  w-100 d-flex justify-content-start p-2 align-items-center"
                            >
                              <label htmlFor="" className="">
                                <i className="fas fa-check"></i>
                              </label>
                              <label htmlFor="" className="mx-1">
                                Publicar
                              </label>
                            </button>
                          </li>
                        )}
                        <li>
                          <button
                            type=""
                            onClick={onShowModalEdit}
                            className="  w-100 d-flex justify-content-start p-2 align-items-center"
                          >
                            <label htmlFor="" className="">
                              {" "}
                              <i className="fas fa-pencil"></i>
                            </label>
                            <label htmlFor="" className="mx-1">
                              Editar
                            </label>
                          </button>
                        </li>
                        <li>
                          <button
                            type=""
                            onClick={onShowModalDeleted}
                            className="  w-100 d-flex justify-content-start p-2 align-items-center"
                          >
                            <label htmlFor="" className="">
                              {" "}
                              <i className="fas fa-trash"></i>
                            </label>
                            <label htmlFor="" className="mx-1">
                              Eliminar
                            </label>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : (
                  <>
                    <button
                      type=""
                      onClick={onShowModal}
                      className="  d-flex justify-content-start align-items-center bg-btn-vet bordecompleto"
                    >
                      <label htmlFor="" className="">
                        <i className="fas fa-add"></i>
                      </label>
                      <label htmlFor="" className="mx-1">
                        Nuevo
                      </label>
                    </button>
                    {estado_publicacion ? (
                      <button
                        type=""
                        onClick={
                          /* publicarQuitar(proyecto.pre_proyecto_id, false) */
                          onShowModalDesactivacion
                        }
                        className=" w-auto d-flex justify-content-start align-items-center mx-2 bg-btn-vet bordecompleto"
                      >
                        <label htmlFor="" className="">
                          {" "}
                          <i className="fas fa-close"></i>
                        </label>
                        <label htmlFor="" className="mx-1">
                          Ocultar
                        </label>
                      </button>
                    ) : (
                      <button
                        type=""
                        onClick={onShowModalDesactivacion}
                        className=" w-auto d-flex justify-content-start align-items-center mx-2 bg-btn-vet bordecompleto"
                      >
                        <label htmlFor="" className="">
                          <i className="fas fa-check"></i>
                        </label>
                        <label htmlFor="" className="mx-1">
                          Publicar
                        </label>
                      </button>
                    )}
                    <button
                      type=""
                      onClick={onShowModalEdit}
                      className="w-100 bg-btn-vet bordecompleto "
                    >
                      <label htmlFor="" className="">
                        {" "}
                        <i className="fas fa-pencil"></i>
                      </label>
                    </button>
                    <button
                      type=""
                      onClick={onShowModalDeleted}
                      className=" w-100 bg-btn-vet-danger mx-2 bordecompleto"
                    >
                      <label htmlFor="" className="">
                        {" "}
                        <i className="fas fa-trash"></i>
                      </label>
                    </button>
                  </>
                )
              ) : (
                ""
              )}
            </div>
          </div>
          <ModalDefault
            bandera={"confirmacion"}
            modal={modalDeleted}
            size={"md"}
            icontitulo={<null />}
            titulo={`Confirmación`}
            closeAction={onCloseModalDeleted}
          >
            <div className="row py-4 justify-content-center text-align-center ">
              Realmente desea eliminar el Proyecto
            </div>
            <div className="d-flex justify-content-end flex-row align-self-start my-4">
              <button
                type=""
                onClick={onCloseModalDeleted}
                className="bg-btn-vet-danger sombra mx-1"
              >
                Cancelar
              </button>
              <button
                type=""
                onClick={onDeleted}
                className="bg-btn-vet sombra mx-1"
              >
                <i className="fas fa-save"> Eliminar</i>
              </button>
            </div>
          </ModalDefault>
        </div>
        <Collapse in={opened}>
          <div className={`py-0 `}>
            <hr />
            <div
              className="card-body row p-0 m-0  "
              style={{ overflowY: "none", height: "auto" }}
            >
              {proyecto.pre_formularios.length > 0 ? (
                isExternal ? (
                  proyecto.pre_formularios.map((formulario, i) => {
                    return formulario.estado_publicacion ? (
                      <ItemFormulario
                        formularioData={formulario}
                        key={i}
                        updateListFn={updateListados}
                        isExternal={isExternal}
                        tieneFormSist={tieneFormSist}
                      />
                    ) : null;
                  })
                ) : (
                  proyecto.pre_formularios.map((formulario, i) => {
                    return (
                      <ItemFormulario
                        formularioData={formulario}
                        key={i}
                        updateListFn={updateListados}
                        isExternal={isExternal}
                      />
                    );
                  })
                )
              ) : (
                <div className="w-100 d-flex justify-content-center align-items-center py-4">
                  <h5 style={{ fontWeight: "bold", opacity: "0.3" }}>
                    No se existen formularios para esta categoría
                  </h5>
                </div>
              )}
            </div>
          </div>
        </Collapse>
      </div>
      <ModalDefault
        bandera={"confirmacion"}
        modal={showModal}
        size={"md"}
        icontitulo={<null />}
        titulo={`Nuevo Formulario`}
        closeAction={onCloseModal}
      >
        <div className="row py-4 ">
          <div className="col-md-12">
            <label className="py-2 negrita">Nombre del Formulario</label>
            <input
              type="text"
              id="formulario"
              name="formulario"
              className="inputtext2 bordecompleto"
              placeholder="Digite el nombre del formulario"
              value={nameFormulario}
              onChange={(e) => setNameFormulario(e.target.value)}
            />
          </div>
          <div className="d-flex justify-content-end flex-row align-self-start my-4">
            <button
              type=""
              onClick={onCloseModal}
              className="bg-btn-vet-danger sombra mx-1"
            >
              Cancelar
            </button>
            <button
              type=""
              onClick={handleSaveForm}
              className="bg-btn-vet sombra mx-1"
            >
              <i className="fas fa-save"> Guardar</i>
            </button>
          </div>
        </div>
      </ModalDefault>
      <ModalDefault
        bandera={"confirmacion"}
        modal={modalDeleted}
        size={"md"}
        icontitulo={<null />}
        titulo={`Confirmación`}
        closeAction={onCloseModalDeleted}
      >
        <div className="row py-4 justify-content-center text-align-center ">
          Se eliminará el proyecto
        </div>
        <div className="d-flex justify-content-end flex-row align-self-start my-4">
          <button
            type=""
            onClick={onCloseModalDeleted}
            className="bg-btn-vet-danger sombra mx-1"
          >
            Cancelar
          </button>
          <button
            type=""
            onClick={onDeleted}
            className="bg-btn-vet sombra mx-1"
          >
            <i className="fas fa-save"> Eliminar</i>
          </button>
        </div>
      </ModalDefault>
      <ModalDefault
        bandera={"confirmacion"}
        modal={modalActivar}
        size={"md"}
        icontitulo={<null />}
        titulo={`Confirmación`}
        closeAction={onCloseModalDesactivacion}
      >
        <div className="row py-4 justify-content-center text-align-center ">
          {estado_publicacion ? (
            <h4>
              Se desactivará la publicación del proyecto y no estará disponible
              para los usuarios
            </h4>
          ) : (
            <h4>
              Se activará la publicación del proyecto y estará disponible para
              los usuarios
            </h4>
          )}
        </div>
        <div className="d-flex justify-content-between   flex-row align-self-start my-4">
          <button
            type=""
            onClick={onCloseModalDesactivacion}
            className="bg-btn-vet-danger sombra mx-1 w-100"
          >
            Cancelar
          </button>
          <button
            type=""
            onClick={onActivarDesactivar}
            className="bg-btn-vet sombra mx-1 w-100"
          >
            <i className="fas fa-save"></i>
            <label htmlFor="">
              {estado_publicacion ? "Desactivar" : "Activar"}
            </label>
          </button>
        </div>
      </ModalDefault>
      <ModalDefault
        bandera={"Editar categoría"}
        modal={modalEdit}
        size={"lg"}
        icontitulo={<null />}
        titulo={`Editar categoría`}
        closeAction={onCloseModalEdit}
      >
        <div className="row py-4 ">
          <div className="col-md-6">
            <label className="py-2 negrita">Nombre de la categoria </label>
            <input
              type="text"
              id="proyecto"
              name="proyecto"
              value={proyectoUpdate.proyecto}
              className="inputtext2 bordecompleto"
              placeholder="Digite el nombre de la categoría del proyecto"
              onChange={onchange}
            />
          </div>
          <div className="col-md-6 ">
            <div className=" row">
              <label className="x-0 py-2 negrita">
                Tipo de acceso para está categoría :
              </label>

              <div className={"col-md-12 p-0 m-0"}>
                <select
                  className="inputselect2 bordecompleto"
                  id="tipo_proyecto_code"
                  name="tipo_proyecto_code"
                  style={{ width: "100%" }}
                  onChange={onchange}
                  value={proyectoUpdate.tipo_proyecto_code}
                >
                  <option value="">Seleccione tipo de proyecto...</option>
                  <option value="externo">Categoría pública</option>
                  <option value="interno">Categoría privada</option>
                </select>
              </div>
            </div>
          </div>
          {proyectoUpdate.tipo_proyecto_code === "interno" && (
            <div className="col-md-12">
              <div className="d-flex flex-column">
                <label className=" py-2 negrita">
                  Areas que podran acceder a esta categoria
                </label>
              </div>
              <Select
                isMulti
                name="areas_asignadas"
                options={areas}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Seleccione las areas que podran acceder a esta categoria"
                closeMenuOnSelect={false}
                components={animatedComponents}
                defaultValue={defaultAreas}
                onChange={(e) => {
                  setProyectoUpdate({
                    ...proyectoUpdate,
                    areas_asignadas: e.map((item) => item.value),
                  });
                }}
              />
            </div>
          )}
          <div className="col-md-12">
            <div className="d-flex flex-column">
              <label className=" py-2 negrita">
                Descripción del de la categoria
              </label>
              <p style={{ color: "blue" }}>
                (Ingrese una descripción para informar al usuario sobre el uso
                de los formularios de esta categoría.)
              </p>
            </div>
            <input
              type="text"
              id="descripcion"
              name="descripcion"
              value={proyectoUpdate.descripcion}
              placeholder="Digite una breve dscripción de esta categoria"
              className="inputtext2 bordecompleto"
              onChange={onchange}
            />
          </div>
          {dataUser?.roles.code === "1" && (
            <div className="col-md-12">
              <div className="d-flex flex-column">
                <label className=" pt-2 negrita">
                  ¿Esta categoria es para formularios de sistema?
                </label>
              </div>
              <div className="d-flex flex-row">
                <div className="d-flex flex-row align-items-center">
                  <input
                    type="checkbox"
                    id="tieneFormSist"
                    name="tieneFormSist"
                    checked={proyectoUpdate.tieneFormSist}
                    className="mx-2 bordecompleto"
                    onChange={onchange}
                  />
                  <label className="py-2">
                    Si, esta categoría es para formularios de sistema
                  </label>
                </div>
              </div>
            </div>
          )}

          <div className="d-flex justify-content-end flex-row align-self-start pt-4 px-4">
            <div className="d-flex justify-content-end ">
              {loading ? (
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <button
                  type=""
                  className="bg-btn-vet bordesombreado"
                  onClick={() => UpdateProyectoFn()}
                >
                  <i className="fas fa-save mx-2"></i>
                  Actualizar categoría
                </button>
              )}
            </div>
          </div>
        </div>
      </ModalDefault>
    </div>
  );
};

export default CardProyecto;
