import { useEffect, useState } from "react";
import ModalDefault from "../../modalDefault";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ApiCrearInstanciaIncedente } from "../../../api/instanciaAccidentes";

export const ItemAsignacion = ({ idGrupo, formulario, isExternal }) => {
  const codigo = formulario.pre_formulario.pre_asignacion_formularios[0].codigo;
  const [ReportModal, setReportModal] = useState([]);
  const defaultReq = {
    grupo_acceso_usuario_id: idGrupo,
    pre_asignacion_formulario_cod: codigo,
    cedula_accidentado: "",
    nombres_accidentado: "",
    fecha_accidente: "",
  };
  const [req, setReq] = useState(defaultReq);
  const navigate = useNavigate();

  const [showBtnReportar, setShowBtnReportar] = useState(false);

  useEffect(() => {
    init();
    return () => {};
  }, []);

  const init = async () => {
    const mostrarBtnReportar =
      !formulario.orden_obligatorio || formulario.orden <= 1;
    setShowBtnReportar(mostrarBtnReportar);
  };

  const onChange = (e) => {
    setReq({
      ...req,
      [e.target.name]: e.target.value,
    });
  };

  const formatearFecha = (fecha) => {
    const partesFecha = fecha.split("-");
    return partesFecha[2] + "/" + partesFecha[1] + "/" + partesFecha[0];
  };

  const enviarFormulario = async () => {
    /**
    if (!req.cedula_accidentado || req.cedula_accidentado.trim() === "") {
      toast.error("Por favor, ingrese la cédula del accidentado.");
      return;
    }
    if (!req.nombres_accidentado || req.nombres_accidentado.trim() === "") {
      toast.error("Por favor, ingrese los nombres del accidentado.");
      return;
    }
    */
    if (!req.fecha_accidente || req.fecha_accidente.trim() === "") {
      toast.error("Por favor, ingrese la fecha del accidente.");
      return;
    }

    let reqConFechaFormateada = {
      ...req,
      fecha_accidente: req.fecha_accidente
        ? formatearFecha(req.fecha_accidente)
        : "",
    };

    const data = await apiSendForm(reqConFechaFormateada);
    reqConFechaFormateada = {
      ...reqConFechaFormateada,
      data: data,
    };

    // Si todo es válido, procede a guardar y redirigir
    localStorage.setItem("req", JSON.stringify(reqConFechaFormateada));
    navigate(ReportModal[1]);
    setReportModal([!ReportModal[0], ReportModal[1]]);
    setReq(defaultReq);
  };

  const apiSendForm = async (data) => {
    const { data: res, sms, mensaje } = await ApiCrearInstanciaIncedente(data);
    if (sms === "ok") {
      toast.success(mensaje);
      return res;
    } else {
      toast.error(mensaje);
      return {};
    }
  };

  const toggleReportModal = (url) => {
    setReportModal([!ReportModal[0], url]);
  };

  return (
    <div className="d-flex flex-row my-2">
      {showBtnReportar && (
        <button
          type="button"
          className="bg-btn-vet-warning bordesombreado mx-2"
          onClick={() =>
            toggleReportModal(
              `/proyectos/formulario/${formulario.pre_formulario.pre_formulario_id}/${isExternal}`
            )
          }
        >
          <i className="fas fa-flag"></i> {formulario.pre_proyecto.proyecto} -{" "}
          {formulario.pre_formulario.formulario}
        </button>
      )}
      <ModalDefault
        bandera={`reportar`}
        modal={ReportModal[0]}
        size={"sm"}
        icontitulo={<null />}
        titulo={formulario.pre_formulario.formulario}
        closeAction={() => {
          toggleReportModal("");
          setReq(defaultReq);
        }}
      >
        <div className="form-container p-3">
          <div className="col-md-12">
            <label className="py-2 negrita">Fecha del Accidente</label>
            <input
              type="date"
              name="fecha_accidente"
              id="fecha_accidente"
              className="inputtext2 bordecompleto"
              value={req.fecha_accidente}
              onChange={onChange}
            />
          </div>
          <div className="col-md-12">
            <label className="py-2 negrita">Cédula del Accidentado</label>
            <input
              type="text"
              name="cedula_accidentado"
              id="cedula_accidentado"
              className="inputtext2 bordecompleto"
              placeholder="Digite la cédula del accidentado"
              value={req.cedula_accidentado}
              onChange={onChange}
            />
          </div>
          <div className="col-md-12">
            <label className="py-2 negrita">Nombres del Accidentado</label>
            <input
              type="text"
              name="nombres_accidentado"
              id="nombres_accidentado"
              className="inputtext2 bordecompleto"
              placeholder="Digite los nombres del accidentado"
              value={req.nombres_accidentado}
              onChange={onChange}
            />
          </div>

          <div className="py-3">
            <button
              type="button"
              className="bg-btn-vet bordesombreado "
              onClick={enviarFormulario}
            >
              Crear inciencia
            </button>
          </div>
        </div>
      </ModalDefault>
    </div>
  );
};
