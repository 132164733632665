import React, { useEffect, useState, useContext } from "react";

import "../../../components/admin/Sidebar/menucontable.css";
import CrearProyectos from "../../../components/admin/proyectos/crearProyectos";
import ListadoProyectos from "../../../components/admin/proyectos/ListadoProyectos"
import { useParams } from "react-router-dom";

const Proyectos = () => {
  const { panel } = useParams();
  const [updateList,setUpdateList]=useState(false)
  useEffect(() => {
    
    return () => {};
  }, []);

  const updateListFn=()=> {
    setUpdateList(!updateList)
  }
  return (
    <div className="bgfondo2 d-flex justify-content-start flex-column">
      <CrearProyectos updateListFn={updateListFn}/>
      <ListadoProyectos updateList={updateList} updateListFn={updateListFn}/>
    </div>
  );
};

export default Proyectos;
