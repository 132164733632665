import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { ApiGetDesactivarOpciones,ApiCrearOpciones } from "../../../../api/opciones";
const ListadoOpciones = ({
  pre_opciones,
  setOpciones,
  editInfo,
  updateListOpcionesFn,
  pre_pregunta_id
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const onchange = (e, index) => {
    let pre_opciones2 = pre_opciones;
    pre_opciones2[index].opcion = e.target.value;
    setearOpcionesPrincipal(pre_opciones2);
  };

    const deleteOpcionLocal = async (indexOpcion, e) => {
    e.preventDefault();
    const res = await pre_opciones.filter((x, index) => index !== indexOpcion);
    setearOpcionesPrincipal(res);
  };

  /* const deleteOpcionLocal = async (indexOpcion, e) => {
    e.preventDefault();
    let res = pre_opciones;
    res[indexOpcion].estado = false;
    setearOpcionesPrincipal(res);
  }; */
  const setearOpcionesPrincipal = (data) => {
    setOpciones(data);
  };

  const eliminarOpcion = async (pre_opcion_id) => {
    setLoading(true);
    const res = await ApiGetDesactivarOpciones({
      pre_opcion_id:pre_opcion_id,estado:false});
    console.log(res);
    if (res.sms === "ok") {
      toast.success(res.mensaje);
      setLoading(false);
      updateListOpcionesFn();
    } else {
      toast.error(res.mensaje);
      setLoading(false);
    }
  };

    const GuardarOpcion = async (data2) => {
     let datax=data2
     datax.pre_pregunta_id=pre_pregunta_id
     datax.pre_opcion_id=""
      setLoading(true);
      
      if(datax.opcion===""){
        toast.error(`El nombre de la opción está vacío`,{
          position:"bottom-center",
          style:{
            background:"red",
            color:"white"
          }
        })
        return
      }
      
      const res = await ApiCrearOpciones(datax);
      console.log(res);
      if (res.sms === "ok") {
        toast.success(res.mensaje);
        setLoading(false);
        updateListOpcionesFn();
      } else {
        toast.error(res.mensaje);
        setLoading(false);
      }
    };

  return (
    <>
      {pre_opciones.length > 0
        ? pre_opciones.map((data2, index) => {
            return (
              <div
                key={index}
                className="d-flex justify-content-between align-items-center mb-2"
                style={{ backgroundColor: data2.estado ? `red` : "" }}
              >
                <input
                  type=""
                  onChange={(e) => onchange(e, index)}
                  className="inputtext bordecompleto bordesombreado"
                  name={index}
                  id={index}
                  value={data2.opcion}
                />
                {
                  <div className="d-flex justify-content-between align-items-center bordecompleto p-1 bordesombreado bgfondo2 ">
                    {editInfo ? (
                      <button
                        className="bg-btn-vet bordesombreado bordecompleto mx-1"
                        onClick={()=>GuardarOpcion(data2)}
                      >
                        <i className="fas fa-save"></i>
                      </button>
                    ) : null}
                    <button
                      className="bg-btn-vet-danger mx-1"
                      onClick={(e) => {
                        data2.pre_opcion_id
                          ? eliminarOpcion(data2.pre_opcion_id)
                          : deleteOpcionLocal(index, e);
                      }}
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                  </div>
                  /* : 
                  <div className="d-flex justify-content-between align-items-center bordecompleto p-1 bordesombreado bgfondo2 ">
                    <button
                      className="bg-btn-vet mx-1"
                      onClick={(e) => deleteOpcionLocal(index, e)}
                    >
                      <i className="fas fa-rotate-left"></i>
                    </button>
                  </div>
                 */
                }
              </div>
            );
          })
        : null}
    </>
  );
};

export default ListadoOpciones;
